import React, {useEffect, useState} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import * as queries from '../graphql/customQueries';
//import { updateAttorney } from '../graphql/customMutations';
import {API, graphqlOperation, Storage} from 'aws-amplify';

// Generate Order Data
function createData(id, item, count) {
  return { id, item, count};
}

const rows = [
  createData(0, 'Total attorneys', '', 'Tupelo, MS', 'VISA ⠀•••• 3719', 312.44),
  createData(1, '16 Mar, 2019', 'Paul McCartney', 'London, UK', 'VISA ⠀•••• 2574', 866.99),
  createData(2, '16 Mar, 2019', 'Tom Scholz', 'Boston, MA', 'MC ⠀•••• 1253', 100.81),
  createData(3, '16 Mar, 2019', 'Michael Jackson', 'Gary, IN', 'AMEX ⠀•••• 2000', 654.39),
  createData(4, '15 Mar, 2019', 'Bruce Springsteen', 'Long Branch, NJ', 'VISA ⠀•••• 5919', 212.79),
];

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const arrayToObjectCount = array =>{
  //declare an empty object ( {} = empty object)
  const obj = {};
  //loop through the given array
  for (let item of array){
      //is the element in the object, if so add one to its value
      if (obj[item]){
          obj[item]++;
      } else{
          //if not set its value to 1
          obj[item] = 1;
      }
      //return the object
  }
  return obj;
}

export default function Totals() {
  const classes = useStyles();
const [practice, setPractice] = useState(null);
const [payments, setPayments] = useState(null);
const [cases, setCases] = useState(null);
const [client, setClient] = useState(null);
const [clientCount, setClientCount] = useState(null);
const [amountEqual39, setAmount39] = useState(null);
const [amountEqual59, setAmount59] = useState(null);
const [amountEqual0, setAmount0] = useState(null);
const [amountEqual109, setAmount109] = useState(null);
const [totalPromoCredits, setTotalPromoCredits] = useState(null);


useEffect(() => {
    (
        async () => {
            //here it grabs listPracticeAreas
            const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
            //set practice to listAttorneys
            const practice = responseListAttorneys.data.listAttorneys.items;
            setPractice(practice);

            //grabbing listPayment
            const responseListPayments = await API.graphql(graphqlOperation(queries.listPaymentsTest));
            const payments = responseListPayments.data.listPayments.items;
            setPayments(payments);
            let arrPayment = payments;
            const payments15 = 39; //15 minutes cost $39 
            const payments30 = 59;  //30 minutes cost $59
            const paymentsFree = 0; //15 minutes cost $0 
            const paymentsHour = 109;  //60 minutes cost $109
            const promoCredits = "PromoCredits"; 

            const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
            const cases = responseListCases.data.listCases.items;
            setCases(cases);
            let arrCase = cases; 

            const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
            const client = responseListClients.data.listClients.items;
            setClient(client);


            let arrClient = client;
            let clientValidIDArray = [];
            let clientInvalidArray = []; 


            for (let item of arrClient) {
                //If the client has an ID and accepts the TC and PP 
                if(item.id && item.acceptsTCAndPP === true) {
                    clientValidIDArray.push(item.id);
                }
                //checking if anyone is able to enter the app without acceping TC and PP
                if(item.acceptsTCAndPP === null){
                  clientInvalidArray.push(item.id)
                }
            }
            const clientCount = clientValidIDArray;
            setClientCount(clientCount);

            const amountEqual39 = payments.filter((item) => {
                return item.amount === payments15
            })
            setAmount39(amountEqual39);


            const amountEqual59 = payments.filter((item) => {
                return item.amount === payments30
            })
            setAmount59(amountEqual59);

            const amountEqual0 = payments.filter((item) => {
              return item.amount === paymentsFree
          })

          setAmount0(amountEqual0);

          const amountEqual109 = payments.filter((item) => {
            return item.amount === paymentsHour
        })
        setAmount109(amountEqual109);

            const totalPromoCredits = payments.filter((item) => {
              return item.paymentMethod === promoCredits
          })
          setTotalPromoCredits(totalPromoCredits);
        }
    )();
}, []);

  return (
    <React.Fragment>
      <Title>Totals</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Total</TableCell>
            <TableCell align="left">Count</TableCell>
            <TableCell>Total</TableCell>
            <TableCell align="left">Count</TableCell>
            <TableCell>Total</TableCell>
            <TableCell align="left">Count</TableCell>
            <TableCell>Total</TableCell>
            <TableCell align="left">Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={{fontSize: 22}}>Attorneys:</TableCell>  <TableCell style={{fontSize: 22}}>{practice ?  practice.length : 0}</TableCell>
            <TableCell align="left" style={{fontSize: 22}}>$0 payments:</TableCell> <TableCell  style={{fontSize: 22}}>{amountEqual0 ? amountEqual0.length: 0}</TableCell>
            <TableCell align="left" style={{fontSize: 22}}>$39 payments:</TableCell> <TableCell  style={{fontSize: 22}}>{amountEqual39 ? amountEqual39.length: 0}</TableCell>
            <TableCell style={{fontSize: 22}}>Payments:</TableCell> <TableCell style={{fontSize: 22}}>{payments ? payments.length: 0}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{fontSize: 22}}>Users:</TableCell> <TableCell style={{fontSize: 22}}>{clientCount ? client.length : 0}</TableCell>
            <TableCell align="left" style={{fontSize: 22}}>$109 payments:</TableCell> <TableCell style={{fontSize: 22}}>{amountEqual109 ? amountEqual109.length: 0}</TableCell>
            <TableCell align="left" style={{fontSize: 22}}>$59 payments:</TableCell> <TableCell style={{fontSize: 22}}>{amountEqual59 ? amountEqual59.length: 0}</TableCell>
            <TableCell align="left" style={{fontSize: 22}}>PromoCredits:</TableCell> <TableCell style={{fontSize: 22}}>{totalPromoCredits ? totalPromoCredits.length: 0}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Link color="primary" href="/LegalCategories" onClick={preventDefault}>
          {/* add text */}
        </Link>
      </div>
    </React.Fragment>
  );
}
