import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from './Title';
import * as queries from '../graphql/customQueries';
//import { updateAttorney } from '../graphql/customMutations';
import { API, graphqlOperation } from 'aws-amplify';


const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Deposits() {
  const classes = useStyles();
  const [payments, setPayments] = useState(null);
  const [paymentToAttorneys, setPaymentsToAttorneys] = useState(null);
  const [totalPaymentsRevenueFinal, setTotalPaymentsRevenueFinal] = useState(null);
  const [totalPaymentsToLegalQ, setTotalPaymentsToLegalQ] = useState(null);

  useEffect(() => {
    (
      async () => {
        const responseListPayments = await API.graphql(graphqlOperation(queries.listPaymentsTest));
        const payments = responseListPayments.data.listPayments.items;
        setPayments(payments);
        const payments15 = 39; //15 minutes cost $39 
        const payments30 = 59;  //30 minutes cost $59


        let arrPayments = payments;
        const totalPaymentsToAttorneys = [];
        const totalPaymentsRevenue = [];

        for (let item of arrPayments) {
          if (item.calculatedAttorneyAmount) {
            totalPaymentsToAttorneys.push(item.calculatedAttorneyAmount)
            totalPaymentsRevenue.push(item.amount);
          }
        }



        let totalPaymentsToAttorneysFinal = totalPaymentsToAttorneys.reduce((a, b) => a + b).toFixed(2);    

        
        let totalPaymentsRevenueFinal = totalPaymentsRevenue.reduce((a, b) => a + b).toFixed(2);


        const totalPaymentsToLegalQ = totalPaymentsRevenueFinal - totalPaymentsToAttorneysFinal;
           


        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        });
        

        setTotalPaymentsRevenueFinal(formatter.format(totalPaymentsRevenueFinal));
        setTotalPaymentsToLegalQ(formatter.format(totalPaymentsToLegalQ));
        setPaymentsToAttorneys(formatter.format(totalPaymentsToAttorneysFinal));
      }
    )();
  }, []);


  return (
    <React.Fragment>
      <Title>Total Revenue</Title>
      <Typography component="p" variant="h5">
      {totalPaymentsRevenueFinal}
      </Typography>
      {/* <Typography color="textSecondary" className={classes.depositContext}>
        Given to Attorneys
      </Typography> */}

      <Title>Total to Attorneys</Title>
      <Typography component="p" variant="h5">
      {paymentToAttorneys}
      </Typography>
      {/* <Typography color="textSecondary" className={classes.depositContext}>
        Given to Attorneys
      </Typography> */}

      <Title>Total to LegalQ</Title>
      <Typography component="p" variant="h5">
      {totalPaymentsToLegalQ}
      </Typography>
      {/* <Typography color="textSecondary" className={classes.depositContext}>
        Given to Attorneys
      </Typography> */}


      {/* <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          test
        </Link>
      </div> */}
    </React.Fragment>
  );
}
