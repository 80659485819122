// @ts-nocheck
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { API, graphqlOperation } from 'aws-amplify';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import * as queries from '../graphql/customQueries';
import CasesDataTable from './CasesDataTable';
import ConsultationChart from './ConsultationChart';
import ConsultationsDataTable from './ConsultationsDataTable/index';
import moment from 'moment';
import Title from './Title';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        display: 'flex',
        "& .wrapHeader .MuiDataGrid-colCellTitle": {
            lineHeight: "15px",
            whiteSpace: "normal"
        }
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    boxLarge: {
        width: theme.spacing(18),
        height: theme.spacing(18),
    },
}));


const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

const averageSecondsFormat = array => {

    let average = (array) => array.reduce((a, b) => a + b) / array.length;
    var minutes = Math.floor(average(array) / 60);
    var seconds = average(array) - minutes * 60;

    let averageTime = minutes + ":" + seconds.toFixed(0);

    return averageTime;

}

export default function AttorneyDashboard() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [cases, setCases] = useState(null);
    const [consultationsStatus, setConsultationsStatus] = useState(null);
    const [caseConnectionMEthodCount, setCaseConnectionMEthodCount] = useState(null);
    const [averageTimeInConsultation, setAverageTimeInConsultation] = useState(null);
    const [averageWaitTime, setAverageWaitTime] = useState(null);
    const [averageWaitTimeReject, setAverageWaitTimeReject] = useState(null);
    const [averageFriendlinessScore, setAverageFriendlinessScore] = useState(null);
    const [averageKnowledgeScore, setAverageKnowledgeScore] = useState(null);
    const [averageOverallScore, setAverageOverallScore] = useState(null);
    const [averageRatingParticipation, setAverageRatingParticipation] = useState(null);

    useEffect(() => {
        (
            async () => {

                // ----------------------- FETCH CONSULTATION DATA -------------------------------------
                let arrConsultations = [];
                try {
                    const responseListConsultations = await API.graphql(graphqlOperation(queries.listConsultationsTest));
                    arrConsultations = responseListConsultations.data.listConsultations.items?.filter(q => q);

                } catch (error) {
                    arrConsultations = error.data.listConsultations.items?.filter(q => q);
                }


                let arrlistAttorneyConsultationRequests = [];
                try {
                    const responseListAttorneyConsultationRequests = await API.graphql(graphqlOperation(queries.listAttorneyConsultationRequestsTest));
                    arrlistAttorneyConsultationRequests = responseListAttorneyConsultationRequests.data.listAttorneyConsultationRequests.items?.filter(q => q);
                } catch (error) {
                    arrlistAttorneyConsultationRequests = error.data.listAttorneyConsultationRequests.items?.filter(q => q);
                }

                let cases = [];
                try {
                    const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                    cases = responseListCases.data.listCases.items?.filter(q => q);
                } catch (error) {
                    cases = error.data.listCases.items?.filter(q => q);
                }
                setCases(cases);

                console.log(arrlistAttorneyConsultationRequests)



                // ------------------- HANDLE STATISTICS -------------------
                let arrCase = cases;


                let consultationsStatusArray = [];
                //put status into a single array
                let today = moment().format("MM/DD/YY, h:mm a");
                let freeSessionAgo = moment().subtract(15, 'minutes');
                let twoMonthsago = moment().subtract(2, 'months');
                // console.log(typeof (Date(testingago))+"\n"+testingago);

                for (let item of arrConsultations) {
                    let consultationdate = moment(item.createdAt);

                    if (item.status == "Completed") {
                        consultationsStatusArray.push(item.status);
                    }

                    if (item.status == "InProgress" && consultationdate > freeSessionAgo) {
                        consultationsStatusArray.push("In Progress");
                        console.log("Consultation Date " + consultationdate + "\n freeSession ago " + freeSessionAgo)
                    }
                    if (item.status == "Scheduled" && consultationdate > twoMonthsago && item.clientJoined == null) {
                        consultationsStatusArray.push(item.status);
                    }

                }
                // console.log(consultationsStatusArray)
                //count the statues in the array and save as object
                const consultationsStatusCount = arrayToObjectCount(consultationsStatusArray);

                setConsultationsStatus(consultationsStatusCount);
                let average = (array) => array.reduce((a, b) => a + b) / array.length;

                let waitTimeCountAcceptTotal = [];
                let waitTimeCountRejectTotal = [];
                let waitTimeCountPendingTotal = [];
                for (let item of arrlistAttorneyConsultationRequests) {

                    if (item.clientWaitTime && item.status == "Accept") {
                        waitTimeCountAcceptTotal.push(item.clientWaitTime);
                    }

                    if (item.clientWaitTime && item.status == "Reject") {
                        waitTimeCountRejectTotal.push(item.clientWaitTime);
                    }
                    if (item.clientWaitTime && item.status == "Pending") {
                        waitTimeCountPendingTotal.push(item.clientWaitTime);
                    }
                }

                let averageWaitTimeAcceptFormat = averageSecondsFormat(waitTimeCountAcceptTotal);
                let averageWaitTimeRejectFormat = averageSecondsFormat(waitTimeCountRejectTotal);
                let averageWaitTimePendingFormat = averageSecondsFormat(waitTimeCountPendingTotal);
                console.log("Accept WaitTimesFormat "+averageWaitTimeAcceptFormat);
                console.log("Reject WaitTimesFormat "+averageWaitTimeRejectFormat);
                console.log("Pending WaitTimesFormat "+averageWaitTimePendingFormat);
                setAverageWaitTime(averageWaitTimeAcceptFormat);
                setAverageWaitTimeReject(averageWaitTimeRejectFormat);

                let averageConsultationTime = [];
                let consultationTime15 = [];
                let consultationTime30 = [];

                for (let item of arrConsultations) {

                    if (item.actualLength) {
                        averageConsultationTime.push(item.actualLength)
                        if (item.purchasedLength === 15) {
                            consultationTime15.push(item.actualLength)
                        }
                        if (item.purchasedLength === 30) {
                            consultationTime30.push(item.actualLength)
                        }
                    }
                    if (item.connectionMethod) {

                    }
                }

                const averageTimeInConsultation = averageSecondsFormat(consultationTime15);

                setAverageTimeInConsultation(averageTimeInConsultation);


                let countOverallScore = [];
                let countKnowledgeScore = [];
                let countFriendlinessScore = [];
                let totalRatingsCount = [];


                for (let item of arrCase) {
                    if (item.rating) {
                        totalRatingsCount.push(item.id)
                        if (item.rating.friendlinessScore) {
                            countFriendlinessScore.push(item.rating.friendlinessScore);
                        }
                        if (item.rating.knowledgeScore) {
                            countKnowledgeScore.push(item.rating.knowledgeScore);
                        }
                        if (item.rating.overallScore) {
                            countOverallScore.push(item.rating.overallScore);
                        }
                    }
                }

                let averageFriendlinessScore = average(countFriendlinessScore);
                let averageKnowledgeScore = average(countKnowledgeScore);
                let averageOverallScore = average(countOverallScore);

                setAverageFriendlinessScore(averageFriendlinessScore);
                setAverageKnowledgeScore(averageKnowledgeScore);
                setAverageOverallScore(averageOverallScore);


                let caseConnectionMethod = [];

                for (let item of arrCase) {
                    if (item.attorney) {
                        caseConnectionMethod.push(item.consultationConnectionMethod)
                    }
                }

                const caseConnectionMEthodCount = arrayToObjectCount(caseConnectionMethod);
                setCaseConnectionMEthodCount(caseConnectionMEthodCount);

                let totalCasesCount = [];

                for (let item of arrCase) {
                    if (item.attorney) {
                        totalCasesCount.push(item.id);
                    }
                }

                let averageRatingCount5 = totalRatingsCount.length / totalCasesCount.length * 100
                let averageRatingParticipation = averageRatingCount5.toFixed(2) + "%";
                setAverageRatingParticipation(averageRatingParticipation);
            }
        )();
    }, []);

    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
            <React.Fragment>
                <Title>Consultation Details</Title>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {/* CONSULTATION LINE CHART */}
                        <ConsultationChart data={cases} />

                        {/* STATISTICS */}
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <div align="left" style={{ fontSize: 20 }}> Status {consultationsStatus ?

                                    Object.keys(consultationsStatus || {}).map((item, i) => {
                                        return (
                                            <div key={`keyID-${i}`}>
                                                <ol>{item}: {consultationsStatus[item]}</ol>
                                            </div>
                                        )
                                    })
                                    : "Loading"}</div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <div align="left" style={{ fontSize: 20 }}> Completed connection methods count {cases ?

                                    Object.keys(caseConnectionMEthodCount || {}).map((item, i) => {
                                        return (
                                            <div key={`keyID-${i}`}>
                                                <ol>{item}: {caseConnectionMEthodCount[item]}</ol>
                                            </div>
                                        )
                                    })
                                    : "Loading"}</div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <div align="left" style={{ fontSize: 20 }}> Connection time average (mm:ss) {averageTimeInConsultation ?

                                    <p>15 minutes: {averageTimeInConsultation}</p>

                                    : "Loading"}</div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <div align="left" style={{ fontSize: 20 }}> Attorney rating average {averageOverallScore ?

                                    <ol>
                                        <p> Overall: {averageOverallScore.toFixed(1)} </p>
                                        <p> Friendliness: {averageFriendlinessScore.toFixed(1)} </p>
                                        <p> Knowledge: {averageKnowledgeScore.toFixed(1)}</p>
                                    </ol>

                                    : "Loading"}</div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <div align="left" style={{ fontSize: 20 }}> Average Rating Participation: {averageOverallScore ? averageRatingParticipation

                                    : "Loading"}</div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Paper className={fixedHeightPaper}>
                                <div align="left" style={{ fontSize: 20 }}> Average User Wait Time by Attorney Action: {averageWaitTime ? 

                                    <ol>
                                        <p> Accepted: {averageWaitTime} </p>
                                        <p> Rejected: {averageWaitTimeReject} </p>
                                    </ol>
                                    : "Loading"}</div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>

                {/* CONSULTATION TABLE */}
                <ConsultationsDataTable data={cases} />

                {/* CASES TABLE */}
                <CasesDataTable data={cases} />

            </React.Fragment>
        </main>
    );
}
