// @ts-nocheck
import moment from "moment";
import { Storage } from 'aws-amplify';

const today = moment().format("MM/DD/YY");
const oneDayAgo = moment().subtract(1, 'days').endOf('day').format("MM/DD/YY");
const twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD/YY");
const threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD/YY");
const fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD/YY");
const fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD/YY");
const sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD/YY");
const sevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD/YY");
const thisWeek = moment().format("WW/YY");
const oneWeekAgo = moment().subtract(1, 'weeks').endOf('day').format("WW/YY");
const twoWeeksAgo = moment().subtract(2, 'weeks').endOf('day').format("WW/YY");
const threeWeeksAgo = moment().subtract(3, 'weeks').endOf('day').format("WW/YY");
const fourWeeksAgo = moment().subtract(4, 'weeks').endOf('day').format("WW/YY");
const thisMonth = moment().format("MM/YY");
const oneMonthAgo = moment().subtract(1, 'months').endOf('day').format("MM/YY");
const twoMonthsAgo = moment().subtract(2, 'months').endOf('day').format("MM/YY");
const threeMonthsAgo = moment().subtract(3, 'months').endOf('day').format("MM/YY");
const fourMonthsAgo = moment().subtract(4, 'months').endOf('day').format("MM/YY");
const fiveMonthsAgo = moment().subtract(5, 'months').endOf('day').format("MM/YY");
const sixMonthsAgo = moment().subtract(6, 'months').endOf('day').format("MM/YY");
const sevenMonthsAgo = moment().subtract(7, 'months').endOf('day').format("MM/YY");
const eightMonthsAgo = moment().subtract(8, 'months').endOf('day').format("MM/YY");
const nineMonthsAgo = moment().subtract(9, 'months').endOf('day').format("MM/YY");
const tenMonthsAgo = moment().subtract(10, 'months').endOf('day').format("MM/YY");
const elevenMonthsAgo = moment().subtract(11, 'months').endOf('day').format("MM/YY");

// TODO: refactor whatever this is

export const mapConsultationThisWeek = (data) => {
  if (!data) return null;

  const todayArray = [];
  const oneDayAgoArray = [];
  const twoDaysAgoArray = [];
  const threeDaysAgoArray = [];
  const fourDaysAgoArray = [];
  const fiveDaysAgoArray = [];
  const sixDaysAgoArray = [];
  const sevenDaysAgoArray = [];

  const todayArrayAttorney = [];
  const oneDayAgoArrayAttorney = [];
  const twoDaysAgoArrayAttorney = [];
  const threeDaysAgoArrayAttorney = [];
  const fourDaysAgoArrayAttorney = [];
  const fiveDaysAgoArrayAttorney = [];
  const sixDaysAgoArrayAttorney = [];
  const sevenDaysAgoArrayAttorney = [];
  for (let item of data) {
    let currentDay = moment(item.createdAt).format("MM/DD/YY").toString();

    if (item.attorney === null) {
      if (currentDay === today) {
        todayArray.push(item.id);
      }
      if (currentDay === oneDayAgo) {
        oneDayAgoArray.push(item.id);
      }
      if (currentDay === twoDaysAgo) {
        twoDaysAgoArray.push(item.id);
      }
      if (currentDay === threeDaysAgo) {
        threeDaysAgoArray.push(item.id);
      }
      if (currentDay === fourDaysAgo) {
        fourDaysAgoArray.push(item.id);
      }
    }

    if (item.attorney && item.client) {
      if (currentDay === today) {
        todayArrayAttorney.push(item.id);
      }
      if (currentDay === oneDayAgo) {
        oneDayAgoArrayAttorney.push(item.id);
      }
      if (currentDay === twoDaysAgo) {
        twoDaysAgoArrayAttorney.push(item.id);
      }
      if (currentDay === threeDaysAgo) {
        threeDaysAgoArrayAttorney.push(item.id);
      }
      if (currentDay === fourDaysAgo) {
        fourDaysAgoArrayAttorney.push(item.id);
      }
      if (currentDay === fiveDaysAgo) {
        fiveDaysAgoArrayAttorney.push(item.id);
      }
      if (currentDay === sixDaysAgo) {
        sixDaysAgoArrayAttorney.push(item.id);
      }
      if (currentDay === sevenDaysAgo) {
        sevenDaysAgoArrayAttorney.push(item.id);
      }
    }
  }
  const clientCountBarData = [];
  clientCountBarData.push({ XAxisPoints: today, ClientCount: todayArray.length, AttorneyCount: todayArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: oneDayAgo, ClientCount: oneDayAgoArray.length, AttorneyCount: oneDayAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: twoDaysAgo, ClientCount: twoDaysAgoArray.length, AttorneyCount: twoDaysAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: threeDaysAgo, ClientCount: threeDaysAgoArray.length, AttorneyCount: threeDaysAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: fourDaysAgo, ClientCount: fourDaysAgoArray.length, AttorneyCount: fourDaysAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: fiveDaysAgo, ClientCount: fiveDaysAgoArray.length, AttorneyCount: fiveDaysAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: sixDaysAgo, ClientCount: sixDaysAgoArray.length, AttorneyCount: sixDaysAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: sevenDaysAgo, ClientCount: sevenDaysAgoArray.length, AttorneyCount: sevenDaysAgoArrayAttorney.length })

  return clientCountBarData.reverse();
}

export const mapConsultationPast5Weeks = (data) => {
  if (!data) return null;

  const currentWeekArray = [];
  const oneWeekAgoArray = [];
  const twoWeeksAgoArray = [];
  const threeWeeksAgoArray = [];
  const fourWeeksAgoArray = [];

  const currentWeekArrayAttorney = [];
  const oneWeekAgoArrayAttorney = [];
  const twoWeeksAgoArrayAttorney = [];
  const threeWeeksAgoArrayAttorney = [];
  const fourWeeksAgoArrayAttorney = [];

  for (let item of data) {
    let currentWeek = moment(item.createdAt).format("WW/YY").toString();
    if (item.attorney === null) {
      if (currentWeek === thisWeek) {
        currentWeekArray.push(item.id);
      }
      if (currentWeek === oneWeekAgo) {
        oneWeekAgoArray.push(item.id);
      }
      if (currentWeek === twoWeeksAgo) {
        twoWeeksAgoArray.push(item.id);
      }
      if (currentWeek === threeWeeksAgo) {
        threeWeeksAgoArray.push(item.id);
      }
      if (currentWeek === fourWeeksAgo) {
        fourWeeksAgoArray.push(item.id);
      }
    }

    if (item.attorney && item.client) {
      if (currentWeek === thisWeek) {
        currentWeekArrayAttorney.push(item.id);
      }
      if (currentWeek === oneWeekAgo) {
        oneWeekAgoArrayAttorney.push(item.id);
      }
      if (currentWeek === twoWeeksAgo) {
        twoWeeksAgoArrayAttorney.push(item.id);
      }
      if (currentWeek === threeWeeksAgo) {
        threeWeeksAgoArrayAttorney.push(item.id);
      }
      if (currentWeek === fourWeeksAgo) {
        fourWeeksAgoArrayAttorney.push(item.id);
      }
    }
  }

  const MonthCountBarData = [];
  MonthCountBarData.push({ XAxisPoints: thisWeek, ClientCount: currentWeekArray.length, AttorneyCount: currentWeekArrayAttorney.length })
  MonthCountBarData.push({ XAxisPoints: oneWeekAgo, ClientCount: oneWeekAgoArray.length, AttorneyCount: oneWeekAgoArrayAttorney.length })
  MonthCountBarData.push({ XAxisPoints: twoWeeksAgo, ClientCount: twoWeeksAgoArray.length, AttorneyCount: twoWeeksAgoArrayAttorney.length })
  MonthCountBarData.push({ XAxisPoints: threeWeeksAgo, ClientCount: threeWeeksAgoArray.length, AttorneyCount: threeWeeksAgoArrayAttorney.length })
  MonthCountBarData.push({ XAxisPoints: fourWeeksAgo, ClientCount: fourWeeksAgoArray.length, AttorneyCount: fourWeeksAgoArrayAttorney.length })
  return MonthCountBarData.reverse();
};

export const mapConsultationPastYear = (data) => {
  if (!data) return null;

  const thisMonthArray = [];
  const oneMonthAgoArray = [];
  const twoMonthsAgoArray = [];
  const threeMonthsAgoArray = [];
  const fourMonthsAgoArray = [];
  const fiveMonthsAgoArray = [];
  const sixMonthsAgoArray = [];
  const sevenMonthsAgoArray = [];
  const eightMonthsAgoArray = [];
  const nineMonthsAgoArray = [];
  const tenMonthsAgoArray = [];
  const elevenMonthsAgoArray = [];

  const thisMonthArrayAttorney = [];
  const oneMonthAgoArrayAttorney = [];
  const twoMonthsAgoArrayAttorney = [];
  const threeMonthsAgoArrayAttorney = [];
  const fourMonthsAgoArrayAttorney = [];
  const fiveMonthsAgoArrayAttorney = [];
  const sixMonthsAgoArrayAttorney = [];
  const sevenMonthsAgoArrayAttorney = [];
  const eightMonthsAgoArrayAttorney = [];
  const nineMonthsAgoArrayAttorney = [];
  const tenMonthsAgoArrayAttorney = [];
  const elevenMonthsAgoArrayAttorney = [];

  for (let item of data) {
    let currentYear = moment(item.createdAt).format("MM/YY").toString();

    if (item.attorney === null) {
      if (currentYear === thisMonth) {
        thisMonthArray.push(item.id);
      }
      if (currentYear === oneMonthAgo) {
        oneMonthAgoArray.push(item.id);
      }
      if (currentYear === twoMonthsAgo) {
        twoMonthsAgoArray.push(item.id);
      }
      if (currentYear === threeMonthsAgo) {
        threeMonthsAgoArray.push(item.id);
      }
      if (currentYear === fourMonthsAgo) {
        fourMonthsAgoArray.push(item.id);
      }
      if (currentYear === fiveMonthsAgo) {
        fiveMonthsAgoArray.push(item.id);
      }
      if (currentYear === sixMonthsAgo) {
        sixMonthsAgoArray.push(item.id);
      }
      if (currentYear === sevenMonthsAgo) {
        sevenMonthsAgoArray.push(item.id);
      }
      if (currentYear === eightMonthsAgo) {
        eightMonthsAgoArray.push(item.id);
      }
      if (currentYear === nineMonthsAgo) {
        nineMonthsAgoArray.push(item.id);
      }
      if (currentYear === tenMonthsAgo) {
        tenMonthsAgoArray.push(item.id);
      }
      if (currentYear === elevenMonthsAgo) {
        elevenMonthsAgoArray.push(item.id);
      }
    }

    if (item.attorney && item.client) {
      if (currentYear === thisMonth) {
        thisMonthArrayAttorney.push(item.id);
      }
      if (currentYear === oneMonthAgo) {
        oneMonthAgoArrayAttorney.push(item.id);
      }
      if (currentYear === twoMonthsAgo) {
        twoMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === threeMonthsAgo) {
        threeMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === fourMonthsAgo) {
        fourMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === fiveMonthsAgo) {
        fiveMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === sixMonthsAgo) {
        sixMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === sevenMonthsAgo) {
        sevenMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === eightMonthsAgo) {
        eightMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === nineMonthsAgo) {
        nineMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === tenMonthsAgo) {
        tenMonthsAgoArrayAttorney.push(item.id);
      }
      if (currentYear === elevenMonthsAgo) {
        elevenMonthsAgoArrayAttorney.push(item.id);
      }
    }
  }

  const clientCountBarData = [];
  clientCountBarData.push({ XAxisPoints: thisMonth, ClientCount: thisMonthArray.length, AttorneyCount: thisMonthArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: oneMonthAgo, ClientCount: oneMonthAgoArray.length, AttorneyCount: oneMonthAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: twoMonthsAgo, ClientCount: twoMonthsAgoArray.length, AttorneyCount: twoMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: threeMonthsAgo, ClientCount: threeMonthsAgoArray.length, AttorneyCount: threeMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: fourMonthsAgo, ClientCount: fourMonthsAgoArray.length, AttorneyCount: fourMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: fiveMonthsAgo, ClientCount: fiveMonthsAgoArray.length, AttorneyCount: fiveMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: sixMonthsAgo, ClientCount: sixMonthsAgoArray.length, AttorneyCount: sixMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: sevenMonthsAgo, ClientCount: sevenMonthsAgoArray.length, AttorneyCount: sevenMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: eightMonthsAgo, ClientCount: eightMonthsAgoArray.length, AttorneyCount: eightMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: nineMonthsAgo, ClientCount: nineMonthsAgoArray.length, AttorneyCount: nineMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: tenMonthsAgo, ClientCount: tenMonthsAgoArray.length, AttorneyCount: tenMonthsAgoArrayAttorney.length })
  clientCountBarData.push({ XAxisPoints: elevenMonthsAgo, ClientCount: elevenMonthsAgoArray.length, AttorneyCount: elevenMonthsAgoArrayAttorney.length })

  return clientCountBarData.reverse();
}

export async function mapConsultationsTableData(data) {
  if(!data) return null;

  const userTableData = []
  for (let item of data) {
    if(!item) continue;
    
    // const tempStorage = item.attorney ? await Storage.get(item.attorney.avatarUrl, { level: "public" }) : "";

    let consultationObj = {
      id: item.id,
      ConnectionMethod: item.consultationConnectionMethod,
      UserAvatar: item.client.firstName,
      UserFirstName: item.client.firstName,
      UserLastName: item.client.lastName,
      UserFullName: item.client.firstName + " " + item.client.lastName,
      UserEmail: item.client.email,
      UserState: item.client.state,
      PaymentAmount: item.payment.amount,
      PaymentMethod: item.payment.paymentMethod,
      StripeToken: item.payment.stripeToken,
      VenueState: item.venueState,
      AreaOfLaw: item.areaOfLaw,
      CaseDate: item.createdAt,
      UpdatedAt: item.updatedAt,
      UserDescription: item.legalAdviceDescription,
      FutureConsultationTime: item.futureConsultationDateTime,
      actions: item,

      OverallRating: undefined,
      FriendlinessScore: undefined,
      KnowledgeScore: undefined,
      FeedbackText: undefined,

      Connection: undefined,

      AttorneyFirstName: undefined,
      AttorneyLastName: undefined,
      AttorneyWorkEmail: undefined,
      AttorneyAvatar: undefined,
    }

    if (!item.rating) {
      consultationObj = {
        ...consultationObj,
        OverallRating: "NA",
        FriendlinessScore: "NA",
        KnowledgeScore: "NA",
        FeedbackText: "NA",
      }
    } else {
      consultationObj = {
        ...consultationObj,
        OverallRating: item.rating.overallScore,
        FriendlinessScore: item.rating.friendlinessScore,
        KnowledgeScore: item.rating.knowledgeScore,
        FeedbackText: item.rating.feedbackText
      }
    }

    if (item.attorney) {
      consultationObj = {
        ...consultationObj,
        AttorneyFirstName: item.attorney.firstName,
        AttorneyLastName: item.attorney.lastName,
        AttorneyFullName: item.attorney.firstName + " " + item.attorney.lastName,
        AttorneyWorkEmail: item.attorney.emailWork,
        // AttorneyAvatar: tempStorage,
        Connection: "yes",
      }
    } else {
      consultationObj = {
        ...consultationObj,
        AttorneyFirstName: "NA",
        AttorneyLastName: "NA",
        AttorneyWorkEmail: "NA",
        AttorneyAvatar: "",
        AttorneyFullName: "NA",
        Connection: "No",
      }
    }

    userTableData.push(consultationObj);
  }

  return userTableData;
}