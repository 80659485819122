import { Modal, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useStyles from './styles';
import { statesOptions } from '../../constants'
import {NotificationMethod, ConnectionMethods} from '../../API'

function AttorneyEditModal(props) {
  const classes = useStyles();
  const { onClose, isVisible, onSubmit, data } = props;

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { ...data }
  });

  useEffect(() => {
    if (isVisible) {
      reset({ ...data })
    }
  }, [isVisible, data])

  return (
    <Modal onClose={onClose} open={isVisible} className={classes.modal}>
      <div className={classes.paper}>
        <h2>Attorney Details</h2>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => <TextField {...field} label="First Name" defaultValue={field.value} variant='filled' className={classes.input} />}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => <TextField {...field} label="Last Name" defaultValue={field.value} variant='filled' className={classes.input} />}
          />
          <Controller
            name="emailWork"
            control={control}
            render={({ field }) => <TextField {...field} label="Email" defaultValue={field.value} variant='filled' className={classes.input} />}
          />
          <Controller
            name="statesOfLicense"
            control={control}
            render={({ field }) => (
              <FormControl variant="filled" className={classes.input}>
                <InputLabel id="demo-simple-select-filled-label">State</InputLabel>
                <Select
                  {...field}
                >
                  {statesOptions.map(option => (
                    <MenuItem key={option.label} value={option.value}>{option.value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="licenseNumber"
            control={control}
            render={({ field }) => <TextField {...field} label="License Number" defaultValue={field.value} variant='filled' className={classes.input} />}
          />
          <Controller
            name="cellPhone"
            control={control}
            render={({ field }) => <TextField {...field} label="Phone Number" defaultValue={field.value} variant='filled' className={classes.input} />}
          />
          <Controller
            name="notificationMethods"
            control={control}
            render={({ field }) => (
              <FormControl variant="filled" className={classes.input}>
                <InputLabel id="demo-simple-select-filled-label">Notification Preferences</InputLabel>
                <Select
                  {...field}
                  multiple
                >
                  {Object.values(NotificationMethod).map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name="communicationMethodsPreference"
            control={control}
            render={({ field }) => (
              <FormControl variant="filled" className={classes.input}>
                <InputLabel id="demo-simple-select-filled-label">Communcation Channels</InputLabel>
                <Select
                  {...field}
                  multiple
                >
                  {Object.values(ConnectionMethods).map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />

          <Button type="submit" variant='contained' color='primary'>Submit</Button>
        </form>
      </div>
    </Modal>
  )
}

export default AttorneyEditModal;