import React, { useEffect, useState, PureComponent } from 'react';
import { Header, Statistic, Icon, Divider, Image, Loader } from 'semantic-ui-react'
import { makeStyles } from '@material-ui/core/styles';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { DataGrid } from '@material-ui/data-grid';
import { GridToolbar } from '@material-ui/data-grid';
import clsx from 'clsx';
import { renderCellExpand } from "./renderCellExpand";
import moment from 'moment';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const wrap = (s) => s.replace(
    /(?![^\n]{1,32}$)([^\n]{1,32})\s/g, '$1\n'
);



const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        display: 'flex',
        "& .wrapHeader .MuiDataGrid-colCellTitle": {
            lineHeight: "15px",
            whiteSpace: "normal"
        }
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    boxLarge: {
        width: theme.spacing(18),
        height: theme.spacing(18),
    },
}));


const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

const averageSecondsFormat = array => {

    let average = (array) => array.reduce((a, b) => a + b) / array.length;
    var minutes = Math.floor(average(array) / 60);
    var seconds = average(array) - minutes * 60;

    let averageTime = minutes + ":" + seconds.toFixed(0);

    return averageTime;

}
const { SearchBar } = Search;

const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-success" onClick={ handleClick }>Export to CSV</button>
      </div>
    );
  };

  
export default function AttorneyDashboard() {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [consultations, setConsultations] = useState(null);
    const [cases, setCases] = useState(null);
    const [consultationsStatus, setConsultationsStatus] = useState(null);
    const [caseConnectionMEthodCount, setCaseConnectionMEthodCount] = useState(null);
    const [columnsTemp, setColumnsTemp] = useState(null);
    const [columns2, setColums2] = useState(null);
    const [userTableData, setUserTableData] = useState(null);
    const [averageTimeInConsultation, setAverageTimeInConsultation] = useState(null);
    const [averageFriendlinessScore, setAverageFriendlinessScore] = useState(null);
    const [averageKnowledgeScore, setAverageKnowledgeScore] = useState(null);
    const [averageOverallScore, setAverageOverallScore] = useState(null);
    const [averageRatingParticipation, setAverageRatingParticipation] = useState(null);
    const [modalInfo, setModalInfo] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [rowEvents, setRowEvents] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        (
            async () => {

                const responseListConsultations = await API.graphql(graphqlOperation(queries.listConsultationsTest));
                const consultations = responseListConsultations.data.listConsultations.items;
                setConsultations(consultations);
                let arrConsultations = consultations;

                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);
                let arrCase = cases;

                // const testPromotion = await API.graphql(graphqlOperation(queries.listClientPromotionalCodesTest));
                // const promotion = testPromotion.data.listClientPromotionalCodes.items;
                // setCases(promotion);
                // let arrpromotion = promotion;
                // console.log(arrpromotion);


                let consultationsStatusArray = [];
                //put status into a single array
                for (let item of arrConsultations) { consultationsStatusArray.push(item.status); }
                //count the statues in the array and save as object
                const consultationsStatusCount = arrayToObjectCount(consultationsStatusArray);

                setConsultationsStatus(consultationsStatusCount);

                let averageConsultationTime = [];
                let consultationTime15 = [];
                let consultationTime30 = [];

                for (let item of arrConsultations) {

                    if (item.actualLength) {
                        averageConsultationTime.push(item.actualLength)
                        if (item.purchasedLength === 15) {
                            consultationTime15.push(item.actualLength)
                        }
                        if (item.purchasedLength === 30) {
                            consultationTime30.push(item.actualLength)
                        }
                    }
                    if(item.connectionMethod){

                    }
                }

                // console.log(averageSecondsFormat(consultationTime15));

                const averageTimeInConsultation = averageSecondsFormat(consultationTime15);
                // const averageTimeInConsultation = [
                //     {
                //         timeBought: '15 minute', actualAverageLength: averageSecondsFormat(consultationTime15),
                //     },
                // ];
                setAverageTimeInConsultation(averageTimeInConsultation);


                let countOverallScore = [];
                let countKnowledgeScore = [];
                let countFriendlinessScore = [];
                let totalRatingsCount = [];
                let average = (array) => array.reduce((a, b) => a + b) / array.length;

                for (let item of arrCase) {
                    if (item.rating) {
                        totalRatingsCount.push(item.id)
                        if (item.rating.friendlinessScore) {
                            countFriendlinessScore.push(item.rating.friendlinessScore);
                        }
                        if (item.rating.knowledgeScore) {
                            countKnowledgeScore.push(item.rating.knowledgeScore);
                        }
                        if (item.rating.overallScore) {
                            countOverallScore.push(item.rating.overallScore);
                        }
                    }
                }
                // console.log(average(countFriendlinessScore));
                // console.log(average(countKnowledgeScore));
                // console.log(average(countOverallScore));

                let averageFriendlinessScore = average(countFriendlinessScore);
                let averageKnowledgeScore = average(countKnowledgeScore);
                let averageOverallScore = average(countOverallScore);

                setAverageFriendlinessScore(averageFriendlinessScore);
                setAverageKnowledgeScore(averageKnowledgeScore);
                setAverageOverallScore(averageOverallScore);


                let caseConnectionMethod = [];

                for (let item of arrCase) {
                    if (item.attorney) {
                        caseConnectionMethod.push(item.consultationConnectionMethod)
                    }
                }

                const caseConnectionMEthodCount = arrayToObjectCount(caseConnectionMethod);
                setCaseConnectionMEthodCount(caseConnectionMEthodCount);
                //console.log(caseConnectionMEthodCount);




                let userTableData = [];
                let totalCasesCount = [];

                let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD/YY");
                for (let item of arrCase) {
                    let currentDay = moment(item.createdAt).format("MM/DD/YY").toString();
                    if (!item.attorney && currentDay > threeDaysAgo) {
                        totalCasesCount.push(item.id);
                        console.log(item.createdAt)
                        userTableData.push({
                            id: item.id,
                            ConnectionMethod: item.consultationConnectionMethod,
                            UserFirstName: item.client.firstName,
                            UserLasttName: item.client.lastName,
                            UserEmail: item.client.email,
                            AreaOfLaw: item.areaOfLaw,
                            UserState: item.client.state,
                            UserDescription: item.legalAdviceDescription,
                            VenueState: item.venueState,
                            PaymentAmount: item.payment.amount,
                            PaymentMethod: item.payment.paymentMethod,
                            CaseDate: item.createdAt,
                            UpdatedAt: item.updatedAt,
                            Connection: "No",
                            FutureConsultationTime: item.futureConsultationDateTime,
                        });
                    }
                }

                setUserTableData(userTableData);

                // console.log(totalCasesCount.length);
                // console.log(totalRatingsCount.length);
                // console.log(totalRatingsCount.length / totalCasesCount.length * 100);

                let averageRatingCount5 = totalRatingsCount.length / totalCasesCount.length * 100
                let averageRatingParticipation = averageRatingCount5.toFixed(2) + "%";
                setAverageRatingParticipation(averageRatingParticipation);

                const columns2 = [
                    { field: 'id', headerName: 'ID', hide: true },
                    { field: 'Connection', headerName: 'Connection', width: 75 },
                    { field: 'ConnectionMethod', headerName: 'Connection Method', width: 100 },
                    { field: 'UserEmail', headerName: 'User Email', width: 220, renderCell: renderCellExpand },
                    { field: 'UserDescription', headerName: 'User Case Description (mouse over to expand)', width: 350, renderCell: renderCellExpand },
                    { field: 'AreaOfLaw', headerName: 'Legal Catagory', width: 160, renderCell: renderCellExpand },
                    { field: 'VenueState', headerName: 'Venue State', width: 110, headerClassName: "wrapHeader" },
                    {
                        field: 'CaseDate', headerName: 'Case Date', width: 230, renderCell: (params) => (
                            moment(params.value).format('MMMM Do YYYY, h:mm a')
                        ),
                    },
                    { field: 'PaymentMethod', headerName: 'Payment Method', width: 150 },
                    { field: 'PaymentAmount', headerName: 'Amount', width: 85 },
                    {
                        field: 'FutureConsultationTime', headerName: 'Future Consultation Time', width: 210, renderCell: (params) => (
                            moment(params.value).format('MMMM Do YYYY, h:mm a')
                        ),
                    },
                    { field: 'UserFirstName', headerName: 'First Name', width: 150, headerClassName: "wrapHeader", renderCell: renderCellExpand },
                    { field: 'UserLasttName', headerName: 'Last Name', width: 150, headerClassName: "wrapHeader", renderCell: renderCellExpand },
                    { field: 'StripeToken', headerName: 'Stripe Token', width: 570, renderCell: renderCellExpand, hide: true },

                ];
                setColums2(columns2);


                const columnsTemp = [
                    { dataField: "id", text: "Case ID" },
                    { dataField: "AttorneyFirstName", text: "Attorney First Name" },
                    { dataField: "AttorneyLastName", text: "Attorney Last Name" },
                    { dataField: "AttorneyWorkEmail", text: "Attorney Email" },
                    { dataField: "UserFirstName", text: "User First Name" },
                    { dataField: "UserLasttName", text: "User Last Name" },
                    { dataField: "UserEmail", text: "User Email" },
                    { dataField: "CaseDate", text: "Case Date",   sort: true },
                    { dataField: "UpdatedAt", text: "Updated At", hidden: true },
                    { dataField: "VenueState", text: "Venue State", hidden: true },
                    { dataField: "AreaOfLaw", text: "Area of Law" },
                    { dataField: "PaymentAmount", text: "Payment Amount", hidden: true },
                    { dataField: "PaymentMethod", text: "Payment Method", hidden: true },
                    { dataField: "OverallRating", text: "Overall Rating", hidden: true },
                    { dataField: "KnowledgeScore", text: "Knowledge Score", hidden: true },
                    { dataField: "FriendlinessScore", text: "Friendliness Score", hidden: true },
                    { dataField: "UserDescription", text: "User Description", hidden: true },
                    { dataField: "FeedbackText", text: "Feedback Text", hidden: true },
                    { dataField: "FutureConsultationTime", text: "Future Consultation Time", hidden: true },
                    { dataField: "StripeToken", text: "Stripe Token", hidden: true },

                    
                ]
                setColumnsTemp(columnsTemp);



                const rowEvents = {
                    onClick: (e, row) => {
                        //console.log(row);
                        setModalInfo(row)
                        toggleTrueFalse()
                    },
                };

                setRowEvents(rowEvents);

                const toggleTrueFalse = () => {
                    setShowModal(handleShow);
                };


            }
        )();
    }, []);
    return (
        <main className={classes.content}>
            <React.Fragment>
                {columns2 ?
                    <>
                        <div style={{ height: '100%', width: '100%' }}>
                            <DataGrid
                                rows={userTableData}
                                columns={columns2}
                                className={classes.root}
                                pageSize={10}
                                autoHeight={true}
                                showCellRightBorder={true}
                            components={{
                                Toolbar: GridToolbar
                            }}
                            sortModel={[
                                {
                                  field: 'CaseDate',
                                  sort: 'desc',
                                },
                              ]}
                            />
                        </div>
                    </> : <Loader active inline='centered' />}
            </React.Fragment>
        </main>
    );
}
