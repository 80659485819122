import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


export default function Chart() {
    const [cases, setCases] = useState(null);
    const [clientCountBarData, setClientBarData] = useState(null);

    const arrayToObjectCount = array => {
        //declare an empty object ( {} = empty object)
        const obj = {};
        //loop through the given array
        for (let item of array) {
            //is the element in the object, if so add one to its value
            if (obj[item]) {
                obj[item]++;
            } else {
                //if not set its value to 1
                obj[item] = 1;
            }
            //return the object
        }
        return obj;
    }

    useEffect(() => {
        (
            async () => {
                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);
                let arrCase = cases;

                let thisWeek = moment().format("WW/YY");
                let oneWeekAgo = moment().subtract(1, 'weeks').endOf('day').format("WW/YY");
                let twoWeeksAgo = moment().subtract(2, 'weeks').endOf('day').format("WW/YY");
                let threeWeeksAgo = moment().subtract(3, 'weeks').endOf('day').format("WW/YY");
                let fourWeeksAgo = moment().subtract(4, 'weeks').endOf('day').format("WW/YY");

                let monthCountArrayUser = [];

                //if area of law found
                for (let item of arrCase) {
                    let currentWeek = moment(item.createdAt).format("WW/YY").toString();
                    if (item.areaOfLaw) {
                        if (currentWeek === thisWeek) {
                            monthCountArrayUser.push(item.areaOfLaw)
                        }
                        if (currentWeek === oneWeekAgo) {
                            monthCountArrayUser.push(item.areaOfLaw)
                        }
                        if (currentWeek === twoWeeksAgo) {
                            monthCountArrayUser.push(item.areaOfLaw)
                        }
                        if (currentWeek === threeWeeksAgo) {
                            monthCountArrayUser.push(item.areaOfLaw)
                        }
                        if (currentWeek === fourWeeksAgo) {
                            monthCountArrayUser.push(item.areaOfLaw)
                        }
                    }
                }
                const practiceAreasFinalCount = arrayToObjectCount(monthCountArrayUser);

                let clientCountBarData = [];
                if (practiceAreasFinalCount) {
                    Object.keys(practiceAreasFinalCount).map((item) => {
                        return (
                            clientCountBarData.push({ PracticeArea: item, Count: practiceAreasFinalCount[item] })
                        )
                    })
                }
                setClientBarData(clientCountBarData);
            }
        )();
    }, []);

    return (
        <React.Fragment>
            <Title>Practice Area Requests this Month</Title>
            <ResponsiveContainer >
                <BarChart
                    data={clientCountBarData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="PracticeArea" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="Count" fill="#8884d8" >
                        <LabelList dataKey="Count" position="middle" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
