import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


export default function Chart() {
  const [cases, setCases] = useState(null);
  const [clientCountBarData, setClientBarData] = useState(null);

  useEffect(() => {
    (
      async () => {
        const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
        const cases = responseListCases.data.listCases.items;
        setCases(cases);
        let arrCase = cases;

        let today = moment().format("MM/DD");
        let oneDayAgo = moment().subtract(1, 'days').endOf('day').format("MM/DD");
        let twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD");
        let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD");
        let fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD");
        let fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD");
        let sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD");
        let sevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD");
        let todayArray = [];
        let oneDayAgoArray = [];
        let twoDaysAgoArray = [];
        let threeDaysAgoArray = [];
        let fourDaysAgoArray = [];
        let fiveDaysAgoArray = [];
        let sixDaysAgoArray = [];
        let sevenDaysAgoArray = [];
        let weekCountArray = [];



        //if attorney found
        for (let item of arrCase) {
          let currentDay = moment(item.createdAt).format("MM/DD").toString();
          if (item.attorney) {
            if (currentDay === today) {
              todayArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === oneDayAgo) {
              oneDayAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === twoDaysAgo) {
              twoDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === threeDaysAgo) {
              threeDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === fourDaysAgo) {
              fourDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === fiveDaysAgo) {
              fiveDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === sixDaysAgo) {
              sixDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === sevenDaysAgo) {
              sevenDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
          }
        }

        let todayArrayAttorney = [];
        let oneDayAgoArrayAttorney = [];
        let twoDaysAgoArrayAttorney = [];
        let threeDaysAgoArrayAttorney = [];
        let fourDaysAgoArrayAttorney = [];
        let fiveDaysAgoArrayAttorney = [];
        let sixDaysAgoArrayAttorney = [];
        let sevenDaysAgoArrayAttorney = [];
        let weekCountArrayAttorney = [];


        for (let item of arrCase) {
          let currentDay = moment(item.createdAt).format("MM/DD").toString();
          if (!item.attorney) {
            if (currentDay === today) {
              todayArrayAttorney.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === oneDayAgo) {
              oneDayAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === twoDaysAgo) {
              twoDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === threeDaysAgo) {
              threeDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === fourDaysAgo) {
              fourDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === fiveDaysAgo) {
              fiveDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === sixDaysAgo) {
              sixDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === sevenDaysAgo) {
              sevenDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
          }
        }

        const clientCountBarData = [
          {
            name: 'Not found', count: weekCountArrayAttorney.length,
          },
          {
            name: 'Found', count: weekCountArray.length,
          },
        ];
        setClientBarData(clientCountBarData);
      }
    )();
  }, []);

  return (
    <React.Fragment>
      <Title>Attorneys Found / Not Found This Week</Title>

      <ResponsiveContainer >
        <BarChart
          data={clientCountBarData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >


          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey="count" fill="#8884d8" >
            <LabelList dataKey="count" position="middle" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
