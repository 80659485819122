
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:30d93baa-77c0-41c0-b62d-a583eb3be380",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_RXnEgvwL6",
    "aws_user_pools_web_client_id": "56t0d0ch7duu8kpbtq8vm9ans8",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qd6ui34onzdrtamm4a3c5ogp5q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [{
            "name": "addUserToGroup",
            "endpoint": "https://w7hze2mnu1.execute-api.us-east-1.amazonaws.com/mobile",
            "region": "us-east-1"
        },
        {
            "name": "quickBloxCustomIdProvider",
            "endpoint": "https://0tn5wj1rue.execute-api.us-east-1.amazonaws.com/mobile",
            "region": "us-east-1"
        },
        {
            "name": "uploadPhoto",
            "endpoint": "https://z8z7xw0485.execute-api.us-east-1.amazonaws.com/mobile",
            "region": "us-east-1"
        },
        {
            "name": "externalActiveCampaign",
            "endpoint": "https://wviyexvnu1.execute-api.us-east-1.amazonaws.com/mobile",
            "region": "us-east-1"
        },
        {
            "name": "manageStripeAccount",
            "endpoint": "https://zios6uadg9.execute-api.us-east-1.amazonaws.com/mobile",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "legalqmobileui9552498daf4145f9a02445e0637102fa134436-mobile",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;