import { Button } from '@material-ui/core';
import React, { useMemo } from 'react';
import DataTable from '../../commons/DataTable';

function ClientsDataTable(props) {
  const { data, handleEdit } = props;

  const columns = [
    { headerClassName: 'data-table-header', field: 'firstName', headerName: 'First Name', flex: 1 },
    { headerClassName: 'data-table-header', field: 'lastName', headerName: 'Last Name', flex: 1 },
    { headerClassName: 'data-table-header', field: 'email', headerName: 'Email', flex: 1 },
    { headerClassName: 'data-table-header', field: 'state', headerName: 'States', flex: 1 },
    { headerClassName: 'data-table-header', field: 'phone', headerName: 'Phone Number', flex: 1 },
    {
      headerClassName: 'data-table-header',
      headerName: 'Actions',
      field: 'actions',
      renderCell: (data) => (
        <>
          <Button variant='contained' color='primary' onClick={() => handleEdit(data.value)}>Edit</Button>
        </>
      ),
      flex: 1,
    }
  ]

  const rows = useMemo(() => data?.map(item => ({
    id: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    state: item.state,
    phone: item.phone,
    actions: item
  })), [data])

  return (
    <>
      <DataTable data={rows} columns={columns} />
    </>
  )
}

export default ClientsDataTable;