// @ts-nocheck
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { mapConsultationsTableData } from '../../ultils';
import CaseDetailsModal from './CaseDetailsModal';
import useStyles from './styles';

const columns = [
  { dataField: "id", text: "Case ID" },
  { dataField: "AttorneyFullName", text: "Attorney Full Name" },
  { dataField: "AttorneyFirstName", text: "Attorney First Name", hidden: true },
  { dataField: "AttorneyLastName", text: "Attorney Last Name", hidden: true },
  { dataField: "AttorneyWorkEmail", text: "Attorney Email" },
  { dataField: "UserFullName", text: "User Full Name" },
  { dataField: "UserFirstName", text: "User First Name", hidden: true },
  { dataField: "UserLastName", text: "User Last Name", hidden: true },
  { dataField: "UserEmail", text: "User Email" },
  { dataField: "CaseDate", text: "Case Date", sort: true },
  { dataField: "UpdatedAt", text: "Updated At", hidden: true },
  { dataField: "VenueState", text: "Venue State", hidden: true },
  { dataField: "AreaOfLaw", text: "Area of Law" },
  { dataField: "PaymentAmount", text: "Payment Amount", hidden: true },
  { dataField: "PaymentMethod", text: "Payment Method", hidden: true },
  { dataField: "OverallRating", text: "Overall Rating", hidden: true },
  { dataField: "KnowledgeScore", text: "Knowledge Score", hidden: true },
  { dataField: "FriendlinessScore", text: "Friendliness Score", hidden: true },
  { dataField: "UserDescription", text: "User Description", hidden: true },
  { dataField: "FeedbackText", text: "Feedback Text", hidden: true },
  { dataField: "FutureConsultationTime", text: "Future Consultation Time", hidden: true },
  { dataField: "StripeToken", text: "Stripe Token", hidden: true },
]

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn btn-success" onClick={handleClick}>Export to CSV</button>
    </div>
  );
};

function CasesDataTable(props) {
  const classes = useStyles();

  const { data } = props;

  const [userTableData, setUserTableData] = useState([])
  const [modalInfo, setModalInfo] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)


  useEffect(() => {
    if (data) {
      mapTableData();
    }
  }, [data])

  const mapTableData = useCallback(async () => {
    const result = await mapConsultationsTableData(data);
    setUserTableData(result);
  }, [data])

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  if (!data) return null;

  return (
    <div className={classes.root}>
      <ToolkitProvider
        keyField="id"
        data={userTableData}
        columns={columns}
        search
        defaultSorted={{
          dataField: 'CaseDate',
          order: 'desc'
        }}
        exportCSV={{
          fileName: 'ConsultationDetails.csv',
        }}
      >
        {
          props =>
            <div>
              <h3>Click the row to bring up the individual case page</h3>
              <Search.SearchBar {...props.searchProps} className={classes.searchBar}/>
              <hr />

              <BootstrapTable
                pagination={paginationFactory()}
                rowEvents={{
                  onClick: (e, row) => {
                    console.log('row :>> ', row);
                    setModalInfo(row)
                    toggleModal()
                  },
                }}
                {...props.baseProps}
              />
              <MyExportCSV {...props.csvProps} />
            </div>
        }
      </ToolkitProvider>

      {/* CONSULTATION DETAILS MODAL */}
      <CaseDetailsModal modalInfo={modalInfo} onClose={toggleModal} isShow={isModalOpen} />

    </div>
  )
}

export default CasesDataTable;