// @ts-nocheck
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTheme } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { mapConsultationPast5Weeks, mapConsultationPastYear, mapConsultationThisWeek } from '../../ultils';
import Title from '../Title';
import useStyles from './styles';
import { useMemo } from 'react';

function ConsultationLineChart(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { data, activeMode } = props;

  const [clientCountBarData, setClientCountBarData] = useState([])

  useEffect(() => {
    switch (activeMode) {
      case 'FirstCard':
        mapThisWeekData();
        break;
      case 'SecondCard':
        mapPast5WeeksData();
        break;
      case 'ThirdCard':
        mapThisYearData();
        break;
      default:
        break;
    }
  }, [activeMode, data])

  const mapThisWeekData = useCallback(() => {
    const chartData = mapConsultationThisWeek(data);
    setClientCountBarData(chartData);
  }, [data])

  const mapPast5WeeksData = useCallback(() => {
    const chartData = mapConsultationPast5Weeks(data);
    setClientCountBarData(chartData);
  }, [data])

  const mapThisYearData = useCallback(() => {
    const chartData = mapConsultationPastYear(data);
    setClientCountBarData(chartData);
  }, [data])

  const activeChartText = useMemo(() => {
    switch (activeMode) {
      case 'FirstCard':
        return 'This Week';
      case 'SecondCard':
        return 'Past 5 Weeks';
      case 'ThirdCard':
        return 'Past Year';
      default:
        return 'This Week';
    }
  }, [activeMode])

  if (!data) {
    return <CircularProgress style={{ alignSelf: 'center', display: 'flex' }} />
  }

  return (
    <>
      <Title>Number of consultation connections {activeChartText}</Title>
      <ResponsiveContainer >
        <LineChart
          data={clientCountBarData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="XAxisPoints" stroke={theme.palette.text.secondary} />
          <XAxis stroke={theme.palette.text.secondary} allowDecimals={false} >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Connection Count
            </Label>
          </XAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          <Line name="Failed connections" type="monotone" dataKey="ClientCount" stroke="#ff7300" />
          <Line name="Successful connection" type="monotone" dataKey="AttorneyCount" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}

export default ConsultationLineChart;