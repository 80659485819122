import React, {useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import { Route } from "react-router-dom";
import Chart from './Chart';
import JoinMonthCount from './JoinMonthCount';
import JoinYearCount from './JoinYearCount';
import Deposits from './Deposits';
import Totals from './Totals';
import Orders from './Totals';
import LegalCategories from './LegalCategories';
import AttorneyDetails from './AttorneyDetails';
import AttorneyLive from './LiveStatus';
import UserTrends from './UserTrends';
import ConsultationDetails from './ConsultationDetails';
import Button from '@material-ui/core/Button';
import { AmplifySignOut } from '@aws-amplify/ui-react';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
}));

function PracticeAreas() {

    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const [active, setActive] = useState("FirstCard")

    return (

        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    
                    <Button variant="contained" onClick={() => setActive("FirstCard")}>This Week</Button>
                    <Button variant="contained" onClick={() => setActive("SecondCard")}>Past 5 Weeks</Button>
                    <Button variant="contained" onClick={() => setActive("ThirdCard")}>Past Year</Button>

                    <Grid item xs={12} md={9} lg={9}>
                        <Paper className={fixedHeightPaper}>
                            {active === "FirstCard" && <Chart />}
                            {active === "SecondCard" && <JoinMonthCount />}
                            {active === "ThirdCard" && <JoinYearCount />}
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={12} md={3} lg={3}>
                        <Paper className={fixedHeightPaper}>
                            <Deposits />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={fixedHeightPaper}>
                            <Totals />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </main>

    );

}
export default PracticeAreas;

