import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Divider, Header, Icon, Statistic } from 'semantic-ui-react';
import useStyles from './styles';


function CaseDetailsModal(props) {
  const { modalInfo, isShow, onClose } = props;
  const classes = useStyles();

  if (!isShow) return null;

  return (
    <Modal show={isShow} onHide={onClose} centered size="lg">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Modal.Header >
            <Modal.Title >{modalInfo.AttorneyFirstName} {modalInfo.AttorneyLastName} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box display="flex" justifyContent="center">
              <Avatar alt={modalInfo.AttorneyAvatar} src={modalInfo.AttorneyAvatar} className={classes.boxLarge} variant="rounded" />
            </Box>
            <Divider clearing />

            <Statistic.Group size='mini' widths='1' >

              <Statistic >
                <Statistic.Value text>
                  <Icon name='mail' /> {modalInfo.AttorneyWorkEmail}
                </Statistic.Value>
                <Statistic.Label>Email</Statistic.Label>
              </Statistic>

            </Statistic.Group>


            <Divider horizontal>
              <Header as='h4'>
                <Icon name='address card' />
                ID
              </Header>
            </Divider>

            <Statistic.Group widths='1' size='tiny'>
              <Statistic>
                <Statistic.Value text> {modalInfo.UserDescription}</Statistic.Value>
              </Statistic>
            </Statistic.Group>

            <Divider horizontal>
              <Header as='h4'>
                <Icon name='time' />
                Case Taken
              </Header>
            </Divider>

            <Statistic.Group widths='3' size='mini'>
              <Statistic>
                <Statistic.Value>{modalInfo.OverallRating}</Statistic.Value>
                <Statistic.Label>Overall Rating</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{modalInfo.KnowledgeScore}</Statistic.Value>
                <Statistic.Label>Knowledge</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value>{modalInfo.FriendlinessScore}</Statistic.Value>
                <Statistic.Label>Friendliness</Statistic.Label>
              </Statistic>
            </Statistic.Group>

            <Divider horizontal>
              <Header as='h4'>
                <Icon name='clipboard' />
                Attorney Reviews
              </Header>
            </Divider>

            <Statistic.Group widths='2' size='tiny'>
              <Statistic>
                <Statistic.Value text>{modalInfo.VenueState}</Statistic.Value>
                <Statistic.Label>Venue State</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value text>{modalInfo.AreaOfLaw}</Statistic.Value>
                <Statistic.Label>Legal category</Statistic.Label>
              </Statistic>
            </Statistic.Group>
            <Divider horizontal>
              <Header as='h4'>
                <Icon name='book' />
                Reference
              </Header>
            </Divider>
          </Modal.Body>
        </Grid>

        {/* --------------------------------------------------------------------------------------------------------------------------------------------------- */}

        <Grid item xs={6}>
          <Modal.Header >
            <Modal.Title >{modalInfo.UserFirstName} {modalInfo.UserLasttName} </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {/* <Image src={modalInfo.AttorneyAvatar} alt="No Image" size='small' centered /> */}
            <Box display="flex" justifyContent="center">
              <Avatar alt={modalInfo.UserAvatar} src={modalInfo.UserAvatar} className={classes.boxLarge} variant="rounded" />
            </Box>
            <Divider clearing />

            <Statistic.Group size='mini' widths='1' >

              <Statistic >
                <Statistic.Value text>
                  <Icon name='mail' /> {modalInfo.UserEmail}
                </Statistic.Value>
                <Statistic.Label>Email</Statistic.Label>
              </Statistic>

            </Statistic.Group>


            <Divider horizontal>
              <Header as='h4'>
                <Icon name='address card' />
                ID
              </Header>
            </Divider>

            <Statistic.Group widths='2' size='tiny'>
              <Statistic>
                <Statistic.Value >$ {modalInfo.PaymentAmount}</Statistic.Value>
                <Statistic.Label>Payment Amount</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value text>{modalInfo.PaymentMethod}</Statistic.Value>
                <Statistic.Label>Payment Methods</Statistic.Label>
              </Statistic>
            </Statistic.Group>
            <Divider horizontal>
              <Header as='h4'>
                <Icon name='payment' />
                Payment
              </Header>
            </Divider>

            <Statistic.Group widths='2' size='mini'>
              <Statistic>
                <Statistic.Value text>{moment(modalInfo.CaseDate).format('L, h:mm a')}</Statistic.Value>
                <Statistic.Label>Case Date</Statistic.Label>
              </Statistic>
              <Statistic>
                <Statistic.Value text>{moment(modalInfo.UpdatedAt).format('L, h:mm a')}</Statistic.Value>
                <Statistic.Label>Updated At</Statistic.Label>
              </Statistic>
              {/* <Statistic>
                    <Statistic.Value>{modalInfo.futureConsultationDateTime}</Statistic.Value>
                    <Statistic.Label>Friendliness</Statistic.Label>
                </Statistic> */}
            </Statistic.Group>

            <Divider horizontal>
              <Header as='h4'>
                <Icon name='calendar alternate' />
                Case Date
              </Header>
            </Divider>

            <Statistic.Group widths='1' size='tiny'>
              <Statistic>
                <Statistic.Value text>{modalInfo.FeedbackText}</Statistic.Value>

              </Statistic>
            </Statistic.Group>
            <Divider horizontal>
              <Header as='h4'>
                <Icon name='book' />
                FeedBack Text
              </Header>
            </Divider>
          </Modal.Body>
        </Grid>
      </Grid>



      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>

      </Modal.Footer>
      <Divider vertical></Divider>
    </Modal>
  )
}

export default CaseDetailsModal;