import React, { useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import clsx from 'clsx';
import * as queries from '../graphql/customQueries';
import {
    ScatterChart,
    Scatter,
    XAxis,
    YAxis,
    ZAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Label
} from "recharts";
import moment from 'moment';

const data01 = [
    { x: 100, y: 200, z: 200 },
    { x: 120, y: 100, z: 260 },
    { x: 170, y: 300, z: 400 },
    { x: 140, y: 250, z: 280 },
    { x: 150, y: 400, z: 500 },
    { x: 110, y: 280, z: 200 }
];
const data02 = [
    { x: 200, y: 260, z: 240 },
    { x: 240, y: 290, z: 220 },
    { x: 190, y: 290, z: 250 },
    { x: 198, y: 250, z: 210 },
    { x: 180, y: 280, z: 260 },
    { x: 210, y: 220, z: 230 }
];


const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

const averageSecondsFormat = array => {

    let average = (array) => array.reduce((a, b) => a + b) / array.length;
    var minutes = Math.floor(average(array) / 60);
    var seconds = average(array) - minutes * 60;

    let averageTime = minutes + ":" + seconds.toFixed(0);

    return averageTime;

}

export default function App() {

    const [cases, setCases] = useState(null);
    const [consultationsStatus, setConsultationsStatus] = useState(null);
    const [caseConnectionMEthodCount, setCaseConnectionMEthodCount] = useState(null);
    const [averageTimeInConsultation, setAverageTimeInConsultation] = useState(null);
    const [averageWaitTime, setAverageWaitTime] = useState(null);
    const [averageWaitTimeAccepted, setAverageWaitTimeAccepted] = useState(null);
    const [averageWaitTimeRejected, setAverageWaitTimeRejected] = useState(null);
    const [averageWaitTimeReject, setAverageWaitTimeReject] = useState(null);
    const [averageFriendlinessScore, setAverageFriendlinessScore] = useState(null);
    const [averageKnowledgeScore, setAverageKnowledgeScore] = useState(null);
    const [averageOverallScore, setAverageOverallScore] = useState(null);
    const [averageRatingParticipation, setAverageRatingParticipation] = useState(null);

    useEffect(() => {
        (
            async () => {

                // ----------------------- FETCH CONSULTATION DATA -------------------------------------
                let arrConsultations = [];
                try {
                    const responseListConsultations = await API.graphql(graphqlOperation(queries.listConsultationsTest));
                    arrConsultations = responseListConsultations.data.listConsultations.items?.filter(q => q);

                } catch (error) {
                    arrConsultations = error.data.listConsultations.items?.filter(q => q);
                }


                let arrlistAttorneyConsultationRequests = [];
                try {
                    const responseListAttorneyConsultationRequests = await API.graphql(graphqlOperation(queries.listAttorneyConsultationRequestsTest));
                    arrlistAttorneyConsultationRequests = responseListAttorneyConsultationRequests.data.listAttorneyConsultationRequests.items?.filter(q => q);
                } catch (error) {
                    arrlistAttorneyConsultationRequests = error.data.listAttorneyConsultationRequests.items?.filter(q => q);
                }

                let cases = [];
                try {
                    const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                    cases = responseListCases.data.listCases.items?.filter(q => q);
                } catch (error) {
                    cases = error.data.listCases.items?.filter(q => q);
                }
                setCases(cases);

                // console.log(arrlistAttorneyConsultationRequests)



                // ------------------- HANDLE STATISTICS -------------------
                let arrCase = cases;
                
                let consultationsStatusArray = [];
                //put status into a single array
                let today = moment().format("MM/DD/YY, h:mm a");
                let freeSessionAgo = moment().subtract(15, 'minutes');
                let twoMonthsago = moment().subtract(2, 'months');
                // console.log(typeof (Date(testingago))+"\n"+testingago);

                for (let item of arrConsultations) {
                    let consultationdate = moment(item.createdAt);

                    if (item.status == "Completed") {
                        consultationsStatusArray.push(item.status);
                    }

                    if (item.status == "InProgress" && consultationdate > freeSessionAgo) {
                        consultationsStatusArray.push("In Progress");
                        // console.log("Consultation Date " + consultationdate + "\n freeSession ago " + freeSessionAgo)
                    }
                    if (item.status == "Scheduled" && consultationdate > twoMonthsago && item.clientJoined == null) {
                        consultationsStatusArray.push(item.status);
                    }

                }
                // console.log(consultationsStatusArray)
                //count the statues in the array and save as object
                const consultationsStatusCount = arrayToObjectCount(consultationsStatusArray);

                setConsultationsStatus(consultationsStatusCount);
                let average = (array) => array.reduce((a, b) => a + b) / array.length;

                let waitTimeCountAcceptTotal = [];
                let waitTimeCountRejectTotal = [];
                let waitTimeCountPendingTotal = [];
                for (let item of arrlistAttorneyConsultationRequests) {
                        // console.log(item)
                    if (item.clientWaitTime && item.status == "Accept" && item.clientWaitTime < 1000) {
                        waitTimeCountAcceptTotal.push({WaitTime: item.clientWaitTime, RequestedAttorneys: item.requestedAttorneys.length});
                    }

                    if (item.clientWaitTime && item.status == "Reject") {
                        waitTimeCountRejectTotal.push({WaitTime: item.clientWaitTime, RequestedAttorneys: item.requestedAttorneys.length});
                    }
                    if (item.clientWaitTime && item.status == "Pending") {
                        waitTimeCountPendingTotal.push(item.clientWaitTime);
                    }
                }
                console.log(waitTimeCountAcceptTotal)

                let averageWaitTimeAcceptFormat = averageSecondsFormat(waitTimeCountAcceptTotal);
                let averageWaitTimeRejectFormat = averageSecondsFormat(waitTimeCountRejectTotal);
                let averageWaitTimePendingFormat = averageSecondsFormat(waitTimeCountPendingTotal);
                // console.log("Accept WaitTimesFormat " + averageWaitTimeAcceptFormat);
                // console.log("Reject WaitTimesFormat " + averageWaitTimeRejectFormat);
                // console.log("Pending WaitTimesFormat " + averageWaitTimePendingFormat);
                setAverageWaitTime(averageWaitTimeAcceptFormat);
                setAverageWaitTimeReject(averageWaitTimeRejectFormat);
                setAverageWaitTimeRejected(waitTimeCountRejectTotal);
                setAverageWaitTimeAccepted(waitTimeCountAcceptTotal);

                let averageConsultationTime = [];
                let consultationTime15 = [];
                let consultationTime30 = [];

                for (let item of arrConsultations) {

                    if (item.actualLength) {
                        averageConsultationTime.push(item.actualLength)
                        if (item.purchasedLength === 15) {
                            consultationTime15.push(item.actualLength)
                        }
                        if (item.purchasedLength === 30) {
                            consultationTime30.push(item.actualLength)
                        }
                    }
                    if (item.connectionMethod) {

                    }
                }

                const averageTimeInConsultation = averageSecondsFormat(consultationTime15);

                setAverageTimeInConsultation(averageTimeInConsultation);


                let countOverallScore = [];
                let countKnowledgeScore = [];
                let countFriendlinessScore = [];
                let totalRatingsCount = [];


                for (let item of arrCase) {
                    if (item.rating) {
                        totalRatingsCount.push(item.id)
                        if (item.rating.friendlinessScore) {
                            countFriendlinessScore.push(item.rating.friendlinessScore);
                        }
                        if (item.rating.knowledgeScore) {
                            countKnowledgeScore.push(item.rating.knowledgeScore);
                        }
                        if (item.rating.overallScore) {
                            countOverallScore.push(item.rating.overallScore);
                        }
                    }
                }

                let averageFriendlinessScore = average(countFriendlinessScore);
                let averageKnowledgeScore = average(countKnowledgeScore);
                let averageOverallScore = average(countOverallScore);

                setAverageFriendlinessScore(averageFriendlinessScore);
                setAverageKnowledgeScore(averageKnowledgeScore);
                setAverageOverallScore(averageOverallScore);


                let caseConnectionMethod = [];

                for (let item of arrCase) {
                    if (item.attorney) {
                        caseConnectionMethod.push(item.consultationConnectionMethod)
                    }
                }

                const caseConnectionMEthodCount = arrayToObjectCount(caseConnectionMethod);
                setCaseConnectionMEthodCount(caseConnectionMEthodCount);

                let totalCasesCount = [];

                for (let item of arrCase) {
                    if (item.attorney) {
                        totalCasesCount.push(item.id);
                    }
                }

                let averageRatingCount5 = totalRatingsCount.length / totalCasesCount.length * 100
                let averageRatingParticipation = averageRatingCount5.toFixed(2) + "%";
                setAverageRatingParticipation(averageRatingParticipation);
            }
        )();
    }, []);
    return (
        <div style={{ width: '100%', height: 500 }}>
        <ResponsiveContainer>
        <ScatterChart
            margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20
            }}
        >
            <CartesianGrid />
            <XAxis type="number" dataKey="WaitTime" name="Wait Time">
            <Label value="Seconds" offset={0} position="insideBottom" />
            </XAxis>
            <YAxis type="number" dataKey="RequestedAttorneys" name="Requested attorneys">
            <Label value="Attorneys Requested" offset={0} position="insideLeft" angle = {-90} />
            </YAxis>
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Legend />
            <Scatter name="Accepted" data={averageWaitTimeAccepted} fill="#8884d8"  />
            <Scatter name="Rejected" data={averageWaitTimeRejected} fill="#e60000"  />
        </ScatterChart>
        </ResponsiveContainer>
        </div>
    );
}
