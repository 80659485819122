import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend } from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


// const weekCount = dateTime => {

//   let today = moment().format("MM/DD");
//   let yesterday = moment().subtract(1, 'days').endOf('day').format("MM/DD");
//   let twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD");
//   let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD");
//   let fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD");
//   let fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD");
//   let sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD");
//   let SevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD");
//   //let createdAtTime = moment(item.createdAt).format("MM/DD").toString();

//   const obj = [{}];

//   for (let item of dateTime) {
//     let createdAtTime = moment(dateTime).format("MM/DD").toString();

//     if (createdAtTime === yesterday) {
//         obj.push({YesterdayCount: createdAtTime})
//     }
//   }


//   return obj;


// }

export default function Chart() {
  const theme = useTheme();
  //const classes = useStyles();
  const [practice, setPractice] = useState(null);
  const [client, setClient] = useState(null);
  const [joinCountLineData, setJoinLineData] = useState(null);


  useEffect(() => {
    (
      async () => {
        //here it grabs listPracticeAreas
        const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
        //set practice to listAttorneys
        const practice = responseListAttorneys.data.listAttorneys.items;
        setPractice(practice);
        let arrPractice = practice;

        const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
        const client = responseListClients.data.listClients.items;
        setClient(client);
        let arrClient = client;

        // const test = new Date('2021-02-01T16:36:00.704Z');
        // const test2 = moment("2021-02-01T16:36:00.704Z");
        // const testYesterday = test2;

        // console.log(testYesterday.subtract(1, 'days').endOf('day').format("MM/DD").toString());
        // console.log(practice)
        let today = moment().format("MM/DD");
        let yesterday = moment().subtract(1, 'days').endOf('day').format("MM/DD");
        let todayArray = [];


        let thisWeek = moment().format("WW/YY");
        let thisMonth = moment().format("MM/YY");
        let oneMonthAgo = moment().subtract(1, 'months').endOf('day').format("MM/YY");
        let twoMonthsAgo = moment().subtract(2, 'months').endOf('day').format("MM/YY");
        let threeMonthsAgo = moment().subtract(3, 'months').endOf('day').format("MM/YY");
        let fourMonthsAgo = moment().subtract(4, 'months').endOf('day').format("MM/YY");
        let fiveMonthsAgo = moment().subtract(5, 'months').endOf('day').format("MM/YY");
        let sixMonthsAgo = moment().subtract(6, 'months').endOf('day').format("MM/YY");
        let sevenMonthsAgo = moment().subtract(7, 'months').endOf('day').format("MM/YY");
        let eightMonthsAgo = moment().subtract(8, 'months').endOf('day').format("MM/YY");
        let nineMonthsAgo = moment().subtract(9, 'months').endOf('day').format("MM/YY");
        let tenMonthsAgo = moment().subtract(10, 'months').endOf('day').format("MM/YY");
        let elevenMonthsAgo = moment().subtract(11, 'months').endOf('day').format("MM/YY");
        let twelveMonthsAgo = moment().subtract(12, 'months').endOf('day').format("MM/YY");
        let thisMonthArrayUser = [];
        let oneMonthAgoArrayUser = [];
        let twoMonthsAgoArrayUser = [];
        let threeMonthsAgoArrayUser = [];
        let fourMonthsAgoArrayUser = [];
        let fiveMonthsAgoArrayUser = [];
        let sixMonthsAgoArrayUser = [];
        let sevenMonthsAgoArrayUser = [];
        let eightMonthsAgoArrayUser = [];
        let nineMonthsAgoArrayUser = [];
        let tenMonthsAgoArrayUser = [];
        let elevenMonthsAgoArrayUser = [];
        let twelveMonthsAgoArrayUser = [];
        let yearCountArray = [];
        let clientValidIDArray = [];
        //console.log("test", today);


//month attorney 
       for (let item of arrClient) {
        let currentYear = moment(item.createdAt).format("MM/YY").toString();

        //If the client has an ID and accepts the TC and PP 
        if (item.id && item.acceptsTCAndPP === true) {
          clientValidIDArray.push(item.id);
          //weekCountArray  = weekCount(item.createdAt)
          //weekCountArray.push(weekCount(item.createdAt));
          if (currentYear === thisMonth) {
            thisMonthArrayUser.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === oneMonthAgo) {
            oneMonthAgoArrayUser.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === twoMonthsAgo) {
            twoMonthsAgoArrayUser.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === threeMonthsAgo) {
            threeMonthsAgoArrayUser.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === fourMonthsAgo) {
            fourMonthsAgoArrayUser.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === fiveMonthsAgo) {
              fiveMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === sixMonthsAgo) {
              sixMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === sevenMonthsAgo) {
              sevenMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === eightMonthsAgo) {
              eightMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === nineMonthsAgo) {
              nineMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === tenMonthsAgo) {
              tenMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === elevenMonthsAgo) {
              elevenMonthsAgoArrayUser.push(item.id);
              yearCountArray.push(item.id)
            }
        }else{
          //console.log("nothing")
        }
        //checking if anyone is able to enter the app without acceping TC and PP
      }

      let thisMonthArrayAttorney = [];
      let oneMonthAgoArrayAttorney = [];
      let twoMonthsAgoArrayAttorney = [];
      let threeMonthsAgoArrayAttorney = [];
      let fourMonthsAgoArrayAttorney = [];
      let fiveMonthsAgoArrayAttorney = [];
      let sixMonthsAgoArrayAttorney = [];
      let sevenMonthsAgoArrayAttorney = [];
      let eightMonthsAgoArrayAttorney = [];
      let nineMonthsAgoArrayAttorney = [];
      let tenMonthsAgoArrayAttorney = [];
      let elevenMonthsAgoArrayAttorney = [];
      let YearCountArrayAttorney = [];
      for (let item of arrPractice) {
        let currentYear = moment(item.createdAt).format("MM/YY").toString();
  
        //If the client has an ID and accepts the TC and PP 
        if (item.id) {
          clientValidIDArray.push(item.id);

          if (currentYear === thisMonth) {
            thisMonthArrayAttorney.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === oneMonthAgo) {
            oneMonthAgoArrayAttorney.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === twoMonthsAgo) {
            twoMonthsAgoArrayAttorney.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === threeMonthsAgo) {
            threeMonthsAgoArrayAttorney.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === fourMonthsAgo) {
            fourMonthsAgoArrayAttorney.push(item.id);
            yearCountArray.push(item.id)
          }
          if (currentYear === fiveMonthsAgo) {
            fiveMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === sixMonthsAgo) {
                sixMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === sevenMonthsAgo) {
                sevenMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === eightMonthsAgo) {
                eightMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === nineMonthsAgo) {
                nineMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === tenMonthsAgo) {
                tenMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
            if (currentYear === elevenMonthsAgo) {
                elevenMonthsAgoArrayAttorney.push(item.id);
              yearCountArray.push(item.id)
            }
        }else{
          //console.log("nothing")
        }
        //checking if anyone is able to enter the app without acceping TC and PP
      }

        //console.log(weekCountArray);

        const joinCountLineData = [];
        joinCountLineData.push({Year: thisMonth, ClientCountYear: thisMonthArrayUser.length, AttorneyCountYear: thisMonthArrayAttorney.length})
        joinCountLineData.push({Year: oneMonthAgo, ClientCountYear: oneMonthAgoArrayUser.length, AttorneyCountYear: oneMonthAgoArrayAttorney.length})
        joinCountLineData.push({Year: twoMonthsAgo, ClientCountYear: twoMonthsAgoArrayUser.length, AttorneyCountYear: twoMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: threeMonthsAgo, ClientCountYear: threeMonthsAgoArrayUser.length, AttorneyCountYear: threeMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: fourMonthsAgo, ClientCountYear: fourMonthsAgoArrayUser.length, AttorneyCountYear: fourMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: fiveMonthsAgo, ClientCountYear: fiveMonthsAgoArrayUser.length, AttorneyCountYear: fiveMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: sixMonthsAgo, ClientCountYear: sixMonthsAgoArrayUser.length, AttorneyCountYear: sixMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: sevenMonthsAgo, ClientCountYear: sevenMonthsAgoArrayUser.length, AttorneyCountYear: sevenMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: eightMonthsAgo, ClientCountYear: eightMonthsAgoArrayUser.length, AttorneyCountYear: eightMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: nineMonthsAgo, ClientCountYear: nineMonthsAgoArrayUser.length, AttorneyCountYear: nineMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: tenMonthsAgo, ClientCountYear: tenMonthsAgoArrayUser.length, AttorneyCountYear: tenMonthsAgoArrayAttorney.length})
        joinCountLineData.push({Year: elevenMonthsAgo, ClientCountYear: elevenMonthsAgoArrayUser.length, AttorneyCountYear: elevenMonthsAgoArrayAttorney.length})
        //console.log("look here",clientCountBarData); 
        setJoinLineData(joinCountLineData.reverse());


        // console.log("This many users signed up yesterday: ", oneDayAgoArray.length);
        // console.log(oneDayAgoArray);
        // console.log(weekCountArray);


      }
    )();
  }, []);

  return (
    <React.Fragment>
      <Title>Number of Users and Attorneys That Have Joined This Year</Title>
      <ResponsiveContainer >
        <LineChart
          data={joinCountLineData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="Year" stroke={theme.palette.text.secondary}  />
          <YAxis stroke={theme.palette.text.secondary} allowDecimals={false} >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Count of users
            </Label>
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
            <Legend/>
          <Line name="User Count" type="monotone" dataKey="ClientCountYear" stroke="#8884d8" />
          <Line name="Attorney Count" type="monotone" dataKey="AttorneyCountYear" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

