import Button from '@material-ui/core/Button';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable from '../../commons/DataTable/index';
import { mapConsultationsTableData } from '../../ultils';
import { renderCellExpand } from '../renderCellExpand';
import Title from '../Title';
import EditConsultationModal from './EditConsultationModal';
import { createConsultationRating, updateConsultationRating, updateCaseRating } from '../../graphql/customMutations';

const sortModel = [
  {
    field: 'CaseDate',
    sort: 'desc',
  },
]

function ConsultationsDataTable(props) {
  const { data } = props;
  const [userTableData, setUserTableData] = useState();
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [selectedConsultation, setSelectedConsultation] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data) {
      mapTableData();
    }
  }, [data]);

  const mapTableData = useCallback(async () => {
    const result = await mapConsultationsTableData(data);
    setUserTableData(result);
  }, [data]);

  const renderActionButtons = useCallback((data) => {
    return (
      <div className="action-buttons">
        <Button variant='contained' color='primary' onClick={() => handleEditConsultation(data)}>Edit</Button>
      </div>
    )
  }, [data])

  const columns = useMemo(() => {
    return [
      { field: 'id', headerName: 'ID', hide: true },
      { field: 'Connection', headerName: 'Connection', width: 150 },
      { field: 'ConnectionMethod', headerName: 'Connection Method', width: 200 },
      { field: 'UserFullName', headerName: 'User Full Name', width: 150, },
      { field: 'UserEmail', headerName: 'User Email', width: 220, },
      { field: 'AttorneyFullName', headerName: 'Attorney Full Name', width: 220, },
      { field: 'AttorneyWorkEmail', headerName: 'Attorney Work Email', width: 220, },
      { field: 'UserDescription', headerName: 'User Case Description (mouse over to expand)', width: 350, renderCell: renderCellExpand },
      { field: 'VenueState', headerName: 'Venue State', width: 110, headerClassName: "wrapHeader" },
      { field: 'AreaOfLaw', headerName: 'Legal Catagory', width: 160, },
      { field: 'PaymentAmount', headerName: 'Amount', width: 100 },
      { field: 'PaymentMethod', headerName: 'Payment Method', width: 150 },
      { field: 'OverallRating', headerName: 'Overall Rating', width: 80, headerClassName: "wrapHeader" },
      { field: 'KnowledgeScore', headerName: 'Knowledge Score', width: 80, headerClassName: "wrapHeader" },
      { field: 'FriendlinessScore', headerName: 'Friendliness Score', width: 80, headerClassName: "wrapHeader" },
      { field: 'FeedbackText', headerName: 'Feedback Text', width: 350, },
      {
        field: 'FutureConsultationTime', headerName: 'Future Consultation Time', width: 230, renderCell: (params) =>
          moment(params.value).format('MMMM Do YYYY, h:mm a'),
      },
      {
        field: 'CaseDate', headerName: 'Case Date', width: 230, renderCell: (params) => (
          moment(params.value).format('MMMM Do YYYY, h:mm a')
        ),
      },
      {
        field: 'UpdatedAt', headerName: 'Updated At', width: 230, renderCell: (params) => (
          moment(params.value).format('MMMM Do YYYY, h:mm a')
        ),
      },
      { field: 'StripeToken', headerName: 'Stripe Token', width: 300 },
      { field: 'actions', headerName: 'Actions', renderCell: (params) => renderActionButtons(params.value) }
    ];
  }, [data]);

  const handleToggleModal = () => {
    setIsShowEditModal(!isShowEditModal);
  }

  const handleSubmitEdit = async (values) => {
    console.log(`values`, values)
    setIsSubmitting(true);
    if (values) {
      try {
        if (values.rating && values.attorney && values.client) {
          await API.graphql(
            graphqlOperation(updateConsultationRating, {
              input: {
                id: values?.rating?.id,
                overallScore: values?.rating?.overallScore,
                knowledgeScore: values?.rating?.knowledgeScore,
                friendlinessScore: values?.rating?.friendlinessScore,
                feedbackText: values?.rating?.feedbackText,
              },
            })
          );
        } else {
          alert('Attorney or Client info not found')
        }
      } catch (error) {
        console.error(`error`, error)
        if (error.data === null) {
          // create new rating
          const createRatingReponse = await API.graphql(
            graphqlOperation(createConsultationRating, {
              input: {
                overallScore: values?.rating?.overallScore,
                knowledgeScore: values?.rating?.knowledgeScore,
                friendlinessScore: values?.rating?.friendlinessScore,
                feedbackText: values?.rating?.feedbackText,
                ratingAttorneyId: values?.attorney?.id,
                ratingCaseId: values?.id,
              }
            })
          )
          // update case rating id
          if(createRatingReponse) {
            await API.graphql(
              graphqlOperation(updateCaseRating, {
                input: {
                  id: values?.id,
                  caseRatingId: createRatingReponse?.data?.createRating?.id,
                },
              })
            );
          }
        } else {
          alert('Edit consultation failed')
        }
      } finally {
        setIsSubmitting(false);
        handleToggleModal();
      }
    }
  }

  const handleEditConsultation = (data) => {
    setSelectedConsultation(data);
    handleToggleModal();
  }

  if (!data || !userTableData) return <h2>Fetching Consultations...</h2>

  return (
    <div style={{ height: 910, width: '100%' }}>
      <Title>Consultations List</Title>
      <DataTable data={userTableData} columns={columns} sortModel={sortModel} columnBuffer={5} showCellRightBorder={true} />

      <EditConsultationModal
        visible={isShowEditModal}
        data={selectedConsultation}
        onClose={handleToggleModal}
        onSubmit={handleSubmitEdit} 
        isLoading={isSubmitting}/>
    </div>
  )
}

export default ConsultationsDataTable;