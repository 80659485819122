import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


export default function Chart() {
    const [cases, setCases] = useState(null);
    const [clientCountBarData, setClientBarData] = useState(null);

    const arrayToObjectCount = array => {
        //declare an empty object ( {} = empty object)
        const obj = {};
        //loop through the given array
        for (let item of array) {
            //is the element in the object, if so add one to its value
            if (obj[item]) {
                obj[item]++;
            } else {
                //if not set its value to 1
                obj[item] = 1;
            }
            //return the object
        }
        return obj;
    }

    useEffect(() => {
        (
            async () => {
                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);
                let arrCase = cases;


                let thisMonth = moment().format("MM/YY");
                let oneMonthAgo = moment().subtract(1, 'months').endOf('day').format("MM/YY");
                let twoMonthsAgo = moment().subtract(2, 'months').endOf('day').format("MM/YY");
                let threeMonthsAgo = moment().subtract(3, 'months').endOf('day').format("MM/YY");
                let fourMonthsAgo = moment().subtract(4, 'months').endOf('day').format("MM/YY");
                let fiveMonthsAgo = moment().subtract(5, 'months').endOf('day').format("MM/YY");
                let sixMonthsAgo = moment().subtract(6, 'months').endOf('day').format("MM/YY");
                let sevenMonthsAgo = moment().subtract(7, 'months').endOf('day').format("MM/YY");
                let eightMonthsAgo = moment().subtract(8, 'months').endOf('day').format("MM/YY");
                let nineMonthsAgo = moment().subtract(9, 'months').endOf('day').format("MM/YY");
                let tenMonthsAgo = moment().subtract(10, 'months').endOf('day').format("MM/YY");
                let elevenMonthsAgo = moment().subtract(11, 'months').endOf('day').format("MM/YY");
                let yearCountArray = [];
            

                //if area of law found
                for (let item of arrCase) {
                    let currentYear = moment(item.createdAt).format("MM/YY").toString();
                    if (item.areaOfLaw) {
                        if (currentYear === thisMonth) {
                            // thisMonthArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === oneMonthAgo) {
                            // oneMonthAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === twoMonthsAgo) {
                            // twoMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === threeMonthsAgo) {
                            // threeMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === fourMonthsAgo) {
                            // fourMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === fiveMonthsAgo) {
                            // fiveMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === sixMonthsAgo) {
                            // sixMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === sevenMonthsAgo) {
                            // sevenMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === eightMonthsAgo) {
                            // eightMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === nineMonthsAgo) {
                            // nineMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === tenMonthsAgo) {
                            // tenMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                        if (currentYear === elevenMonthsAgo) {
                            // elevenMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.areaOfLaw)
                        }
                    }
                }
                const practiceAreasFinalCount = arrayToObjectCount(yearCountArray);

                let clientCountBarData = [];
                if (practiceAreasFinalCount) {
                    Object.keys(practiceAreasFinalCount).map((item) => {
                        return (
                            clientCountBarData.push({ PracticeArea: item, Count: practiceAreasFinalCount[item] })
                        )
                    })
                }
                setClientBarData(clientCountBarData);
            }
        )();
    }, []);

    return (
        <React.Fragment>
            <Title>Practice Area Requests this Year</Title>
            <ResponsiveContainer >
                <BarChart
                    data={clientCountBarData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="PracticeArea" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="Count" fill="#8884d8" >
                        <LabelList dataKey="Count" position="middle" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
