import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


export default function Chart() {
    const [cases, setCases] = useState(null);
    const [clientCountBarData, setClientBarData] = useState(null);
    useEffect(() => {
        (
            async () => {

                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);
                let arrCase = cases;

                let thisMonth = moment().format("MM/YY");
                let oneMonthAgo = moment().subtract(1, 'months').endOf('day').format("MM/YY");
                let twoMonthsAgo = moment().subtract(2, 'months').endOf('day').format("MM/YY");
                let threeMonthsAgo = moment().subtract(3, 'months').endOf('day').format("MM/YY");
                let fourMonthsAgo = moment().subtract(4, 'months').endOf('day').format("MM/YY");
                let fiveMonthsAgo = moment().subtract(5, 'months').endOf('day').format("MM/YY");
                let sixMonthsAgo = moment().subtract(6, 'months').endOf('day').format("MM/YY");
                let sevenMonthsAgo = moment().subtract(7, 'months').endOf('day').format("MM/YY");
                let eightMonthsAgo = moment().subtract(8, 'months').endOf('day').format("MM/YY");
                let nineMonthsAgo = moment().subtract(9, 'months').endOf('day').format("MM/YY");
                let tenMonthsAgo = moment().subtract(10, 'months').endOf('day').format("MM/YY");
                let elevenMonthsAgo = moment().subtract(11, 'months').endOf('day').format("MM/YY");

                let thisMonthArrayUser = [];
                let oneMonthAgoArrayUser = [];
                let twoMonthsAgoArrayUser = [];
                let threeMonthsAgoArrayUser = [];
                let fourMonthsAgoArrayUser = [];
                let fiveMonthsAgoArrayUser = [];
                let sixMonthsAgoArrayUser = [];
                let sevenMonthsAgoArrayUser = [];
                let eightMonthsAgoArrayUser = [];
                let nineMonthsAgoArrayUser = [];
                let tenMonthsAgoArrayUser = [];
                let elevenMonthsAgoArrayUser = [];

                let yearCountArray = [];
                let clientValidIDArray = [];


                //if attorney found
                for (let item of arrCase) {
                    let currentYear = moment(item.createdAt).format("MM/YY").toString();
                    //If the client has an ID and accepts the TC and PP 
                    if (item.attorney) {
                        clientValidIDArray.push(item.id);
                        //weekCountArray  = weekCount(item.createdAt)
                        //weekCountArray.push(weekCount(item.createdAt));
                        if (currentYear === thisMonth) {
                            thisMonthArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === oneMonthAgo) {
                            oneMonthAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === twoMonthsAgo) {
                            twoMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === threeMonthsAgo) {
                            threeMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === fourMonthsAgo) {
                            fourMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === fiveMonthsAgo) {
                            fiveMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === sixMonthsAgo) {
                            sixMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === sevenMonthsAgo) {
                            sevenMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === eightMonthsAgo) {
                            eightMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === nineMonthsAgo) {
                            nineMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === tenMonthsAgo) {
                            tenMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                        if (currentYear === elevenMonthsAgo) {
                            elevenMonthsAgoArrayUser.push(item.id);
                            yearCountArray.push(item.id)
                        }
                    }
                }

                let thisMonthArrayAttorney = [];
                let oneMonthAgoArrayAttorney = [];
                let twoMonthsAgoArrayAttorney = [];
                let threeMonthsAgoArrayAttorney = [];
                let fourMonthsAgoArrayAttorney = [];
                let fiveMonthsAgoArrayAttorney = [];
                let sixMonthsAgoArrayAttorney = [];
                let sevenMonthsAgoArrayAttorney = [];
                let eightMonthsAgoArrayAttorney = [];
                let nineMonthsAgoArrayAttorney = [];
                let tenMonthsAgoArrayAttorney = [];
                let elevenMonthsAgoArrayAttorney = [];
                let YearCountArrayAttorney = [];
                for (let item of arrCase) {
                    let currentYear = moment(item.createdAt).format("MM/YY").toString();
                    //If the client has an ID and accepts the TC and PP 
                    if (!item.attorney) {
                        if (currentYear === thisMonth) {
                            thisMonthArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === oneMonthAgo) {
                            oneMonthAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === twoMonthsAgo) {
                            twoMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === threeMonthsAgo) {
                            threeMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === fourMonthsAgo) {
                            fourMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === fiveMonthsAgo) {
                            fiveMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === sixMonthsAgo) {
                            sixMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === sevenMonthsAgo) {
                            sevenMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === eightMonthsAgo) {
                            eightMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === nineMonthsAgo) {
                            nineMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === tenMonthsAgo) {
                            tenMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                        if (currentYear === elevenMonthsAgo) {
                            elevenMonthsAgoArrayAttorney.push(item.id);
                            YearCountArrayAttorney.push(item.id)
                        }
                    }
                }
                const clientCountBarData = [
                    {
                        name: 'Not found', count: YearCountArrayAttorney.length,
                    },
                    {
                        name: 'Found', count: yearCountArray.length,
                    },
                ];
                setClientBarData(clientCountBarData);
            }
        )();
    }, []);

    return (
        <React.Fragment>
            <Title>Attorneys Found / Not Found This Year</Title>
            <ResponsiveContainer >
                <BarChart
                    data={clientCountBarData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >


                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="count" fill="#8884d8" >
                        <LabelList dataKey="count" position="middle" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
