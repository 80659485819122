import React, { useEffect, useState, PureComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,Label,
} from 'recharts';

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
}));

const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

export default function AttorneyDashboard() {
    const [client, setClient] = useState(null);
    const [stateBarData, setStateBarData] = useState(null);



    useEffect(() => {
        (
            async () => {


                const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
                const client = responseListClients.data.listClients.items;
                setClient(client);
                let arrClient = client;


                //let clientValidIDArray = [];
                let clientStatesArray = [];
                let clientInvalidArray = [];

                for (let item of arrClient) {
                    //If the client has an ID and accepts the TC and PP 
                    if (item.id && item.acceptsTCAndPP === true) {
                        //clientValidIDArray.push(item.id);
                        clientStatesArray.push(item.state)
                    }
                    //checking if anyone is able to enter the app without acceping TC and PP
                    if (item.acceptsTCAndPP === null) {
                        clientInvalidArray.push(item.id)
                    }
                }

                //getting user state count
                const clientState = arrayToObjectCount(clientStatesArray);


                //Creating Number of clients by state object arrays
                let stateBarData = [];
                if (clientState) {
                    Object.keys(clientState).map((item) => {
                        return (
                            stateBarData.push({ State: item, Count: clientState[item] })
                        )
                    })
                }
                setStateBarData(stateBarData);


            }
        )();
    }, []);
    return (
        client ?
            <>
                <React.Fragment>
                    <Title>Number of Users by State</Title>
                    <ResponsiveContainer >
                        <BarChart
                            data={stateBarData}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5,
                            }}
                        >
                            <Label value="Test" offset={0} position="top" />
                            <CartesianGrid strokeDasharray="5 5" />
                            <XAxis dataKey="State" >
                            </XAxis>
                            <YAxis label={{ value: 'Count', angle: -90, position: 'insideLeft', textAnchor: 'middle' }} />
                            <Tooltip />
                            <Legend ></Legend>
                            <Bar dataKey="Count" fill="#8884d8">
                                <LabelList dataKey="Count" position="middle" />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </React.Fragment>
            </> :
            "Loading number of users by state graph"
    );
}
