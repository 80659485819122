import { TableContainer } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Divider, Header, Icon, Image, Statistic } from 'semantic-ui-react';
import * as queries from '../graphql/customQueries';
import { renderCellExpand } from "./renderCellExpand";
import Title from './Title';


const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      lineHeight: "15px",
      whiteSpace: "normal"
    }
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const { SearchBar } = Search;

const MyExportCSV = (props) => {
  const handleClick = () => {
    props.onExport();
  };
  return (
    <div>
      <button className="btn btn-success" onClick={handleClick}>Export to CSV</button>
    </div>
  );
};

export default function AttorneyDashboard() {
  const classes = useStyles();

  const [practice, setPractice] = useState(null);
  const [client, setClient] = useState(null);
  const [attorneyImage, setAttorneyImage] = useState(null);
  const [newObj, setNewObj] = useState(null);
  const [attorneyTableData, setAttorneyTableData] = useState(null);
  const [columnsTemp, setColumnsTemp] = useState(null);
  const [columns2, setColums2] = useState(null);
  const [itemsExample, setItemsExample] = useState(null);
  //const [ModalContent2, setModalContent2] = useState(null);
  const [presignedUrl, setpresignedUrl] = useState(function (item) { });
  const [modalInfo, setModalInfo] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [rowEvents, setRowEvents] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [search, setSearch] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (
      async () => {
        //here it grabs listPracticeAreas
        const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
        console.log(`responseListAttorneys`, responseListAttorneys)

        //set practice to listAttorneys
        const practice = responseListAttorneys.data.listAttorneys.items;
        setPractice(practice);

        let arrPractice = practice;
        let attorneyTableData = [];


        for (let item of arrPractice) {

          let overallScoreAverage = 0;
          let knowledgeScoreAverage = 0;
          let friendlinessScoreAverage = 0;
          let sessionsCount = item.cases.items.length;
          let attorneyTotalEarned = 0;
          let attorneyEarnedThisMonth = 0;
          let attorney15MinuteSessions = 0;
          let attorney30MinuteSessions = 0;
          let attorneyFreeSessions = 0;
          let attorney1HourSessions = 0;
          let currentMonth = moment().format("MM/YYYY").toString();
          let tempStorage = item.firstName;
          let notification = "None";

          if (item.notificationMethods) {
            notification = item.notificationMethods
          }

          if (item.ratings.items.length > 0) {
            let overallScoreTotal = 0;
            let knowledgeScoreTotal = 0;
            let friendlinessScoreTotal = 0;
            for (let test of item.ratings.items) {
              overallScoreTotal = test.overallScore + overallScoreTotal;
              knowledgeScoreTotal = test.overallScore + knowledgeScoreTotal;
              friendlinessScoreTotal = test.overallScore + friendlinessScoreTotal;
              //console.log(item.id, test.overallScore)
            }
            overallScoreAverage = overallScoreTotal / item.ratings.items.length;
            knowledgeScoreAverage = knowledgeScoreTotal / item.ratings.items.length;
            friendlinessScoreAverage = friendlinessScoreTotal / item.ratings.items.length;
          }
          if (item.cases.items) {
            for (let test of item.cases.items) {
              attorneyTotalEarned = test.payment.calculatedAttorneyAmount + attorneyTotalEarned;
              let caseMonth = moment(test.payment.createdAt).format("MM/YYYY").toString();
              if (caseMonth === currentMonth) {
                attorneyEarnedThisMonth = test.payment.calculatedAttorneyAmount + attorneyEarnedThisMonth;
              }

              if (test.payment.amount === 39) {
                attorney15MinuteSessions = 1 + attorney15MinuteSessions;
              }

              if (test.payment.amount === 59) {
                attorney30MinuteSessions = 1 + attorney30MinuteSessions;
              }

              if (test.payment.amount === 0) {
                attorneyFreeSessions = 1 + attorneyFreeSessions;
              }

              if (test.payment.amount === 109) {
                attorney1HourSessions = 1 + attorney1HourSessions;
              }

            }
          }

          if (item.avatarUrl) {
            tempStorage = await Storage.get(item.avatarUrl, { level: "public" });
          }

          if (item) {
            let formatPracticeAreas = item.practiceAreas.join(', ');
            attorneyTableData.push({
              id: item.id,
              AttorneyFirstName: item.firstName,
              AttorneyLastName: item.lastName,
              AttorneyWorkEmail: item.emailWork,
              AttorneyAvatar: tempStorage,
              AttorneyNotification: notification,
              AttorneyPracticeAreas: formatPracticeAreas,
              AttorneyStatesOfLicense: item.statesOfLicense,
              AttorneyFirmAssociation: item.firmAssociation,
              AttorneyLiabilityInsuranceProvider: item.liabilityInsuranceProvider,
              AttorneyApproved: item.approved,
              AttorneyLicenseNumber: item.licenseNumber,
              AttorneyStripeId: item.stripeAccountId,
              AttorneyJoinDate: item.createdAt,
              AttorneyOverallScoreAverage: overallScoreAverage,
              AttorneyKnowledgeScoreAverage: knowledgeScoreAverage,
              AttorneyFriendlinessScoreAverage: friendlinessScoreAverage,
              SessionsCount: sessionsCount,
              AttorneyTotalEarned: attorneyTotalEarned.toFixed(2),
              AttorneyEarnedThisMonth: attorneyEarnedThisMonth.toFixed(2),
              Attorney15MinuteSessions: attorney15MinuteSessions,
              Attorney30EarnedThisMonth: attorney30MinuteSessions,
              AttorneyFreeSessions: attorneyFreeSessions,
              Attorney1HourSessions: attorney1HourSessions,
            });
          }
        }
        let singleAttorneyExample = attorneyTableData[6];

        setAttorneyTableData(attorneyTableData);
        const itemsExample = [
          { key: 'AttorneyFirstName', label: 'FirstName', value: singleAttorneyExample.AttorneyPracticeAreas },
        ]

        setItemsExample(itemsExample);

        let attorneyImage = singleAttorneyExample.AttorneyAvatar

        setAttorneyImage(attorneyImage)

        const columns2 = [
          {
            field: 'id', headerName: 'ID', hide: false,
          },
          {
            field: 'AttorneyAvatar', headerName: 'Avatar', width: 100,
            renderCell: (params) => (
              <Avatar alt={params.value} src={params.value} className={classes.large} />
            ),
          },
          { field: 'AttorneyFirstName', headerName: 'First Name', width: 130, renderCell: renderCellExpand },
          { field: 'AttorneyLastName', headerName: 'Last Name', width: 130, renderCell: renderCellExpand },
          { field: 'AttorneyWorkEmail', headerName: 'Work Email', width: 300, renderCell: renderCellExpand },
          { field: 'AttorneyNotification', headerName: 'Notification Methods', width: 150, renderCell: renderCellExpand },
          { field: 'AttorneyPracticeAreas', headerName: 'Practice Areas', width: 350, renderCell: renderCellExpand },
          { field: 'AttorneyStatesOfLicense', headerName: 'State License', width: 130, renderCell: renderCellExpand },
          { field: 'AttorneyLicenseNumber', headerName: 'License Number', width: 130, headerClassName: "wrapHeader" },
          { field: 'AttorneyFirmAssociation', headerName: 'Firm Association', width: 250, renderCell: renderCellExpand },
          { field: 'AttorneyOverallScoreAverage', headerName: 'Average Overall', width: 120, headerClassName: "wrapHeader" },
          { field: 'AttorneyKnowledgeScoreAverage', headerName: 'Average knowledge', width: 120, headerClassName: "wrapHeader" },
          { field: 'AttorneyFriendlinessScoreAverage', headerName: 'Average Friendliness', width: 120, headerClassName: "wrapHeader" },
          { field: 'AttorneyTotalEarned', headerName: 'total Earned', width: 100, headerClassName: "wrapHeader" },
          { field: 'AttorneyEarnedThisMonth', headerName: 'Earned this month', width: 100, headerClassName: "wrapHeader" },
          { field: 'SessionsCount', headerName: 'Total Sessions Count', width: 100, headerClassName: "wrapHeader" },
          { field: 'Attorney15MinuteSessions', headerName: '15 minute Count', width: 100, headerClassName: "wrapHeader" },
          { field: 'Attorney30EarnedThisMonth', headerName: '30 minute Count', width: 100, headerClassName: "wrapHeader" },
          { field: 'AttorneyFreeSessions', headerName: 'Free session(s)', width: 100, headerClassName: "wrapHeader" },
          { field: 'Attorney1HourSessions', headerName: '1 Hour session(s)', width: 100, headerClassName: "wrapHeader" },
          {
            field: 'AttorneyJoinDate', headerName: 'Join Date', width: 250, renderCell: (params) => (
              moment(params.value).format('MMMM Do YYYY, h:mm a')
            ),
          },
          { field: 'AttorneyLiabilityInsuranceProvider', headerName: 'Liability Insurance', width: 220, renderCell: renderCellExpand },
          { field: 'AttorneyApproved', headerName: 'Approved', width: 125 },
          { field: 'AttorneyStripeId', headerName: 'Stripe ID', width: 250 },
        ];
        setColums2(columns2);

        const columnsTemp = [
          { dataField: "id", text: "Attorney ID" },
          { dataField: "AttorneyApproved", text: "Attorney Approved", hidden: true },
          { dataField: "AttorneyFirstName", text: "First Name" },
          { dataField: "AttorneyLastName", text: "Last Name" },
          { dataField: "AttorneyWorkEmail", text: "Email" },
          { dataField: "AttorneyPracticeAreas", text: "Practice Areas", hidden: true },
          { dataField: "AttorneyStatesOfLicense", text: "State License", hidden: true },
          { dataField: "AttorneyFirmAssociation", text: "License Number", hidden: true },
          { dataField: "AttorneyOverallScoreAverage", text: "Overall Score Average", hidden: true },
          { dataField: "AttorneyKnowledgeScoreAverage", text: "Knowledge Score Average", hidden: true },
          { dataField: "AttorneyFriendlinessScoreAverage", text: "Friendliness Score Average", hidden: true },
          { dataField: "AttorneyTotalEarned", text: "Total Earned", hidden: true },
          { dataField: "AttorneyEarnedThisMonth", text: "Earned this Month", hidden: true },
          { dataField: "SessionsCount", text: "Sessions Count", hidden: true },
          { dataField: "Attorney15MinuteSessions", text: "15 Minute Sessions", hidden: true },
          { dataField: "Attorney30EarnedThisMonth", text: "30 Minute Sessions", hidden: true },
          { dataField: "AttorneyJoinDate", text: "Join Date", sort: true },
          { dataField: "AttorneyLiabilityInsuranceProvider", text: "Liability Insureance Provider", hidden: true },
          { dataField: "AttorneyStripeId", text: "StripeID", hidden: true },

        ]

        setColumnsTemp(columnsTemp);

        const rowEvents = {
          onClick: (e, row) => {
            setModalInfo(row)
            toggleTrueFalse()
          },
        };

        setRowEvents(rowEvents);

        const toggleTrueFalse = () => {
          setShowModal(handleShow);
        };
      }
    )();
  }, []);

  return (
    practice ?
      <>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />

          <Title>Attorney Details</Title>

          

        </main>
      </> :
      "Fetching Attorney Details"
  );
}