import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend } from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


// const weekCount = dateTime => {

//   let today = moment().format("MM/DD");
//   let yesterday = moment().subtract(1, 'days').endOf('day').format("MM/DD");
//   let twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD");
//   let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD");
//   let fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD");
//   let fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD");
//   let sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD");
//   let SevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD");
//   //let createdAtTime = moment(item.createdAt).format("MM/DD").toString();

//   const obj = [{}];

//   for (let item of dateTime) {
//     let createdAtTime = moment(dateTime).format("MM/DD").toString();

//     if (createdAtTime === yesterday) {
//         obj.push({YesterdayCount: createdAtTime})
//     }
//   }


//   return obj;


// }

export default function Chart() {
  const theme = useTheme();
  //const classes = useStyles();
  const [practice, setPractice] = useState(null);
  const [client, setClient] = useState(null);
  const [clientCountBarData, setClientBarData] = useState(null);



  useEffect(() => {
    (
      async () => {
        //here it grabs listPracticeAreas
        const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
        //set practice to listAttorneys
        const practice = responseListAttorneys.data.listAttorneys.items;
        setPractice(practice);
        let arrPractice = practice;


        const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
        const client = responseListClients.data.listClients.items;
        setClient(client);
        let arrClient = client;



        // const test = new Date('2021-02-01T16:36:00.704Z');
        // const test2 = moment("2021-02-01T16:36:00.704Z");
        // const testYesterday = test2;

        // console.log(testYesterday.subtract(1, 'days').endOf('day').format("MM/DD").toString());
        // console.log(practice)
        let today = moment().format("MM/DD");
        let oneDayAgo = moment().subtract(1, 'days').endOf('day').format("MM/DD");
        let twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD");
        let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD");
        let fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD");
        let fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD");
        let sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD");
        let sevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD");
        let yesterday = moment().subtract(1, 'days').endOf('day').format("MM/DD");
        let todayArray = [];
        let oneDayAgoArray = [];
        let twoDaysAgoArray = [];
        let threeDaysAgoArray = [];
        let fourDaysAgoArray = [];
        let fiveDaysAgoArray = [];
        let sixDaysAgoArray = [];
        let sevenDaysAgoArray = [];
        let weekCountArray = [];

        let clientValidIDArray = [];
        let clientValidIDYesterdayArray = [];
        let clientInvalidArray = [];
        //console.log("test", today);


        for (let item of arrClient) {
          let currentDay = moment(item.createdAt).format("MM/DD").toString();
          //If the client has an ID and accepts the TC and PP 
          if (item.id && item.acceptsTCAndPP === true) {
            clientValidIDArray.push(item.id);
            //weekCountArray  = weekCount(item.createdAt)
            //weekCountArray.push(weekCount(item.createdAt));
            if (currentDay === today) {
              todayArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === oneDayAgo) {
              oneDayAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === twoDaysAgo) {
              twoDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === threeDaysAgo) {
              threeDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === fourDaysAgo) {
              fourDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === fiveDaysAgo) {
              fiveDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === sixDaysAgo) {
              sixDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === sevenDaysAgo) {
              sevenDaysAgoArray.push(item.id);
              weekCountArray.push(item.id)
            }


          }
          //checking if anyone is able to enter the app without acceping TC and PP
          if (item.acceptsTCAndPP === null) {
            clientInvalidArray.push(item.id)
          }
        }

        // let i; 
        // for(i = 0; i < 8; i++){
        //   clientCountBarData.push({MonthDay: oneDayAgo, Count: oneDayAgoArray.length})
        // }

        let todayArrayAttorney = [];
        let oneDayAgoArrayAttorney = [];
        let twoDaysAgoArrayAttorney = [];
        let threeDaysAgoArrayAttorney = [];
        let fourDaysAgoArrayAttorney = [];
        let fiveDaysAgoArrayAttorney = [];
        let sixDaysAgoArrayAttorney = [];
        let sevenDaysAgoArrayAttorney = [];
        let weekCountArrayAttorney = [];


        for (let item of arrPractice) {
          let currentDay = moment(item.createdAt).format("MM/DD").toString();
          //If the client has an ID and accepts the TC and PP 
          if (item.id) {
            //weekCountArray  = weekCount(item.createdAt)
            //weekCountArray.push(weekCount(item.createdAt));
            if (currentDay === today) {
              todayArrayAttorney.push(item.id);
              weekCountArray.push(item.id)
            }
            if (currentDay === oneDayAgo) {
              oneDayAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === twoDaysAgo) {
              twoDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === threeDaysAgo) {
              threeDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === fourDaysAgo) {
              fourDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === fiveDaysAgo) {
              fiveDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === sixDaysAgo) {
              sixDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
            if (currentDay === sevenDaysAgo) {
              sevenDaysAgoArrayAttorney.push(item.id);
              weekCountArrayAttorney.push(item.id)
            }
          }
        }

        //console.log(weekCountArray);

        const clientCountBarData = [];
        clientCountBarData.push({MonthDay: today, ClientCount: todayArray.length, AttorneyCount: todayArrayAttorney.length})
        clientCountBarData.push({MonthDay: oneDayAgo, ClientCount: oneDayAgoArray.length, AttorneyCount: oneDayAgoArrayAttorney.length})
        clientCountBarData.push({MonthDay: twoDaysAgo, ClientCount: twoDaysAgoArray.length, AttorneyCount: twoDaysAgoArrayAttorney.length})
        clientCountBarData.push({MonthDay: threeDaysAgo, ClientCount: threeDaysAgoArray.length, AttorneyCount: threeDaysAgoArrayAttorney.length})
        clientCountBarData.push({MonthDay: fourDaysAgo, ClientCount: fourDaysAgoArray.length, AttorneyCount: fourDaysAgoArrayAttorney.length})
        clientCountBarData.push({MonthDay: fiveDaysAgo, ClientCount: fiveDaysAgoArray.length, AttorneyCount: fiveDaysAgoArrayAttorney.length})
        clientCountBarData.push({MonthDay: sixDaysAgo, ClientCount: sixDaysAgoArray.length, AttorneyCount: sixDaysAgoArrayAttorney.length})
        clientCountBarData.push({MonthDay: sevenDaysAgo, ClientCount: sevenDaysAgoArray.length, AttorneyCount: sevenDaysAgoArrayAttorney.length})
        //console.log("look here",clientCountBarData); 
        setClientBarData(clientCountBarData.reverse());


        // console.log("This many users signed up yesterday: ", oneDayAgoArray.length);
        // console.log(oneDayAgoArray);
        // console.log(weekCountArray);


      }
    )();
  }, []);

  return (
    <React.Fragment>
      <Title>Number of Users and Attorneys That Have Joined This Week</Title>
      <ResponsiveContainer >
        <LineChart
          data={clientCountBarData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="MonthDay" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary} allowDecimals={false} >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Count of users
            </Label>
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
            <Legend/>
          <Line name="User Count" type="monotone" dataKey="ClientCount" stroke="#8884d8" />
          <Line name="Attorney Count" type="monotone" dataKey="AttorneyCount" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
