/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PaymentInput = {
  amount?: number | null,
  total?: number | null,
  name?: string | null,
  email?: string | null,
};

export type CreateClientInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state: string,
  zip?: string | null,
  email: string,
  phone: string,
  profilePictureUrl: string,
  promoCredits?: number | null,
  birthday?: string | null,
  gender?: string | null,
  permanentStripePaymentToken?: string | null,
  cognitoId: string,
  quickBloxId?: string | null,
};

export type ModelClientConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  promoCredits?: ModelIntInput | null,
  birthday?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  permanentStripePaymentToken?: ModelStringInput | null,
  cognitoId?: ModelStringInput | null,
  quickBloxId?: ModelStringInput | null,
  and?: Array< ModelClientConditionInput | null > | null,
  or?: Array< ModelClientConditionInput | null > | null,
  not?: ModelClientConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ConnectionMethods {
  Text = "Text",
  Voice = "Voice",
  Video = "Video",
}


export enum NotificationMethod {
  EMAIL = "EMAIL",
  SMS = "SMS",
}


export enum LawArea {
  Family = "Family",
  Criminal = "Criminal",
  Business = "Business",
  Labor = "Labor",
  RealEstate = "RealEstate",
  PersonalInjury = "PersonalInjury",
  IntellectualProperty = "IntellectualProperty",
}


export enum PaymentMethod {
  CreditCard = "CreditCard",
  PromoCredits = "PromoCredits",
}


export type UpdateClientInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  email?: string | null,
  phone?: string | null,
  profilePictureUrl?: string | null,
  promoCredits?: number | null,
  birthday?: string | null,
  gender?: string | null,
  permanentStripePaymentToken?: string | null,
  cognitoId?: string | null,
  quickBloxId?: string | null,
};

export type DeleteClientInput = {
  id?: string | null,
};

export type CreateClientAgreementInput = {
  id?: string | null,
  privacyPolicyText: string,
  termsAndConditionsText: string,
};

export type ModelClientAgreementConditionInput = {
  privacyPolicyText?: ModelStringInput | null,
  termsAndConditionsText?: ModelStringInput | null,
  and?: Array< ModelClientAgreementConditionInput | null > | null,
  or?: Array< ModelClientAgreementConditionInput | null > | null,
  not?: ModelClientAgreementConditionInput | null,
};

export type UpdateClientAgreementInput = {
  id: string,
  privacyPolicyText?: string | null,
  termsAndConditionsText?: string | null,
};

export type DeleteClientAgreementInput = {
  id?: string | null,
};

export type CreateAttorneyAgreementInput = {
  id?: string | null,
  privacyPolicyText: string,
  termsAndConditionsText: string,
};

export type ModelAttorneyAgreementConditionInput = {
  privacyPolicyText?: ModelStringInput | null,
  termsAndConditionsText?: ModelStringInput | null,
  and?: Array< ModelAttorneyAgreementConditionInput | null > | null,
  or?: Array< ModelAttorneyAgreementConditionInput | null > | null,
  not?: ModelAttorneyAgreementConditionInput | null,
};

export type UpdateAttorneyAgreementInput = {
  id: string,
  privacyPolicyText?: string | null,
  termsAndConditionsText?: string | null,
};

export type DeleteAttorneyAgreementInput = {
  id?: string | null,
};

export type CreateAttorneyInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  emailWork: string,
  emailPersonal?: string | null,
  cellPhone?: string | null,
  avatarUrl?: string | null,
  firmAssociation: string,
  statesOfLicense: Array< string >,
  licenseNumber: string,
  liabilityInsuranceProvider?: string | null,
  currentProfessionalResponsibilityInvestigations?: string | null,
  practiceAreas: Array< string >,
  communicationMethodsPreference: Array< ConnectionMethods | null >,
  acceptsTCAndPP?: boolean | null,
  attorneyRating?: number | null,
  cognitoId: string,
  quickBloxId?: string | null,
  approved?: boolean | null,
};

export type ModelAttorneyConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  emailWork?: ModelStringInput | null,
  emailPersonal?: ModelStringInput | null,
  cellPhone?: ModelStringInput | null,
  avatarUrl?: ModelStringInput | null,
  firmAssociation?: ModelStringInput | null,
  statesOfLicense?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  liabilityInsuranceProvider?: ModelStringInput | null,
  currentProfessionalResponsibilityInvestigations?: ModelStringInput | null,
  practiceAreas?: ModelStringInput | null,
  communicationMethodsPreference?: ModelConnectionMethodsListInput | null,
  acceptsTCAndPP?: ModelBooleanInput | null,
  attorneyRating?: ModelFloatInput | null,
  cognitoId?: ModelStringInput | null,
  quickBloxId?: ModelStringInput | null,
  approved?: ModelBooleanInput | null,
  and?: Array< ModelAttorneyConditionInput | null > | null,
  or?: Array< ModelAttorneyConditionInput | null > | null,
  not?: ModelAttorneyConditionInput | null,
};

export type ModelConnectionMethodsListInput = {
  eq?: Array< ConnectionMethods | null > | null,
  ne?: Array< ConnectionMethods | null > | null,
  contains?: ConnectionMethods | null,
  notContains?: ConnectionMethods | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export enum ConsultationStatus {
  Pending = "Pending",
  Scheduled = "Scheduled",
  Ready = "Ready",
  InProgress = "InProgress",
  Completed = "Completed",
}


export enum RatingType {
  Overall = "Overall",
}


export type UpdateAttorneyInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  emailWork?: string | null,
  emailPersonal?: string | null,
  cellPhone?: string | null,
  avatarUrl?: string | null,
  firmAssociation?: string | null,
  statesOfLicense?: Array< string > | null,
  licenseNumber?: string | null,
  liabilityInsuranceProvider?: string | null,
  currentProfessionalResponsibilityInvestigations?: string | null,
  practiceAreas?: Array< string > | null,
  communicationMethodsPreference?: Array< ConnectionMethods | null > | null,
  acceptsTCAndPP?: boolean | null,
  attorneyRating?: number | null,
  cognitoId?: string | null,
  quickBloxId?: string | null,
  approved?: boolean | null,
};

export type DeleteAttorneyInput = {
  id?: string | null,
};

export type CreateRatingInput = {
  id?: string | null,
  ratingScore: number,
  ratingType: RatingType,
  ratingAttorneyId: string,
};

export type ModelRatingConditionInput = {
  ratingScore?: ModelFloatInput | null,
  ratingType?: ModelRatingTypeInput | null,
  and?: Array< ModelRatingConditionInput | null > | null,
  or?: Array< ModelRatingConditionInput | null > | null,
  not?: ModelRatingConditionInput | null,
};

export type ModelRatingTypeInput = {
  eq?: RatingType | null,
  ne?: RatingType | null,
};

export type UpdateRatingInput = {
  id: string,
  ratingScore?: number | null,
  ratingType?: RatingType | null,
  ratingAttorneyId?: string | null,
};

export type DeleteRatingInput = {
  id?: string | null,
};

export type CreateEarningInput = {
  id?: string | null,
  earningAttorneyId: string,
};

export type ModelEarningConditionInput = {
  and?: Array< ModelEarningConditionInput | null > | null,
  or?: Array< ModelEarningConditionInput | null > | null,
  not?: ModelEarningConditionInput | null,
};

export enum PromotionalCodeStatus {
  Active = "Active",
  Expired = "Expired",
  Used = "Used",
}


export type UpdateEarningInput = {
  id: string,
  earningAttorneyId?: string | null,
};

export type DeleteEarningInput = {
  id?: string | null,
};

export type CreateCaseInput = {
  id?: string | null,
  venueState: string,
  legalAdviceDescription: string,
  areaOfLaw: LawArea,
  caseClientId: string,
  caseAttorneyId?: string | null,
  casePaymentId?: string | null,
};

export type ModelCaseConditionInput = {
  venueState?: ModelStringInput | null,
  legalAdviceDescription?: ModelStringInput | null,
  areaOfLaw?: ModelLawAreaInput | null,
  and?: Array< ModelCaseConditionInput | null > | null,
  or?: Array< ModelCaseConditionInput | null > | null,
  not?: ModelCaseConditionInput | null,
};

export type ModelLawAreaInput = {
  eq?: LawArea | null,
  ne?: LawArea | null,
};

export type UpdateCaseInput = {
  id: string,
  venueState?: string | null,
  legalAdviceDescription?: string | null,
  areaOfLaw?: LawArea | null,
  caseClientId?: string | null,
  caseAttorneyId?: string | null,
  casePaymentId?: string | null,
};

export type DeleteCaseInput = {
  id?: string | null,
};

export type CreatePaymentInput = {
  id?: string | null,
  paymentMethod: PaymentMethod,
  dateTime: string,
  amount: number,
  stripeToken?: string | null,
  paymentPromotionalCodeId?: string | null,
};

export type ModelPaymentConditionInput = {
  paymentMethod?: ModelPaymentMethodInput | null,
  dateTime?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  stripeToken?: ModelStringInput | null,
  and?: Array< ModelPaymentConditionInput | null > | null,
  or?: Array< ModelPaymentConditionInput | null > | null,
  not?: ModelPaymentConditionInput | null,
};

export type ModelPaymentMethodInput = {
  eq?: PaymentMethod | null,
  ne?: PaymentMethod | null,
};

export type UpdatePaymentInput = {
  id: string,
  paymentMethod?: PaymentMethod | null,
  dateTime?: string | null,
  amount?: number | null,
  stripeToken?: string | null,
  paymentPromotionalCodeId?: string | null,
};

export type DeletePaymentInput = {
  id?: string | null,
};

export type CreatePromotionalCodeInput = {
  id?: string | null,
  numberOfCredits: number,
  status: PromotionalCodeStatus,
  code: string,
};

export type ModelPromotionalCodeConditionInput = {
  numberOfCredits?: ModelIntInput | null,
  status?: ModelPromotionalCodeStatusInput | null,
  code?: ModelStringInput | null,
  and?: Array< ModelPromotionalCodeConditionInput | null > | null,
  or?: Array< ModelPromotionalCodeConditionInput | null > | null,
  not?: ModelPromotionalCodeConditionInput | null,
};

export type ModelPromotionalCodeStatusInput = {
  eq?: PromotionalCodeStatus | null,
  ne?: PromotionalCodeStatus | null,
};

export type UpdatePromotionalCodeInput = {
  id: string,
  numberOfCredits?: number | null,
  status?: PromotionalCodeStatus | null,
  code?: string | null,
};

export type DeletePromotionalCodeInput = {
  id?: string | null,
};

export type CreateConsultationInput = {
  id?: string | null,
  connectionMethod: ConnectionMethods,
  dateTimeOccurred?: string | null,
  videoConnectionToken?: string | null,
  chatConnectionToken?: string | null,
  conversationTranscript?: string | null,
  status: ConsultationStatus,
  clientPrefferedConnectionMethod?: ConnectionMethods | null,
  purchasedLength?: number | null,
  actualLength?: number | null,
};

export type ModelConsultationConditionInput = {
  connectionMethod?: ModelConnectionMethodsInput | null,
  dateTimeOccurred?: ModelStringInput | null,
  videoConnectionToken?: ModelStringInput | null,
  chatConnectionToken?: ModelStringInput | null,
  conversationTranscript?: ModelStringInput | null,
  status?: ModelConsultationStatusInput | null,
  clientPrefferedConnectionMethod?: ModelConnectionMethodsInput | null,
  purchasedLength?: ModelIntInput | null,
  actualLength?: ModelIntInput | null,
  and?: Array< ModelConsultationConditionInput | null > | null,
  or?: Array< ModelConsultationConditionInput | null > | null,
  not?: ModelConsultationConditionInput | null,
};

export type ModelConnectionMethodsInput = {
  eq?: ConnectionMethods | null,
  ne?: ConnectionMethods | null,
};

export type ModelConsultationStatusInput = {
  eq?: ConsultationStatus | null,
  ne?: ConsultationStatus | null,
};

export type UpdateConsultationInput = {
  id: string,
  connectionMethod?: ConnectionMethods | null,
  dateTimeOccurred?: string | null,
  videoConnectionToken?: string | null,
  chatConnectionToken?: string | null,
  conversationTranscript?: string | null,
  status?: ConsultationStatus | null,
  clientPrefferedConnectionMethod?: ConnectionMethods | null,
  purchasedLength?: number | null,
  actualLength?: number | null,
};

export type DeleteConsultationInput = {
  id?: string | null,
};

export type CreateFutureConsultationInput = {
  id?: string | null,
  dateTimeScheduled: string,
  futureConsultationCaseId: string,
  futureConsultationConsultationId: string,
};

export type ModelFutureConsultationConditionInput = {
  dateTimeScheduled?: ModelStringInput | null,
  and?: Array< ModelFutureConsultationConditionInput | null > | null,
  or?: Array< ModelFutureConsultationConditionInput | null > | null,
  not?: ModelFutureConsultationConditionInput | null,
};

export type UpdateFutureConsultationInput = {
  id: string,
  dateTimeScheduled?: string | null,
  futureConsultationCaseId?: string | null,
  futureConsultationConsultationId?: string | null,
};

export type DeleteFutureConsultationInput = {
  id?: string | null,
};

export type CreateImmediateConsultationInput = {
  id?: string | null,
  immediateConsultationCaseId: string,
  immediateConsultationConsultationId: string,
};

export type ModelImmediateConsultationConditionInput = {
  and?: Array< ModelImmediateConsultationConditionInput | null > | null,
  or?: Array< ModelImmediateConsultationConditionInput | null > | null,
  not?: ModelImmediateConsultationConditionInput | null,
};

export type UpdateImmediateConsultationInput = {
  id: string,
  immediateConsultationCaseId?: string | null,
  immediateConsultationConsultationId?: string | null,
};

export type DeleteImmediateConsultationInput = {
  id?: string | null,
};

export type ModelClientFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  address1?: ModelStringInput | null,
  address2?: ModelStringInput | null,
  city?: ModelStringInput | null,
  state?: ModelStringInput | null,
  zip?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  profilePictureUrl?: ModelStringInput | null,
  promoCredits?: ModelIntInput | null,
  birthday?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  permanentStripePaymentToken?: ModelStringInput | null,
  cognitoId?: ModelStringInput | null,
  quickBloxId?: ModelStringInput | null,
  and?: Array< ModelClientFilterInput | null > | null,
  or?: Array< ModelClientFilterInput | null > | null,
  not?: ModelClientFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelClientAgreementFilterInput = {
  id?: ModelIDInput | null,
  privacyPolicyText?: ModelStringInput | null,
  termsAndConditionsText?: ModelStringInput | null,
  and?: Array< ModelClientAgreementFilterInput | null > | null,
  or?: Array< ModelClientAgreementFilterInput | null > | null,
  not?: ModelClientAgreementFilterInput | null,
};

export type ModelAttorneyAgreementFilterInput = {
  id?: ModelIDInput | null,
  privacyPolicyText?: ModelStringInput | null,
  termsAndConditionsText?: ModelStringInput | null,
  and?: Array< ModelAttorneyAgreementFilterInput | null > | null,
  or?: Array< ModelAttorneyAgreementFilterInput | null > | null,
  not?: ModelAttorneyAgreementFilterInput | null,
};

export type ModelAttorneyFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  emailWork?: ModelStringInput | null,
  emailPersonal?: ModelStringInput | null,
  cellPhone?: ModelStringInput | null,
  avatarUrl?: ModelStringInput | null,
  firmAssociation?: ModelStringInput | null,
  statesOfLicense?: ModelStringInput | null,
  licenseNumber?: ModelStringInput | null,
  liabilityInsuranceProvider?: ModelStringInput | null,
  currentProfessionalResponsibilityInvestigations?: ModelStringInput | null,
  practiceAreas?: ModelStringInput | null,
  communicationMethodsPreference?: ModelConnectionMethodsListInput | null,
  acceptsTCAndPP?: ModelBooleanInput | null,
  attorneyRating?: ModelFloatInput | null,
  cognitoId?: ModelStringInput | null,
  quickBloxId?: ModelStringInput | null,
  approved?: ModelBooleanInput | null,
  and?: Array< ModelAttorneyFilterInput | null > | null,
  or?: Array< ModelAttorneyFilterInput | null > | null,
  not?: ModelAttorneyFilterInput | null,
};

export type ModelRatingFilterInput = {
  id?: ModelIDInput | null,
  ratingScore?: ModelFloatInput | null,
  ratingType?: ModelRatingTypeInput | null,
  and?: Array< ModelRatingFilterInput | null > | null,
  or?: Array< ModelRatingFilterInput | null > | null,
  not?: ModelRatingFilterInput | null,
};

export type ModelEarningFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelEarningFilterInput | null > | null,
  or?: Array< ModelEarningFilterInput | null > | null,
  not?: ModelEarningFilterInput | null,
};

export type ModelCaseFilterInput = {
  id?: ModelIDInput | null,
  venueState?: ModelStringInput | null,
  legalAdviceDescription?: ModelStringInput | null,
  areaOfLaw?: ModelLawAreaInput | null,
  and?: Array< ModelCaseFilterInput | null > | null,
  or?: Array< ModelCaseFilterInput | null > | null,
  not?: ModelCaseFilterInput | null,
};

export type ModelPaymentFilterInput = {
  id?: ModelIDInput | null,
  paymentMethod?: ModelPaymentMethodInput | null,
  dateTime?: ModelStringInput | null,
  amount?: ModelFloatInput | null,
  stripeToken?: ModelStringInput | null,
  and?: Array< ModelPaymentFilterInput | null > | null,
  or?: Array< ModelPaymentFilterInput | null > | null,
  not?: ModelPaymentFilterInput | null,
};

export type ModelPromotionalCodeFilterInput = {
  id?: ModelIDInput | null,
  numberOfCredits?: ModelIntInput | null,
  status?: ModelPromotionalCodeStatusInput | null,
  code?: ModelStringInput | null,
  and?: Array< ModelPromotionalCodeFilterInput | null > | null,
  or?: Array< ModelPromotionalCodeFilterInput | null > | null,
  not?: ModelPromotionalCodeFilterInput | null,
};

export type ModelConsultationFilterInput = {
  id?: ModelIDInput | null,
  connectionMethod?: ModelConnectionMethodsInput | null,
  dateTimeOccurred?: ModelStringInput | null,
  videoConnectionToken?: ModelStringInput | null,
  chatConnectionToken?: ModelStringInput | null,
  conversationTranscript?: ModelStringInput | null,
  status?: ModelConsultationStatusInput | null,
  clientPrefferedConnectionMethod?: ModelConnectionMethodsInput | null,
  purchasedLength?: ModelIntInput | null,
  actualLength?: ModelIntInput | null,
  and?: Array< ModelConsultationFilterInput | null > | null,
  or?: Array< ModelConsultationFilterInput | null > | null,
  not?: ModelConsultationFilterInput | null,
};

export type ModelFutureConsultationFilterInput = {
  id?: ModelIDInput | null,
  dateTimeScheduled?: ModelStringInput | null,
  and?: Array< ModelFutureConsultationFilterInput | null > | null,
  or?: Array< ModelFutureConsultationFilterInput | null > | null,
  not?: ModelFutureConsultationFilterInput | null,
};

export type ModelImmediateConsultationFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelImmediateConsultationFilterInput | null > | null,
  or?: Array< ModelImmediateConsultationFilterInput | null > | null,
  not?: ModelImmediateConsultationFilterInput | null,
};

export type CreateStripePaymentMutationVariables = {
  input?: PaymentInput | null,
};

export type CreateStripePaymentMutation = {
  createStripePayment:  {
    __typename: "PaymentResult",
    statusCode: number | null,
    sessionId: string | null,
    error: string | null,
  } | null,
};

export type CreateClientMutationVariables = {
  input: CreateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type CreateClientMutation = {
  createClient:  {
    __typename: "Client",
    id: string,
    firstName: string,
    lastName: string,
    address1: string | null,
    address2: string | null,
    city: string | null,
    state: string,
    zip: string | null,
    email: string,
    phone: string,
    profilePictureUrl: string,
    promoCredits: number | null,
    birthday: string | null,
    gender: string | null,
    permanentStripePaymentToken: string | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientMutationVariables = {
  input: UpdateClientInput,
  condition?: ModelClientConditionInput | null,
};

export type UpdateClientMutation = {
  updateClient:  {
    __typename: "Client",
    id: string,
    firstName: string,
    lastName: string,
    address1: string | null,
    address2: string | null,
    city: string | null,
    state: string,
    zip: string | null,
    email: string,
    phone: string,
    profilePictureUrl: string,
    promoCredits: number | null,
    birthday: string | null,
    gender: string | null,
    permanentStripePaymentToken: string | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientMutationVariables = {
  input: DeleteClientInput,
  condition?: ModelClientConditionInput | null,
};

export type DeleteClientMutation = {
  deleteClient:  {
    __typename: "Client",
    id: string,
    firstName: string,
    lastName: string,
    address1: string | null,
    address2: string | null,
    city: string | null,
    state: string,
    zip: string | null,
    email: string,
    phone: string,
    profilePictureUrl: string,
    promoCredits: number | null,
    birthday: string | null,
    gender: string | null,
    permanentStripePaymentToken: string | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClientAgreementMutationVariables = {
  input: CreateClientAgreementInput,
  condition?: ModelClientAgreementConditionInput | null,
};

export type CreateClientAgreementMutation = {
  createClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClientAgreementMutationVariables = {
  input: UpdateClientAgreementInput,
  condition?: ModelClientAgreementConditionInput | null,
};

export type UpdateClientAgreementMutation = {
  updateClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClientAgreementMutationVariables = {
  input: DeleteClientAgreementInput,
  condition?: ModelClientAgreementConditionInput | null,
};

export type DeleteClientAgreementMutation = {
  deleteClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAttorneyAgreementMutationVariables = {
  input: CreateAttorneyAgreementInput,
  condition?: ModelAttorneyAgreementConditionInput | null,
};

export type CreateAttorneyAgreementMutation = {
  createAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAttorneyAgreementMutationVariables = {
  input: UpdateAttorneyAgreementInput,
  condition?: ModelAttorneyAgreementConditionInput | null,
};

export type UpdateAttorneyAgreementMutation = {
  updateAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAttorneyAgreementMutationVariables = {
  input: DeleteAttorneyAgreementInput,
  condition?: ModelAttorneyAgreementConditionInput | null,
};

export type DeleteAttorneyAgreementMutation = {
  deleteAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAttorneyMutationVariables = {
  input: CreateAttorneyInput,
  condition?: ModelAttorneyConditionInput | null,
};

export type CreateAttorneyMutation = {
  createAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateAttorneyMutationVariables = {
  input: UpdateAttorneyInput,
  condition?: ModelAttorneyConditionInput | null,
};

export type UpdateAttorneyMutation = {
  updateAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteAttorneyMutationVariables = {
  input: DeleteAttorneyInput,
  condition?: ModelAttorneyConditionInput | null,
};

export type DeleteAttorneyMutation = {
  deleteAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreateRatingMutationVariables = {
  input: CreateRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type CreateRatingMutation = {
  createRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateRatingMutationVariables = {
  input: UpdateRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type UpdateRatingMutation = {
  updateRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteRatingMutationVariables = {
  input: DeleteRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type DeleteRatingMutation = {
  deleteRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateEarningMutationVariables = {
  input: CreateEarningInput,
  condition?: ModelEarningConditionInput | null,
};

export type CreateEarningMutation = {
  createEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdateEarningMutationVariables = {
  input: UpdateEarningInput,
  condition?: ModelEarningConditionInput | null,
};

export type UpdateEarningMutation = {
  updateEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeleteEarningMutationVariables = {
  input: DeleteEarningInput,
  condition?: ModelEarningConditionInput | null,
};

export type DeleteEarningMutation = {
  deleteEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreateCaseMutationVariables = {
  input: CreateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type CreateCaseMutation = {
  createCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCaseMutationVariables = {
  input: UpdateCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type UpdateCaseMutation = {
  updateCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCaseMutationVariables = {
  input: DeleteCaseInput,
  condition?: ModelCaseConditionInput | null,
};

export type DeleteCaseMutation = {
  deleteCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePaymentMutationVariables = {
  input: CreatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type CreatePaymentMutation = {
  createPayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type UpdatePaymentMutationVariables = {
  input: UpdatePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type UpdatePaymentMutation = {
  updatePayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type DeletePaymentMutationVariables = {
  input: DeletePaymentInput,
  condition?: ModelPaymentConditionInput | null,
};

export type DeletePaymentMutation = {
  deletePayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type CreatePromotionalCodeMutationVariables = {
  input: CreatePromotionalCodeInput,
  condition?: ModelPromotionalCodeConditionInput | null,
};

export type CreatePromotionalCodeMutation = {
  createPromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePromotionalCodeMutationVariables = {
  input: UpdatePromotionalCodeInput,
  condition?: ModelPromotionalCodeConditionInput | null,
};

export type UpdatePromotionalCodeMutation = {
  updatePromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePromotionalCodeMutationVariables = {
  input: DeletePromotionalCodeInput,
  condition?: ModelPromotionalCodeConditionInput | null,
};

export type DeletePromotionalCodeMutation = {
  deletePromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateConsultationMutationVariables = {
  input: CreateConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type CreateConsultationMutation = {
  createConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateConsultationMutationVariables = {
  input: UpdateConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type UpdateConsultationMutation = {
  updateConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteConsultationMutationVariables = {
  input: DeleteConsultationInput,
  condition?: ModelConsultationConditionInput | null,
};

export type DeleteConsultationMutation = {
  deleteConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateFutureConsultationMutationVariables = {
  input: CreateFutureConsultationInput,
  condition?: ModelFutureConsultationConditionInput | null,
};

export type CreateFutureConsultationMutation = {
  createFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateFutureConsultationMutationVariables = {
  input: UpdateFutureConsultationInput,
  condition?: ModelFutureConsultationConditionInput | null,
};

export type UpdateFutureConsultationMutation = {
  updateFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteFutureConsultationMutationVariables = {
  input: DeleteFutureConsultationInput,
  condition?: ModelFutureConsultationConditionInput | null,
};

export type DeleteFutureConsultationMutation = {
  deleteFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateImmediateConsultationMutationVariables = {
  input: CreateImmediateConsultationInput,
  condition?: ModelImmediateConsultationConditionInput | null,
};

export type CreateImmediateConsultationMutation = {
  createImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateImmediateConsultationMutationVariables = {
  input: UpdateImmediateConsultationInput,
  condition?: ModelImmediateConsultationConditionInput | null,
};

export type UpdateImmediateConsultationMutation = {
  updateImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteImmediateConsultationMutationVariables = {
  input: DeleteImmediateConsultationInput,
  condition?: ModelImmediateConsultationConditionInput | null,
};

export type DeleteImmediateConsultationMutation = {
  deleteImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetClientQueryVariables = {
  id: string,
};

export type GetClientQuery = {
  getClient:  {
    __typename: "Client",
    id: string,
    firstName: string,
    lastName: string,
    address1: string | null,
    address2: string | null,
    city: string | null,
    state: string,
    zip: string | null,
    email: string,
    phone: string,
    profilePictureUrl: string,
    promoCredits: number | null,
    birthday: string | null,
    gender: string | null,
    permanentStripePaymentToken: string | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientsQueryVariables = {
  filter?: ModelClientFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientsQuery = {
  listClients:  {
    __typename: "ModelClientConnection",
    items:  Array< {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetClientAgreementQueryVariables = {
  id: string,
};

export type GetClientAgreementQuery = {
  getClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClientAgreementsQueryVariables = {
  filter?: ModelClientAgreementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListClientAgreementsQuery = {
  listClientAgreements:  {
    __typename: "ModelClientAgreementConnection",
    items:  Array< {
      __typename: "ClientAgreement",
      id: string,
      privacyPolicyText: string,
      termsAndConditionsText: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAttorneyAgreementQueryVariables = {
  id: string,
};

export type GetAttorneyAgreementQuery = {
  getAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAttorneyAgreementsQueryVariables = {
  filter?: ModelAttorneyAgreementFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttorneyAgreementsQuery = {
  listAttorneyAgreements:  {
    __typename: "ModelAttorneyAgreementConnection",
    items:  Array< {
      __typename: "AttorneyAgreement",
      id: string,
      privacyPolicyText: string,
      termsAndConditionsText: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetAttorneyQueryVariables = {
  id: string,
};

export type GetAttorneyQuery = {
  getAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListAttorneysQueryVariables = {
  filter?: ModelAttorneyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAttorneysQuery = {
  listAttorneys:  {
    __typename: "ModelAttorneyConnection",
    items:  Array< {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetRatingQueryVariables = {
  id: string,
};

export type GetRatingQuery = {
  getRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListRatingsQueryVariables = {
  filter?: ModelRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRatingsQuery = {
  listRatings:  {
    __typename: "ModelRatingConnection",
    items:  Array< {
      __typename: "Rating",
      id: string,
      consultation:  {
        __typename: "Consultation",
        id: string,
        connectionMethod: ConnectionMethods,
        dateTimeOccurred: string | null,
        videoConnectionToken: string | null,
        chatConnectionToken: string | null,
        conversationTranscript: string | null,
        status: ConsultationStatus,
        clientPrefferedConnectionMethod: ConnectionMethods | null,
        purchasedLength: number | null,
        actualLength: number | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      },
      ratingScore: number,
      ratingType: RatingType,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetEarningQueryVariables = {
  id: string,
};

export type GetEarningQuery = {
  getEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListEarningsQueryVariables = {
  filter?: ModelEarningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListEarningsQuery = {
  listEarnings:  {
    __typename: "ModelEarningConnection",
    items:  Array< {
      __typename: "Earning",
      id: string,
      consultation:  {
        __typename: "Consultation",
        id: string,
        connectionMethod: ConnectionMethods,
        dateTimeOccurred: string | null,
        videoConnectionToken: string | null,
        chatConnectionToken: string | null,
        conversationTranscript: string | null,
        status: ConsultationStatus,
        clientPrefferedConnectionMethod: ConnectionMethods | null,
        purchasedLength: number | null,
        actualLength: number | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      },
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetCaseQueryVariables = {
  id: string,
};

export type GetCaseQuery = {
  getCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCasesQueryVariables = {
  filter?: ModelCaseFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCasesQuery = {
  listCases:  {
    __typename: "ModelCaseConnection",
    items:  Array< {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetPaymentQueryVariables = {
  id: string,
};

export type GetPaymentQuery = {
  getPayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type ListPaymentsQueryVariables = {
  filter?: ModelPaymentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentsQuery = {
  listPayments:  {
    __typename: "ModelPaymentConnection",
    items:  Array< {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetPromotionalCodeQueryVariables = {
  id: string,
};

export type GetPromotionalCodeQuery = {
  getPromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPromotionalCodesQueryVariables = {
  filter?: ModelPromotionalCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromotionalCodesQuery = {
  listPromotionalCodes:  {
    __typename: "ModelPromotionalCodeConnection",
    items:  Array< {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetConsultationQueryVariables = {
  id: string,
};

export type GetConsultationQuery = {
  getConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListConsultationsQueryVariables = {
  filter?: ModelConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConsultationsQuery = {
  listConsultations:  {
    __typename: "ModelConsultationConnection",
    items:  Array< {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetFutureConsultationQueryVariables = {
  id: string,
};

export type GetFutureConsultationQuery = {
  getFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListFutureConsultationsQueryVariables = {
  filter?: ModelFutureConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFutureConsultationsQuery = {
  listFutureConsultations:  {
    __typename: "ModelFutureConsultationConnection",
    items:  Array< {
      __typename: "FutureConsultation",
      id: string,
      dateTimeScheduled: string,
      case:  {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      consultation:  {
        __typename: "Consultation",
        id: string,
        connectionMethod: ConnectionMethods,
        dateTimeOccurred: string | null,
        videoConnectionToken: string | null,
        chatConnectionToken: string | null,
        conversationTranscript: string | null,
        status: ConsultationStatus,
        clientPrefferedConnectionMethod: ConnectionMethods | null,
        purchasedLength: number | null,
        actualLength: number | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type GetImmediateConsultationQueryVariables = {
  id: string,
};

export type GetImmediateConsultationQuery = {
  getImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListImmediateConsultationsQueryVariables = {
  filter?: ModelImmediateConsultationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListImmediateConsultationsQuery = {
  listImmediateConsultations:  {
    __typename: "ModelImmediateConsultationConnection",
    items:  Array< {
      __typename: "ImmediateConsultation",
      id: string,
      case:  {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      },
      consultation:  {
        __typename: "Consultation",
        id: string,
        connectionMethod: ConnectionMethods,
        dateTimeOccurred: string | null,
        videoConnectionToken: string | null,
        chatConnectionToken: string | null,
        conversationTranscript: string | null,
        status: ConsultationStatus,
        clientPrefferedConnectionMethod: ConnectionMethods | null,
        purchasedLength: number | null,
        actualLength: number | null,
        createdAt: string,
        updatedAt: string,
      },
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken: string | null,
  } | null,
};

export type OnAttorneyAssignedCaseSubscriptionVariables = {
  caseAttorneyId: string,
};

export type OnAttorneyAssignedCaseSubscription = {
  onAttorneyAssignedCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClientAgreementSubscription = {
  onCreateClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClientAgreementSubscription = {
  onUpdateClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClientAgreementSubscription = {
  onDeleteClientAgreement:  {
    __typename: "ClientAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAttorneyAgreementSubscription = {
  onCreateAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAttorneyAgreementSubscription = {
  onUpdateAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAttorneyAgreementSubscription = {
  onDeleteAttorneyAgreement:  {
    __typename: "AttorneyAgreement",
    id: string,
    privacyPolicyText: string,
    termsAndConditionsText: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAttorneySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateAttorneySubscription = {
  onCreateAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateAttorneySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateAttorneySubscription = {
  onUpdateAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteAttorneySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteAttorneySubscription = {
  onDeleteAttorney:  {
    __typename: "Attorney",
    id: string,
    firstName: string,
    lastName: string,
    emailWork: string,
    emailPersonal: string | null,
    cellPhone: string | null,
    avatarUrl: string | null,
    firmAssociation: string,
    statesOfLicense: Array< string >,
    licenseNumber: string,
    liabilityInsuranceProvider: string | null,
    currentProfessionalResponsibilityInvestigations: string | null,
    practiceAreas: Array< string >,
    communicationMethodsPreference: Array< ConnectionMethods | null >,
    acceptsTCAndPP: boolean | null,
    attorneyRating: number | null,
    cases:  {
      __typename: "ModelCaseConnection",
      items:  Array< {
        __typename: "Case",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        venueState: string,
        legalAdviceDescription: string,
        areaOfLaw: LawArea,
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    cognitoId: string,
    quickBloxId: string | null,
    earnings:  {
      __typename: "ModelEarningConnection",
      items:  Array< {
        __typename: "Earning",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        payment:  {
          __typename: "Payment",
          id: string,
          paymentMethod: PaymentMethod,
          dateTime: string,
          amount: number,
          stripeToken: string | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null > | null,
      nextToken: string | null,
    } | null,
    ratings:  {
      __typename: "ModelRatingConnection",
      items:  Array< {
        __typename: "Rating",
        id: string,
        consultation:  {
          __typename: "Consultation",
          id: string,
          connectionMethod: ConnectionMethods,
          dateTimeOccurred: string | null,
          videoConnectionToken: string | null,
          chatConnectionToken: string | null,
          conversationTranscript: string | null,
          status: ConsultationStatus,
          clientPrefferedConnectionMethod: ConnectionMethods | null,
          purchasedLength: number | null,
          actualLength: number | null,
          createdAt: string,
          updatedAt: string,
        },
        attorney:  {
          __typename: "Attorney",
          id: string,
          firstName: string,
          lastName: string,
          emailWork: string,
          emailPersonal: string | null,
          cellPhone: string | null,
          avatarUrl: string | null,
          firmAssociation: string,
          statesOfLicense: Array< string >,
          licenseNumber: string,
          liabilityInsuranceProvider: string | null,
          currentProfessionalResponsibilityInvestigations: string | null,
          practiceAreas: Array< string >,
          communicationMethodsPreference: Array< ConnectionMethods | null >,
          acceptsTCAndPP: boolean | null,
          attorneyRating: number | null,
          cognitoId: string,
          quickBloxId: string | null,
          approved: boolean | null,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        },
        ratingScore: number,
        ratingType: RatingType,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken: string | null,
    } | null,
    approved: boolean | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreateRatingSubscription = {
  onCreateRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateRatingSubscription = {
  onUpdateRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteRatingSubscription = {
  onDeleteRating:  {
    __typename: "Rating",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    ratingScore: number,
    ratingType: RatingType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateEarningSubscriptionVariables = {
  owner: string,
};

export type OnCreateEarningSubscription = {
  onCreateEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdateEarningSubscriptionVariables = {
  owner: string,
};

export type OnUpdateEarningSubscription = {
  onUpdateEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeleteEarningSubscriptionVariables = {
  owner: string,
};

export type OnDeleteEarningSubscription = {
  onDeleteEarning:  {
    __typename: "Earning",
    id: string,
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreateCaseSubscription = {
  onCreateCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCaseSubscription = {
  onUpdateCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCaseSubscription = {
  onDeleteCase:  {
    __typename: "Case",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    attorney:  {
      __typename: "Attorney",
      id: string,
      firstName: string,
      lastName: string,
      emailWork: string,
      emailPersonal: string | null,
      cellPhone: string | null,
      avatarUrl: string | null,
      firmAssociation: string,
      statesOfLicense: Array< string >,
      licenseNumber: string,
      liabilityInsuranceProvider: string | null,
      currentProfessionalResponsibilityInvestigations: string | null,
      practiceAreas: Array< string >,
      communicationMethodsPreference: Array< ConnectionMethods | null >,
      acceptsTCAndPP: boolean | null,
      attorneyRating: number | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      earnings:  {
        __typename: "ModelEarningConnection",
        items:  Array< {
          __typename: "Earning",
          id: string,
          createdAt: string,
          updatedAt: string,
          owner: string | null,
        } | null > | null,
        nextToken: string | null,
      } | null,
      ratings:  {
        __typename: "ModelRatingConnection",
        items:  Array< {
          __typename: "Rating",
          id: string,
          ratingScore: number,
          ratingType: RatingType,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      approved: boolean | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    venueState: string,
    legalAdviceDescription: string,
    areaOfLaw: LawArea,
    payment:  {
      __typename: "Payment",
      id: string,
      paymentMethod: PaymentMethod,
      dateTime: string,
      amount: number,
      promotionalCode:  {
        __typename: "PromotionalCode",
        id: string,
        client:  {
          __typename: "Client",
          id: string,
          firstName: string,
          lastName: string,
          address1: string | null,
          address2: string | null,
          city: string | null,
          state: string,
          zip: string | null,
          email: string,
          phone: string,
          profilePictureUrl: string,
          promoCredits: number | null,
          birthday: string | null,
          gender: string | null,
          permanentStripePaymentToken: string | null,
          cognitoId: string,
          quickBloxId: string | null,
          createdAt: string,
          updatedAt: string,
        },
        numberOfCredits: number,
        status: PromotionalCodeStatus,
        code: string,
        createdAt: string,
        updatedAt: string,
      } | null,
      stripeToken: string | null,
      createdAt: string,
      updatedAt: string,
      owner: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePaymentSubscriptionVariables = {
  owner: string,
};

export type OnCreatePaymentSubscription = {
  onCreatePayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnUpdatePaymentSubscriptionVariables = {
  owner: string,
};

export type OnUpdatePaymentSubscription = {
  onUpdatePayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnDeletePaymentSubscriptionVariables = {
  owner: string,
};

export type OnDeletePaymentSubscription = {
  onDeletePayment:  {
    __typename: "Payment",
    id: string,
    paymentMethod: PaymentMethod,
    dateTime: string,
    amount: number,
    promotionalCode:  {
      __typename: "PromotionalCode",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      numberOfCredits: number,
      status: PromotionalCodeStatus,
      code: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    stripeToken: string | null,
    createdAt: string,
    updatedAt: string,
    owner: string | null,
  } | null,
};

export type OnCreatePromotionalCodeSubscription = {
  onCreatePromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePromotionalCodeSubscription = {
  onUpdatePromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePromotionalCodeSubscription = {
  onDeletePromotionalCode:  {
    __typename: "PromotionalCode",
    id: string,
    client:  {
      __typename: "Client",
      id: string,
      firstName: string,
      lastName: string,
      address1: string | null,
      address2: string | null,
      city: string | null,
      state: string,
      zip: string | null,
      email: string,
      phone: string,
      profilePictureUrl: string,
      promoCredits: number | null,
      birthday: string | null,
      gender: string | null,
      permanentStripePaymentToken: string | null,
      cases:  {
        __typename: "ModelCaseConnection",
        items:  Array< {
          __typename: "Case",
          id: string,
          venueState: string,
          legalAdviceDescription: string,
          areaOfLaw: LawArea,
          createdAt: string,
          updatedAt: string,
        } | null > | null,
        nextToken: string | null,
      } | null,
      cognitoId: string,
      quickBloxId: string | null,
      createdAt: string,
      updatedAt: string,
    },
    numberOfCredits: number,
    status: PromotionalCodeStatus,
    code: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateConsultationSubscription = {
  onCreateConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateConsultationSubscription = {
  onUpdateConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteConsultationSubscription = {
  onDeleteConsultation:  {
    __typename: "Consultation",
    id: string,
    connectionMethod: ConnectionMethods,
    dateTimeOccurred: string | null,
    videoConnectionToken: string | null,
    chatConnectionToken: string | null,
    conversationTranscript: string | null,
    status: ConsultationStatus,
    clientPrefferedConnectionMethod: ConnectionMethods | null,
    purchasedLength: number | null,
    actualLength: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateFutureConsultationSubscription = {
  onCreateFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateFutureConsultationSubscription = {
  onUpdateFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteFutureConsultationSubscription = {
  onDeleteFutureConsultation:  {
    __typename: "FutureConsultation",
    id: string,
    dateTimeScheduled: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateImmediateConsultationSubscription = {
  onCreateImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateImmediateConsultationSubscription = {
  onUpdateImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteImmediateConsultationSubscription = {
  onDeleteImmediateConsultation:  {
    __typename: "ImmediateConsultation",
    id: string,
    case:  {
      __typename: "Case",
      id: string,
      client:  {
        __typename: "Client",
        id: string,
        firstName: string,
        lastName: string,
        address1: string | null,
        address2: string | null,
        city: string | null,
        state: string,
        zip: string | null,
        email: string,
        phone: string,
        profilePictureUrl: string,
        promoCredits: number | null,
        birthday: string | null,
        gender: string | null,
        permanentStripePaymentToken: string | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        createdAt: string,
        updatedAt: string,
      },
      attorney:  {
        __typename: "Attorney",
        id: string,
        firstName: string,
        lastName: string,
        emailWork: string,
        emailPersonal: string | null,
        cellPhone: string | null,
        avatarUrl: string | null,
        firmAssociation: string,
        statesOfLicense: Array< string >,
        licenseNumber: string,
        liabilityInsuranceProvider: string | null,
        currentProfessionalResponsibilityInvestigations: string | null,
        practiceAreas: Array< string >,
        communicationMethodsPreference: Array< ConnectionMethods | null >,
        acceptsTCAndPP: boolean | null,
        attorneyRating: number | null,
        cases:  {
          __typename: "ModelCaseConnection",
          nextToken: string | null,
        } | null,
        cognitoId: string,
        quickBloxId: string | null,
        earnings:  {
          __typename: "ModelEarningConnection",
          nextToken: string | null,
        } | null,
        ratings:  {
          __typename: "ModelRatingConnection",
          nextToken: string | null,
        } | null,
        approved: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      venueState: string,
      legalAdviceDescription: string,
      areaOfLaw: LawArea,
      payment:  {
        __typename: "Payment",
        id: string,
        paymentMethod: PaymentMethod,
        dateTime: string,
        amount: number,
        promotionalCode:  {
          __typename: "PromotionalCode",
          id: string,
          numberOfCredits: number,
          status: PromotionalCodeStatus,
          code: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        stripeToken: string | null,
        createdAt: string,
        updatedAt: string,
        owner: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    },
    consultation:  {
      __typename: "Consultation",
      id: string,
      connectionMethod: ConnectionMethods,
      dateTimeOccurred: string | null,
      videoConnectionToken: string | null,
      chatConnectionToken: string | null,
      conversationTranscript: string | null,
      status: ConsultationStatus,
      clientPrefferedConnectionMethod: ConnectionMethods | null,
      purchasedLength: number | null,
      actualLength: number | null,
      createdAt: string,
      updatedAt: string,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};
