import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


export default function Chart() {
  const [cases, setCases] = useState(null);
  const [clientCountBarData, setClientBarData] = useState(null);

  const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

  useEffect(() => {
    (
      async () => {
        const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
        const cases = responseListCases.data.listCases.items;
        setCases(cases);
        let arrCase = cases;

        let today = moment().format("MM/DD");
        let oneDayAgo = moment().subtract(1, 'days').endOf('day').format("MM/DD");
        let twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD");
        let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD");
        let fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD");
        let fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD");
        let sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD");
        let sevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD");
        let todayArray = [];
        let oneDayAgoArray = [];
        let twoDaysAgoArray = [];
        let threeDaysAgoArray = [];
        let fourDaysAgoArray = [];
        let fiveDaysAgoArray = [];
        let sixDaysAgoArray = [];
        let sevenDaysAgoArray = [];
        let weekCountArray = [];

        //if area of law found
        for (let item of arrCase) {
          let currentDay = moment(item.createdAt).format("MM/DD").toString();
          if (item.areaOfLaw) {
            if (currentDay === today) {
              todayArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === oneDayAgo) {
              oneDayAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === twoDaysAgo) {
              twoDaysAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === threeDaysAgo) {
              threeDaysAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === fourDaysAgo) {
              fourDaysAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === fiveDaysAgo) {
              fiveDaysAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === sixDaysAgo) {
              sixDaysAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
            if (currentDay === sevenDaysAgo) {
              sevenDaysAgoArray.push(item.areaOfLaw);
              weekCountArray.push(item.areaOfLaw)
            }
          }
        }
        const practiceAreasFinalCount = arrayToObjectCount(weekCountArray);

        let clientCountBarData = [];
        if (practiceAreasFinalCount) {
            Object.keys(practiceAreasFinalCount).map((item) => {
                return (
                    clientCountBarData.push({ PracticeArea: item, Count: practiceAreasFinalCount[item] })
                )
            })
        }
        setClientBarData(clientCountBarData);
      }
    )();
  }, []);

  return (
    <React.Fragment>
      <Title>Practice Area Requests this Week</Title>
      <ResponsiveContainer >
        <BarChart
          data={clientCountBarData}
          margin={{
            top: 5, right: 30, left: 20, bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="PracticeArea" />
          <YAxis />
          <Tooltip />
          <Legend />

          <Bar dataKey="Count" fill="#8884d8" >
            <LabelList dataKey="Count" position="middle" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}
