export const listAttorneysForAdmin = /* GraphQL */ `
  query ListAttorneysForAdmin(
    $filter: ModelAttorneyFilterInput
    $limit: Int = 10000
    $nextToken: String
  ) {
    listAttorneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        emailWork
        emailPersonal
        cellPhone
        avatarUrl
        firmAssociation
        statesOfLicense
        liabilityInsuranceProvider
        currentProfessionalResponsibilityInvestigations
        practiceAreas
        acceptsTCAndPP
        attorneyRating
        cognitoId
        approved
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const listPracticeAreas = /* GraphQL */ `
query listPracticeAreas(
  $filter: ModelAttorneyFilterInput
  $limit: Int = 100000
  $nextToken: String
) {
  listAttorneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      isOnline
      lastOnline
      id
      lastName
      firstName
      emailWork
      practiceAreas
      avatarUrl
      attorneyRating
      communicationMethodsPreference
      statesOfLicense
      lastOnline
      createdAt
      approved
      cellPhone
      emailPersonal
      firmAssociation
      liabilityInsuranceProvider
      licenseNumber
      phoneNumberUsageConsent
      stripeAccountId
      updatedAt
      notificationMethods
      ratings {
        items {
          friendlinessScore
          knowledgeScore
          overallScore
        }
      }
      cases {
        items {
          id
          payment {
            calculatedAttorneyAmount
            amount
            createdAt
          }
        }
      }
    }

    nextToken
  }
}
`;


export const listPaymentsTest = /* GraphQL */ `
query ListPaymentsTest(
  $filter: ModelPaymentFilterInput
  $limit: Int = 10000
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      amount
      dateTime
      id
      owner
      calculatedAttorneyAmount
      paymentMethod
    }
    nextToken
  }
}
`;


export const listConsultationsTest = /* GraphQL */ `
  query listConsultationsTest(
    $filter: ModelConsultationFilterInput
    $limit: Int = 10000
    $nextToken: String
  ) {
  listConsultations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      dateTimeOccurred
      createdAt
      actualLength
      status
      updatedAt
      videoConnectionToken
      purchasedLength
      conversationTranscript
      connectionMethod
      clientPrefferedConnectionMethod
      chatConnectionToken
    }
    nextToken
  }
}
`;

export const listCasesTest = /* GraphQL */ `
  query listCasesTest(
    $filter: ModelCaseFilterInput
    $limit: Int = 10000
    $nextToken: String
  ) {
    listCases(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
      consultationConnectionMethod
      areaOfLaw
      venueState
      id
      legalAdviceDescription
      updatedAt
      createdAt
      futureConsultationDateTime
      attorney {
        id
        avatarUrl
        emailWork
        firmAssociation
        firstName
        lastName
        lastOnline
        attorneyRating
      }
      client {
        id
        firstName
        lastName
        email
        profilePictureUrl
        state
      }
      payment {
        amount
        calculatedAttorneyAmount
        paymentMethod
        stripeToken
      }
      rating {
        id
        friendlinessScore
        feedbackText
        knowledgeScore
        overallScore
        updatedAt
        createdAt
      }
    }
    nextToken
  }
}
`;

export const listClientTest = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int = 10000
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
      acceptsTCAndPP
      city
      firstName
      lastName
      gender
      email
      profilePictureUrl
      state
      updatedAt
      createdAt
      phone
      cognitoId
    }
    nextToken
  }
}
`;

export const listClientPromotionalCodesTest = /* GraphQL */ `
  query listClientPromotionalCodes(
    $filter: ModelClientFilterInput
    $limit: Int = 10000
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        isUsed
        id
        createdAt
        updatedAt
    }
    nextToken
  }
}
`;

export const listAttorneyConsultationRequestsTest = /* GraphQL */ `
  query listAttorneyConsultationRequestsTest(
    $limit: Int = 10000
  ) {
    listAttorneyConsultationRequests(limit: $limit) {
      items {
        clientWaitTime
        requestedAttorneys
        status
        updatedAt
        case {
          client {
            email
          }
        }
      }
    }
  }
  `;