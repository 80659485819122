import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: 'flex',
    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      lineHeight: "15px",
      whiteSpace: "normal"
    }
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  paper: {
    position: "absolute",
    width: '50vw',
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),

    "& h2": {
      marginBottom: theme.spacing(4),
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  caseDetails: {
    "& li": {
      marginBottom: theme.spacing(1),
      display: 'flex',
    },
    "& span": {
      fontWeight: "700",
      display: "flex",
      minWidth: 120,
    }
  }
}));

export default useStyles;