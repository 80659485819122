import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React from 'react';
import useStyles from './styles';

const DataTable = (props) => {
  const classes = useStyles();
  const { data, columns } = props;

  return (
    <div className={classes.root}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={15}
        disableColumnMenu
        components={{
          Toolbar: GridToolbar,
        }}
        showCellRightBorder
        {...props}
      />
    </div>
  )
};

export default DataTable;
