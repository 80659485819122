import React, { useEffect, useState, PureComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Title from './Title';
import Grid from '@material-ui/core/Grid';
import * as queries from '../graphql/customQueries';
import { API, container, graphqlOperation, Storage } from 'aws-amplify';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import { PieChart, BarChart, Bar, XAxis, YAxis, CartesianGrid, Pie, Sector, Cell, ResponsiveContainer, Tooltip, Legend, LabelList } from 'recharts';



const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 260,
  },
}));

const COLORS = [' #FF0000', '#008000'];

const COLORS2 = ["#0088FE", "#FFBB28", "#FF8042"];


function preventDefault(event) {
  event.preventDefault();
}

const arrayToObjectCount = array => {
  //declare an empty object ( {} = empty object)
  const obj = {};
  //loop through the given array
  for (let item of array) {
    //is the element in the object, if so add one to its value
    if (obj[item]) {
      obj[item]++;
    } else {
      //if not set its value to 1
      obj[item] = 1;
    }
    //return the object
  }
  return obj;
}

export default function AttorneyDashboard() {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const [practice, setPractice] = useState(null);
  const [attorneyOnline, setAttorneyOnline] = useState(null);
  const [isOnlineCommunicationMethodCount, setIsOnlineCommunicationMethodCount] = useState(null);
  const [isOnlineStateOfLicense, setIsOnlineStateOfLicense] = useState(null);
  const [isOnlinePracticeAreas, setIsOnlinePracticeAreas] = useState(null);
  const [isOnlineAttorneys, setIsOnlineAttorneys] = useState(null);

  useEffect(() => {
    (
      async () => {
        //here it grabs listPracticeAreas
        const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
        //set practice to listAttorneys
        const practice = responseListAttorneys.data.listAttorneys.items;
        setPractice(practice);

        let arrPractice = practice;

        // this deals with all the isOnline code
        let isOnlineArray = [];

        for (let item of arrPractice) {
          if (item.isOnline == true) {
            isOnlineArray.push(["Online"]);
          }
          if (item.isOnline == false) {
            isOnlineArray.push(["Offline"]);
          }
        }
        const isOnlineCount = arrayToObjectCount(isOnlineArray);


        let isOnlineAttorneyPieChart = [];
        if (isOnlineCount) {
          Object.keys(isOnlineCount).map((item) => {
            return (
              isOnlineAttorneyPieChart.push({ IsOnline: item, Count: isOnlineCount[item] })
            )
          })
        }

        setAttorneyOnline(isOnlineAttorneyPieChart);


        let isOnlinePracticeAreasArray = [];
        let isOnlineCommunicationMethodsPreferenceArray = [];
        let isOnlineStateOfLicenceArray = [];
        let isOnlineAttorneysArray = [];

        //for every item in practice (arr = practice)
        for (let item of arrPractice) {
          //if isOnline is true then push to isOnlinePracticeAreasArray
          if (item.isOnline === true) {
            //pushing practice areas
            isOnlinePracticeAreasArray.push(...item.practiceAreas);
            //pushing attorney communication method preference
            isOnlineCommunicationMethodsPreferenceArray.push(...item.communicationMethodsPreference);
            //pushing attorney state of licence
            isOnlineStateOfLicenceArray.push(...item.statesOfLicense);

            isOnlineAttorneysArray.push(item.emailWork)
          }
        }
        // console.log(isOnlineAttorneysArray)

        const isOnlineCommunicationMethodCount = arrayToObjectCount(isOnlineCommunicationMethodsPreferenceArray);

        const isOnlineStateOfLicenceCount = arrayToObjectCount(isOnlineStateOfLicenceArray);

        const isOnlinePracticeAreas = arrayToObjectCount(isOnlinePracticeAreasArray);
        

        setIsOnlineAttorneys(isOnlineAttorneysArray);

        let onlineCommunicationMethodPieChart = [];
        if (isOnlineCommunicationMethodCount) {
          Object.keys(isOnlineCommunicationMethodCount).map((item) => {
            return (
              onlineCommunicationMethodPieChart.push({ CommunicationMethod: item, Count: isOnlineCommunicationMethodCount[item] })
            )
          })
        }

        setIsOnlineCommunicationMethodCount(onlineCommunicationMethodPieChart);

        let onlineStatesPieChart = [];
        if (isOnlineStateOfLicenceCount) {
          Object.keys(isOnlineStateOfLicenceCount).map((item) => {
            return (
              onlineStatesPieChart.push({ State: item, Count: isOnlineStateOfLicenceCount[item] })
            )
          })
        }

        setIsOnlineStateOfLicense(onlineStatesPieChart);
        

        let onlinePracticeAreasPieChart = [];
        if (isOnlinePracticeAreas) {
          Object.keys(isOnlinePracticeAreas).map((item) => {
            return (
              onlinePracticeAreasPieChart.push({ PracticeArea: item, Count: isOnlinePracticeAreas[item] })
            )
          })
        }

        setIsOnlinePracticeAreas(onlinePracticeAreasPieChart);
        console.log(onlinePracticeAreasPieChart)

      }
    )();
  }, []);
  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <div className={classes.appBarSpacer} />
      <React.Fragment>
        <Title>Consultation Details</Title>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>


            {/* <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                      <Paper className={fixedHeightPaper}>
                          <ConsultationLineCount />
                      </Paper>
                  </Grid>
              </Grid> */}


            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <div align="center" style={{ fontSize: 15 }}>Online Attorneys</div>
                {/* <div align="left" style={{ fontSize: 20 }}> Online Attorneys {attorneyOnline ?

                  Object.keys(attorneyOnline || {}).map((item, i) => {
                    return (
                      <div key={`keyID-${i}`}>
                        <ol>{item}: {attorneyOnline[item]}</ol>
                      </div>
                    )
                  })
                  : "Loading"}</div> */}
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={100} height={100}>
                    <Pie data={attorneyOnline} dataKey="Count" nameKey="IsOnline" cx="50%" cy="50%" outerRadius={60} label fill="#8884d8">
                      {attorneyOnline ?
                        attorneyOnline.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))
                        : "loading"}
                      <LabelList dataKey="IsOnline" position="top" />
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={0} />
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>


            <Grid item xs={12} md={3} lg={3}>
              <Paper className={fixedHeightPaper}>
                <div align="center" style={{ fontSize: 15 }}>Online Communication Methods</div>
                {/* <div align="left" style={{ fontSize: 20 }}> Online communication methods {isOnlineCommunicationMethodCount ?

                  Object.keys(isOnlineCommunicationMethodCount || {}).map((item, i) => {
                    return (
                      <div key={`keyID-${i}`}>
                        <ol>{item}: {isOnlineCommunicationMethodCount[item]}</ol>
                      </div>
                    )
                  })
                  : "Loading"}</div> */}

                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={100} height={100}>
                    <Pie
                      data={isOnlineCommunicationMethodCount}
                      dataKey="Count"
                      nameKey="CommunicationMethod"
                      cx="50%"
                      cy="50%"
                      label
                      outerRadius={60}
                      fill="#8884d8">
                      {isOnlineCommunicationMethodCount ?
                        isOnlineCommunicationMethodCount.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                        ))
                        : "loading"}
                      <LabelList dataKey="CommunicationMethod" position="top" />
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={0} />
                  </PieChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <Paper className={fixedHeightPaper}>
                  <Title>Online States</Title>
                <ResponsiveContainer >
                  <BarChart
                    width={500}
                    height={300}
                    data={isOnlineStateOfLicense}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="State" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="Count" fill="#8884d8" >
                      <LabelList dataKey="Count" position="middle" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Paper className={fixedHeightPaper}>
                {/* <div align="left" style={{ fontSize: 20 }}> Online practice areas: {isOnlinePracticeAreas ?

                  Object.keys(isOnlinePracticeAreas || {}).map((item, i) => {
                    return (
                      <div key={`keyID-${i}`}>
                        <ol>{item}: {isOnlinePracticeAreas[item]}</ol>
                      </div>
                    )
                  })
                  : "Loading"}</div> */}
                                    <Title>Online Practice Areas</Title>
                <ResponsiveContainer >
                  <BarChart
                    width={500}
                    height={300}
                    data={isOnlinePracticeAreas}
                    margin={{
                      top: 5, right: 30, left: 20, bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="PracticeArea" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="Count" fill="#8884d8" >
                      <LabelList dataKey="Count" position="middle" />
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            <Grid item xs={12} md={4} lg={4}>
              <Paper className={fixedHeightPaper}>
                <div align="left" style={{ fontSize: 20 }}> Online Attorneys: {isOnlineAttorneys ?

                  Object.keys(isOnlineAttorneys || {}).map((item, i) => {
                    return (
                      <div key={`keyID-${i}`}>
                        <ol>{isOnlineAttorneys[item]}</ol>
                      </div>
                    )
                  })
                  : "No attorneys online"}</div>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    </main>
  );
}
