import React, { useEffect, useState } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';

export default function Chart() {
    const [cases, setCases] = useState(null);
    const [clientCountBarData, setClientBarData] = useState(null);

    useEffect(() => {
        (
            async () => {
                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);
                let arrCase = cases;

                let thisWeek = moment().format("WW/YY");
                let oneWeekAgo = moment().subtract(1, 'weeks').endOf('day').format("WW/YY");
                let twoWeeksAgo = moment().subtract(2, 'weeks').endOf('day').format("WW/YY");
                let threeWeeksAgo = moment().subtract(3, 'weeks').endOf('day').format("WW/YY");
                let fourWeeksAgo = moment().subtract(4, 'weeks').endOf('day').format("WW/YY");

                let currentWeekFound = [];
                let oneWeekAgoFound = [];
                let twoWeeksAgoFound = [];
                let threeWeeksAgoFound = [];
                let fourWeeksAgoFound = [];
                let monthCountFound = [];

                let currentWeekArrayUser = [];
                let oneWeekAgoArrayUser = [];
                let twoWeeksAgoArrayUser = [];
                let threeWeeksAgoArrayUser = [];
                let fourWeeksAgoArrayUser = [];
                let monthCountArrayUser = [];

                //if attorney found
                for (let item of arrCase) {
                    let currentWeek = moment(item.createdAt).format("WW/YY").toString();
                    //If the client has an ID and accepts the TC and PP 
                    if (item.attorney) {
                        if (currentWeek === thisWeek) {
                            monthCountArrayUser.push(item.id)
                        }
                        if (currentWeek === oneWeekAgo) {
                            monthCountArrayUser.push(item.id)
                        }
                        if (currentWeek === twoWeeksAgo) {
                            monthCountArrayUser.push(item.id)
                        }
                        if (currentWeek === threeWeeksAgo) {
                            monthCountArrayUser.push(item.id)
                        }
                        if (currentWeek === fourWeeksAgo) {
                            monthCountArrayUser.push(item.id)
                        }
                    }
                }

                let currentWeekArrayAttorney = [];
                let oneWeekAgoArrayAttorney = [];
                let twoWeeksAgoArrayAttorney = [];
                let threeWeeksAgoArrayAttorney = [];
                let fourWeeksAgoArrayAttorney = [];
                let monthCountArrayAttorney = [];


                for (let item of arrCase) {
                    let currentWeek = moment(item.createdAt).format("WW/YY").toString();
                    //If the client has an ID and accepts the TC and PP 
                    if (!item.attorney) {
                        if (currentWeek === thisWeek) {
                            monthCountArrayAttorney.push(item.id)
                        }
                        if (currentWeek === oneWeekAgo) {
                            monthCountArrayAttorney.push(item.id)
                        }
                        if (currentWeek === twoWeeksAgo) {
                            monthCountArrayAttorney.push(item.id)
                        }
                        if (currentWeek === threeWeeksAgo) {
                            monthCountArrayAttorney.push(item.id)
                        }
                        if (currentWeek === fourWeeksAgo) {
                            monthCountArrayAttorney.push(item.id)
                        }
                    }
                }

                const clientCountBarData = [
                    {
                        name: 'Not found', count: monthCountArrayAttorney.length,
                    },
                    {
                        name: 'Found', count: monthCountArrayUser.length,
                    },
                ];
                setClientBarData(clientCountBarData);
            }
        )();
    }, []);

    return (
        <React.Fragment>
            <Title>Attorneys Found / Not Found This Month</Title>
            <ResponsiveContainer >
                <BarChart
                    data={clientCountBarData}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />

                    <Bar dataKey="count" fill="#8884d8" >
                        <LabelList dataKey="count" position="middle" />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </React.Fragment>
    );
}
