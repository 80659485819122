import { Button, Grid, Modal, TextField, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import useStyles from './styles';

function EditConsultationModal(props) {
  const { data, onSubmit, onClose, visible, isLoading } = props;
  const classes = useStyles();

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { ...data }
  });

  useEffect(() => {
    if (visible) {
      reset({ ...data })
    }
  }, [visible, data])

  if (!visible) return null;

  return (
    <Modal onClose={onClose} open={visible} className={classes.modal}>
      <div className={classes.paper}>
        <h2>Edit Consutlation</h2>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.formContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ul className={classes.caseDetails}>
                <li><span>Client:</span> {data?.client?.firstName} {data?.client?.lastName} - {data.client?.email}</li>
                <li><span>Attorney:</span> {data?.attorney?.firstName} {data?.attorney?.lastName} - {data.attorney?.emailWork}</li>
                <li><span>Create at:</span> {data?.createdAt}</li>
                <li><span>Description:</span> {data?.legalAdviceDescription}</li>
                <li><span>Method:</span> {data?.consultationConnectionMethod}</li>
                <li><span>Law Area:</span> {data?.areaOfLaw}</li>
                <li><span>Venue State:</span> {data?.venueState}</li>
                <li><span>Payment method:</span> {data?.paymentMethod}</li>
                <li><span>Amount:</span> {data?.amount}</li>
                <li><span>Stripe Token:</span> {data?.stripeToken}</li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={6} direction="column" container>
              <h4>Ratings</h4>
              <Controller
                name="rating.overallScore"
                control={control}
                render={({ field }) => <TextField type='number' max='5' min='' {...field} label="Overall Rating" defaultValue={field.value} variant='filled' className={classes.input} />}
              />

              <Controller
                name="rating.friendlinessScore"
                control={control}
                render={({ field }) => <TextField type='number' max='5' min='' {...field} label="Friendliness Score" defaultValue={field.value} variant='filled' className={classes.input} />}
              />

              <Controller
                name="rating.knowledgeScore"
                control={control}
                render={({ field }) => <TextField type='number' max='5' min='' {...field} label="Knowledge Score" defaultValue={field.value} variant='filled' className={classes.input} />}
              />

              <Controller
                name="rating.feedbackText"
                control={control}
                render={({ field }) => <TextField {...field} label="Feedback Text" defaultValue={field.value} variant='filled' className={classes.input} />}
              />
            </Grid>

          </Grid>

          <Button type="submit" variant='contained' color='primary'
            disabled={isLoading}
            startIcon={isLoading ? <CircularProgress size={20} /> : null}
          >
            Submit
          </Button>
        </form>
      </div>
    </Modal>
  )
}

export default EditConsultationModal;