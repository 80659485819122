import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: 910,
    width: '95%',
    margin: 'auto',

    "& .wrapHeader .MuiDataGrid-colCellTitle": {
      lineHeight: "15px",
      whiteSpace: "normal",
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: theme.palette.background.paper,
    },
  },
}));

export default useStyles;