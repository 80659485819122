import { Button } from '@material-ui/core';
import React, { useMemo } from 'react';
import DataTable from '../../commons/DataTable';
import useStyles from './styles';

function AttorneyDataTable(props) {
  const { data, handleEdit, handleToggleActive, isLoading } = props;
  const classes = useStyles();

  const columns = [
    { headerClassName: 'data-table-header', field: 'firstName', headerName: 'First Name', flex: 1 },
    { headerClassName: 'data-table-header', field: 'lastName', headerName: 'Last Name', flex: 1 },
    { headerClassName: 'data-table-header', field: 'email', headerName: 'Email', flex: 1 },
    { headerClassName: 'data-table-header', field: 'state', headerName: 'States', flex: 1 },
    { headerClassName: 'data-table-header', field: 'licenseNumber', headerName: 'License Number', flex: 1 },
    { headerClassName: 'data-table-header', field: 'phone', headerName: 'Phone Number', flex: 1 },
    { headerClassName: 'data-table-header', field: 'notificationMethods', headerName: 'Notification Preferrences', flex: 1 },
    { headerClassName: 'data-table-header', field: 'communicationMethodsPreference', headerName: 'Communication Channels', flex: 1 },
    {
      headerClassName: 'data-table-header', field: 'isActive', headerName: 'Activated', flex: 1,
      renderCell: (params) => {
        const { value } = params;
        return (
          <b style={{ color: value === false ? 'red' : 'black' }}>
            {value === false ? 'No' : 'Yes'}
          </b>
        );
      }
    },
    {
      headerClassName: 'data-table-header',
      headerName: 'Actions',
      field: 'actions',
      renderCell: (params) => (
        <div>
          <Button className={classes.actionButton} variant='contained' color='default' onClick={() => handleEdit(params.value)}>Edit</Button>
          <Button className={classes.actionButton} variant='contained'
            color={params.value.isActive === null ? 'secondary' : !params.value.isActive ? 'primary' : 'secondary'}
            onClick={() => handleToggleActive(params.value)}>
            {params.value.isActive === false ? 'Activate' : 'Deactivate'}
          </Button>
        </div>
      ),
      width: 250,
    }
  ]

  const rows = useMemo(() => data?.map(item => ({
    id: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.emailWork,
    state: item.statesOfLicense?.join('\n'),
    licenseNumber: item.licenseNumber,
    phone: item.cellPhone,
    notificationMethods: item.notificationMethods?.join(', '),
    communicationMethodsPreference: item.communicationMethodsPreference?.join(', ').toUpperCase(),
    isActive: item.isActive,
    actions: item
  })), [data])

  return (
    <>
      <DataTable data={rows} columns={columns} loading={isLoading} />
    </>
  )
}

export default AttorneyDataTable;