import React from 'react';
import './App.css';
import Dashboard from './components/Dashboard.js'
import { withAuthenticator, AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import Amplify from "aws-amplify";

// import awsExports from "./aws-exports";
import AWSConfig from './aws-exports-helper'
import LegalCategories from './components/LegalCategories.js';
import usertrends from './components/UserTrends.js';
import AttorneyDetails from './components/AttorneyDetails/index';
import AttorneyTrends from './components/AttorneyTrends.js';
import LiveStatus from './components/LiveStatus.js';
import ConsultationDetails from './components/ConsultationDetails.js';
import MissedConsultations from './components/MissedConsultations.js';
import UserDetails from './components/UserDetails/index';
import homepage from './components/HomePage.js';
import { Route, Switch, Redirect } from 'react-router-dom';
Amplify.configure(AWSConfig);


function App() {
  return (
    
    <div className="App">
      <div style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AmplifyAuthenticator>
      <AmplifySignIn
    headerText="Ask to create an account"
    hideSignUp="true"
    slot="sign-in"
    
  ></AmplifySignIn>

{/* <AmplifySignUp
    slot="sign-up"
    hide-sign-up="true"
    
    
  ></AmplifySignUp> */}
        <Dashboard>
          <Switch>
          <Route
                exact
                path="/"
                render={() => {
                    return (
            
                      <Redirect to="/homepage" />  
                    )
                }}
              />
            <Route path="/homepage" component={homepage} />
            <Route path="/LegalCategories" component={LegalCategories} />
            <Route path="/usertrends" component={usertrends} />
            <Route path="/LiveStatus" component={LiveStatus} />
            <Route path="/AttorneyDetails" component={AttorneyDetails} />
            <Route path="/AttorneyTrends" component={AttorneyTrends} />
            <Route path="/ConsultationDetails" component={ConsultationDetails} />
            <Route path="/MissedConsultations" component={MissedConsultations} />
            <Route path="/UserDetails" component={UserDetails} />
          </Switch>
        </Dashboard>
        </AmplifyAuthenticator>
      </div>
    </div>
    
  );
  
}

export default App;
