import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import * as queries from '../../graphql/customQueries';
import Title from "../Title";
import useStyles from "./styles";
import UserDataTable from "./UserDataTable";
import UserEditModal from "./UserEditModal";
import { updateUser } from '../../graphql/customMutations'

function UserDetails() {
  const classes = useStyles();

  const [clients, setClients] = useState([])
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [selectedEditData, setSelectedEditData] = useState()

  useEffect(() => {
    getUserList();
  }, [])

  async function getUserList() {
    API.graphql(graphqlOperation(queries.listClientTest))
      .then(repsonse => {
        handleClientList(repsonse.data.listClients.items)
      })
      .catch(err => {
        handleClientList(err.data.listClients.items)
      })
  }

  function handleClientList(clients) {
    const data = clients?.filter(q => q);
    setClients(data)
  }

  function toggleEditModal() {
    setIsEditModalVisible(!isEditModalVisible)
  }

  async function handleSubmit(values) {
    console.log('values :>> ', values);
    if (values) {
      try {
        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              state: values.state,
              phone: values.phone,
            },
          })
        );

        getUserList();
      } catch (error) {
        alert('Edit user failed')
      }
    }
  }

  function handleEdit(data) {
    setIsEditModalVisible(true)
    setSelectedEditData(data)
  }

  return (
    <React.Fragment>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {
          clients && clients.length ? (
            <React.Fragment>
              <Title>Clients Details</Title>
              <UserDataTable data={clients} handleEdit={handleEdit} />
            </React.Fragment>
          )
            : <p>Loading...</p>
        }
        <UserEditModal data={selectedEditData} isVisible={isEditModalVisible} onClose={toggleEditModal} onSubmit={handleSubmit} />
      </main>
    </React.Fragment>
  );
}

export default UserDetails;
