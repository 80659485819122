import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import ConsultationLineChart from './LineChart';
import useStyles from './styles';

function ConsultationChart(props) {
  const { data } = props;

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [active, setActive] = useState('FirstCard')


  return (
    <>
      <Button color={active === 'FirstCard' ? 'secondary' : 'default'} onClick={() => setActive("FirstCard")}>This Week</Button>
      <Button color={active === 'SecondCard' ? 'secondary' : 'default'} onClick={() => setActive("SecondCard")}>Past 5 Weeks</Button>
      <Button color={active === 'ThirdCard' ? 'secondary' : 'default'} onClick={() => setActive("ThirdCard")}>Past Year</Button>

      <Grid item xs={12} md={12} lg={12}>
        <Paper className={fixedHeightPaper}>
          <ConsultationLineChart activeMode={active} data={data} />
        </Paper>
      </Grid>
    </>
  )
}

export default ConsultationChart;