export const updateAttorney = /* GraphQL */ `
mutation UpdateAttorney(
  $input: UpdateAttorneyInput!
  $condition: ModelAttorneyConditionInput
) {
  updateAttorney(input: $input, condition: $condition) {
    id
    firstName
    lastName
    approved
    emailWork
    statesOfLicense
    licenseNumber
    cellPhone
    notificationMethods
    communicationMethodsPreference
  }
}
`;

export const updateUser = /* GraphQL */ `
mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    id
    firstName
    lastName
    email
    state
    phone
  }
}
`;

export const updateConsultationRating = /* GraphQL */ `
mutation UpdateRating(
  $input: UpdateRatingInput!
  $condition: ModelRatingConditionInput
) {
  updateRating(input: $input, condition: $condition) {
    id
    overallScore
    knowledgeScore
    friendlinessScore
    feedbackText
  }
}
`

export const createConsultationRating = /* GraphQL */ `
mutation CreateRating(
  $input: CreateRatingInput!
  $condition: ModelRatingConditionInput
) {
  createRating(input: $input, condition: $condition) {
    id
    overallScore
    knowledgeScore
    friendlinessScore
    feedbackText
  }
}
`

export const updateCaseRating = /* GraphQL */ `
mutation UpdateCaseRating(
  $input: UpdateCaseInput!
  $condition: ModelCaseConditionInput
) {
  updateCase(input: $input, condition: $condition) {
    id
    rating {
      id
      overallScore
      knowledgeScore
      friendlinessScore
      feedbackText
      createdAt
    }
  }
}
`