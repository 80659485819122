import React, { useEffect, useState } from 'react';
import * as queries from '../graphql/customQueries';
//import { updateAttorney } from '../graphql/customMutations';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';



//creating a column and title
const columns = [
    { field: 'practiceAreas', headerName: 'Practice Areas' },
];

const practiceAreaOrder = [
    { field: 'practiceAreas', headerName: 'Practice Areas test' },
    { field: 'count', headerName: 'Count' },
];

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
    createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
    createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
    createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
];

//forcing lowerCase
const lowerCase = str => str.toLowerCase();

//creating a component called filterPracticeAreas with practice and serch arguments
const filterPracticeAreas = (practice, search) => {

    //if not search retrun practice
    if (!search) return practice;

    const filtered = practice.filter(item => {
        //practiceAreas is the field in the columns
        const { practiceAreas } = item;

        return (
            lowerCase(practiceAreas).indexOf(search) !== -1
        )
    })

    return filtered;


}

const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

function PracticeAreas() {

    //setting up practice, search and isLoading?
    const [practice, setPractice] = useState(null);

    //~I added this line
    const [payments, setPayments] = useState(null);
    const [consultations, setConsultations] = useState(null);
    const [cases, setCases] = useState(null);
    const [client, setClient] = useState(null);
    const [clientCount, setClientCount] = useState(null);
    const [clientState, setClientState] = useState(null);
    const [clientCity, setClientCity] = useState(null);
    //const [countOfAreas, setCountOfArea] = useState(null);
    const [practiceAreasCount, setPracticeAreasCount] = useState(null);
    const [consultationsStatus, setConsultationsStatus] = useState(null);
    const [amountEqual39, setAmount39] = useState(null);
    const [amountEqual59, setAmount59] = useState(null);
    const [attorneyOnline, setAttorneyOnline] = useState(null);
    const [isOnlineCommunicationMethodCount, setIsOnlineCommunicationMethodCount] = useState(null);
    const [isOnlineStateOfLicense, setIsOnlineStateOfLicense] = useState(null);
    const [isOnlinePracticeAreas, setIsOnlinePracticeAreas] = useState(null);
    const [attorneyNotFound, setAttorneyNotFound] = useState(null);
    const [attorneyFound, setAttorneyFound] = useState(null);
    const [attorneyImage, setAttorneyImage] = useState(null);
    const [caseDescription, setCaseDescription] = useState(null);
    const [caseIndividualUsersThatDidNotFindAttorney, setCaseIndividualUsersThatDidNotFindAttorney] = useState(null);
    const [caseIndividualAttorneySessions, setCaseIndividualAttorneySessions] = useState(null);
    const [caseConnectionMEthodCount, setCaseConnectionMEthodCount] = useState(null);
    const [presignedUrl, setpresignedUrl] = useState(null);
    const [futureConsultations, setFutureConsultations] = useState(null);

    const [search, setSearch] = useState();
    //  const [status, setStatus] = useState('unapproved');
    const [isLoading, setLoading] = useState(false);


    useEffect(() => {
        (
            async () => {
                //here it grabs listPracticeAreas
                const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
                //set practice to listAttorneys
                const practice = responseListAttorneys.data.listAttorneys.items;
                setPractice(practice);

                //grabbing listPayment
                const responseListPayments = await API.graphql(graphqlOperation(queries.listPaymentsTest));
                const payments = responseListPayments.data.listPayments.items;
                setPayments(payments);
                const payments15 = 39; //15 minutes cost $39 
                const payments30 = 59;  //30 minutes cost $59

                //grabbing listConsultations
                const responseListConsultations = await API.graphql(graphqlOperation(queries.listConsultationsTest));
                const consultations = responseListConsultations.data.listConsultations.items;
                setConsultations(consultations);

                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);

                const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
                const client = responseListClients.data.listClients.items;
                setClient(client);

                // const responseGetAttorney = await API.graphql(graphqlOperation(queries.getAttorneyTest, {id:"a70e136e-4db2-4f40-a647-b919d3fb8b4e"}));
                // const attorneyInfo = responseGetAttorney.data.getAttorney;
                // let arrAttorneyInfo = attorneyInfo;

                //console.log(arrAttorneyInfo("a70e136e-4db2-4f40-a647-b919d3fb8b4e"));

                let arrClient = client;
                console.log(arrClient); 
                let clientValidIDArray = [];
                let clientStatesArray = [];
                let clientCityArray = [];
                let clientInvalidArray = [];


                for (let item of arrClient) {
                    //If the client has an ID and accepts the TC and PP 
                    if (item.id && item.acceptsTCAndPP === true) {
                        clientValidIDArray.push(item.id);
                        clientStatesArray.push(item.state)
                        clientCityArray.push(item.city)
                    }
                    //checking if anyone is able to enter the app without acceping TC and PP
                    if (item.acceptsTCAndPP === null) {
                        clientInvalidArray.push(item.id)
                    }
                }

                const clientCount = clientValidIDArray;
                setClientCount(clientCount);

                const clientCityCount = arrayToObjectCount(clientCityArray);
                setClientCity(clientCityCount);

                const clientStateCount = arrayToObjectCount(clientStatesArray);
                setClientState(clientStateCount);


                let arrPractice = practice;
                let practiceAreas = [];

                /*
                practice areas look like this:
                data": {
    "listAttorneys": {
      "items": [
        {
          "practiceAreas": [
            "RealEstate",
            "ProbateAndTrust",

          ]
        },
        {
          "practiceAreas": [
            "ProbateAndTrust",
          ]
          for every area push into the practiceAreas array this is expected to be big
          the length of this is 242 and is structured like this [RealEstate,ProbateAndTrust,ProbateAndTrust...]
          important to note that arr is practice from the jason above  */
                for (let item of arrPractice) { practiceAreas.push(...item.practiceAreas); }
                const practiceAreasFinalCount = arrayToObjectCount(practiceAreas);
                setPracticeAreasCount(practiceAreasFinalCount);

                //finding total payments of $39 and $59 this is redundant compared to individual attorny payments
                //I could just take the length of that instead of this and load things slightly faster. 
                const amountEqual39 = payments.filter((item) => {
                    return item.amount === payments15
                })
                setAmount39(amountEqual39);


                const amountEqual59 = payments.filter((item) => {
                    return item.amount === payments30
                })
                setAmount59(amountEqual59);




                //this deals with all the consultations
                let arrConsultations = consultations;
                let consultationsStatusArray = [];

                //put status into a single array
                for (let item of arrConsultations) { consultationsStatusArray.push(item.status); }
                //count the statues in the array and save as object
                const consultationsStatusCount = arrayToObjectCount(consultationsStatusArray);

                setConsultationsStatus(consultationsStatusCount);




                // this deals with all the isOnline code
                let isOnlineArray = [];

                for (let item of arrPractice) { isOnlineArray.push(item.isOnline); }
                const isOnlineCount = arrayToObjectCount(isOnlineArray);

                setAttorneyOnline(isOnlineCount);


                let isOnlinePracticeAreasArray = [];
                let isOnlineCommunicationMethodsPreferenceArray = [];
                let isOnlineStateOfLicenceArray = [];

                //for every item in practice (arr = practice)
                for (let item of arrPractice) {
                    //if isOnline is true then push to isOnlinePracticeAreasArray
                    if (item.isOnline === true) {
                        //pushing practice areas
                        isOnlinePracticeAreasArray.push(...item.practiceAreas);
                        //pushing attorney communication method preference
                        isOnlineCommunicationMethodsPreferenceArray.push(...item.communicationMethodsPreference);
                        //pushing attorney state of licence
                        isOnlineStateOfLicenceArray.push(...item.statesOfLicense);
                    }
                }

                const isOnlineCommunicationMethodCount = arrayToObjectCount(isOnlineCommunicationMethodsPreferenceArray);
                setIsOnlineCommunicationMethodCount(isOnlineCommunicationMethodCount);
                const isOnlineStateOfLicenceCount = arrayToObjectCount(isOnlineStateOfLicenceArray);
                setIsOnlineStateOfLicense(isOnlineStateOfLicenceCount);
                const isOnlinePracticeAreas = arrayToObjectCount(isOnlinePracticeAreasArray);
                setIsOnlinePracticeAreas(isOnlinePracticeAreas);


                let attorneyAvatarURLArray = [];
                for (let item of arrPractice) {
                    //if isOnline is true then push to isOnlinePracticeAreasArray
                    if (item.avatarUrl) {
                        attorneyAvatarURLArray.push(item.avatarUrl);
                    }
                }
                //individual poto here
                const presignedUrl = await Storage.get("attorneys/profile-images/9e1b730e-f8d5-4e95-b44c-252618d6f0be.jpg", {
                    level: "public"
                });

                /* This is what you have to do 
                push the console.log link into an array and set it up for visual.  */
                setAttorneyImage(attorneyAvatarURLArray);
                setpresignedUrl(presignedUrl);
                console.log("look here",presignedUrl);

                let arrCase = cases;
                let caseAttorneyNotFoundArray = [];
                let caseTimeOfAttorneyNotFoundArray = [];
                let futureConsultations = [{}];

                // let testData2 = [{}];
                // if (clientState !== null) {
                //     Object.keys(clientState).map((item) => {
                //         testData2.push({ state: item, count: clientState[item] })
                //     })
                // }

                for (let item of arrCase) {
                    if (item.futureConsultationDateTime && item.attorney) {
                        futureConsultations.push({
                            AttorneyFirstName: item.attorney.firstName, 
                            AttorneyPicture: item.attorney.avatarUrl, 
                            ClientFirstName: item.client.firstName, 
                            ClientPicture: item.client.profilePictureUrl,
                            FutureConsultationTime: item.futureConsultationDateTime, 
                            LegalCategory: item.areaOfLaw, 
                            ConsultationMethod: item.consultationConnectionMethod, 
                            PaymentAmount: item.payment.amount,
                            CaseId: item.id
                        });
                    }
                }
                setFutureConsultations(futureConsultations);

                for (let item of arrCase) {
                    if (!item.attorney) {
                        caseAttorneyNotFoundArray.push(item.client.id);
                        caseTimeOfAttorneyNotFoundArray.push(item.updatedAt);
                    }
                }

                const attorneyNotFound = caseAttorneyNotFoundArray;
                setAttorneyNotFound(attorneyNotFound);
                const caseIndividualUsersThatDidNotFindAttorney = arrayToObjectCount(caseAttorneyNotFoundArray);
                setCaseIndividualUsersThatDidNotFindAttorney(caseIndividualUsersThatDidNotFindAttorney);

                let caseAttorneyFoundArray = [];
                let caseTime = [];
                let caseDescription = [];
                let caseConnectionMethod = [];

                for (let item of arrCase) {
                    if (item.attorney) {
                        caseAttorneyFoundArray.push(item.attorney.id);
                        caseTime.push(item.updatedAt);
                        caseDescription.push(item.legalAdviceDescription);
                        caseConnectionMethod.push(item.consultationConnectionMethod)
                    }
                }

                setCaseDescription(caseDescription);
                const attorneyFound = caseAttorneyFoundArray;
                setAttorneyFound(attorneyFound);

                //console.log(caseConnectionMethod);
                // console.log(caseAttorneyFoundArray);
                // console.log(caseTime);
                // console.log(caseDescription);

                const caseIndividualAttorneySessions = arrayToObjectCount(caseAttorneyFoundArray);
                setCaseIndividualAttorneySessions(caseIndividualAttorneySessions);

                const caseConnectionMEthodCount = arrayToObjectCount(caseConnectionMethod);
                setCaseConnectionMEthodCount(caseConnectionMEthodCount);

                const AttorneyPayments39 = [];
                const AttorneyPayments59 = [];
                for (let item of arrCase) {
                    if (item.attorney) {
                        if (item.payment.amount === payments15) {
                            AttorneyPayments39.push(item.attorney.id)
                        }
                        if (item.payment.amount === payments30) {
                            AttorneyPayments59.push(item.attorney.id)
                        }
                    }
                }

                //Individual Attorny Payments
                const totalPayments39 = arrayToObjectCount(AttorneyPayments39);
                const totalPayments59 = arrayToObjectCount(AttorneyPayments59);


                //   caseIndividualAttorneyPayments59.map((item)=>{
                //     //console.log(item.attorney.id)
                // })


            }
        )();
    }, []);

    return (

        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1>
                    Ops Dashboard &nbsp;
                    </h1>
                {
                    isLoading && <CircularProgress color="secondary" />
                }

            </div>
            {

                practice ?
                    <>
                        <div align="left" style={{ fontSize: 45 }}>Total attorneys: {practice ? practice.length : 0}</div>
                        <div align="left" style={{ fontSize: 45 }}>Total users: {clientCount ? client.length : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}>Attorney not found: {attorneyNotFound ? attorneyNotFound.length : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}>Attorney found: {attorneyFound ? attorneyFound.length : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}>Attorneys that have done a session: {caseIndividualAttorneySessions ? Object.keys(caseIndividualAttorneySessions).length : 0} </div>
                        <div align="left" style={{ fontSize: 22 }}>Total payments: {payments ? payments.length : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}>Total $39 payments: {amountEqual39 ? amountEqual39.length : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}>Total $59 payments: {amountEqual59 ? amountEqual59.length : 0} </div>
                        <div align="left" style={{ fontSize: 22 }}>Total $59 payments: {presignedUrl ? <img src={presignedUrl} /> : 0} </div>

                        <div align="left" style={{ fontSize: 22 }}>Count of practice areas: {practice ?

                            /*this grabs Object.keys of practiceAreasCount and checks or "||" object */
                            Object.keys(practiceAreasCount || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        {/* this gets the items from practiceAreasCount keys "immigration" and
                                            to get the value you need to call practiceAreasCount[item] */}
                                        <ol>{item}: {practiceAreasCount[item]}</ol>
                                    </div>
                                )
                            }) : 0}</div>




                        <div align="left" style={{ fontSize: 22 }}> Status {practice ?

                            Object.keys(consultationsStatus || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {consultationsStatus[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>

                        <div align="left" style={{ fontSize: 22 }}> Completed connection methods count {cases ?

                            Object.keys(caseConnectionMEthodCount || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {caseConnectionMEthodCount[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>

                        <div align="left" style={{ fontSize: 22 }}> Online Attorneys {practice ?

                            Object.keys(attorneyOnline || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {attorneyOnline[item]}</ol>

                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Online communication methods {practice ?

                            Object.keys(isOnlineCommunicationMethodCount || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {isOnlineCommunicationMethodCount[item]}</ol>

                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Online States {practice ?

                            Object.keys(isOnlineStateOfLicense || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {isOnlineStateOfLicense[item]}</ol>

                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Online practice areas: {practice ?

                            Object.keys(isOnlinePracticeAreas || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {isOnlinePracticeAreas[item]}</ol>

                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Attorney Image: {practice ?

                            Object.keys(attorneyImage || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <img src={presignedUrl} />
                                        <ol>{attorneyImage[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Individual Attorney Sessions: {practice ?

                            Object.keys(caseIndividualAttorneySessions || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {caseIndividualAttorneySessions[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Individual Users that did not find a Session: {practice ?

                            Object.keys(caseIndividualUsersThatDidNotFindAttorney || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {caseIndividualUsersThatDidNotFindAttorney[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Total Client States {practice ?

                            Object.keys(clientState || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {clientState[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>
                        <div align="left" style={{ fontSize: 22 }}> Total Client Cities {practice ?

                            Object.keys(clientCity || {}).map((item, i) => {
                                return (
                                    <div key={`keyID-${i}`}>
                                        <ol>{item}: {clientCity[item]}</ol>
                                    </div>
                                )
                            })
                            : 0}</div>

                        { futureConsultations ?
                            <>

                                <TableContainer component={Paper}>
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Attorney URL</TableCell>
                                                <TableCell align="right">Attorney First Name</TableCell>
                                                <TableCell align="right">Client First Name&nbsp;</TableCell>
                                                <TableCell align="right">Time Scheduled&nbsp;</TableCell>
                                                <TableCell align="right">Legal Category&nbsp;</TableCell>
                                                <TableCell align="right">Payment&nbsp;</TableCell>
                                                <TableCell align="right">Connection Method&nbsp;</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {futureConsultations.map((row) => (
                                                <TableRow key={row.CaseId}>
                                                    <TableCell component="th" scope="row">
                                                        {row.AttorneyPicture}
                                                    </TableCell>
                                                    <TableCell align="right">{row.AttorneyFirstName}</TableCell>
                                                    <TableCell align="right">{row.ClientFirstName}</TableCell>
                                                    <TableCell align="right">{row.FutureConsultationTime}</TableCell>
                                                    <TableCell align="right">{row.LegalCategory}</TableCell>
                                                    <TableCell align="right">{row.PaymentAmount}</TableCell>
                                                    <TableCell align="right">{row.ConsultationMethod}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </> : "Loading"}


                        { futureConsultations ?
                            <>
                                <TableContainer component={Paper}>
                                    <Table aria-label="collapsible table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>Dessert (100g serving)</TableCell>
                                                <TableCell align="right">Calories</TableCell>
                                                <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                                <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                                <TableCell align="right">Protein&nbsp;(g)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <Row key={row.name} row={row} />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </> : "Loading"}

                        {/* <div align="left" style={{fontSize: 22}}> Individual Users that did not find a Session: {practice ?

                                Object.keys(caseDescription|| {}).map((item, i)=>{
                                    return(
                                        <div key={`keyID-${i}`}>
                                            <ol>{item}: {caseDescription[item]}</ol>
                                        </div>
                                    )
                                })
                              : 0}</div> */}




{/* 
                        <div style={{ height: 450 }}>
                            <TableContainer component={Paper} style={{ height: '100%' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                columns.map((item) => (
                                                    <TableCell key={item.field}>
                                                        <b>{item.headerName}</b>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filterPracticeAreas(practice, search).map((item, i) => (
                                            <TableRow key={i}>
                                                <TableCell>
                                                    {
                                                        item.practiceAreas ?
                                                            item.practiceAreas.map((area, i) => (
                                                                <Chip label={area} key={i} />
                                                            )) :
                                                            'N/A'
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>




                        <div style={{ height: 450 }}>
                            <TableContainer component={Paper} style={{ height: '100%' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                practiceAreaOrder.map((item) => (
                                                    <TableCell key={item.field}>
                                                        <b>{item.headerName}</b>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>

                                </Table>
                            </TableContainer>
                        </div> */}
                    </> :
                    "Fetching practice areas"
            }
        </div>
    );

}
export default PracticeAreas;

//   console.log("test",practiceAreasCount);
/*                practiceAreasCount.map((item)=>{
                    console.log("practiceAreasCount",item)
                })*/


/*
                let countOfAreas = {};
                let areasChecked = [];

                //for every item in practiceAreas ??
                for (let item of practiceAreas) {
                    console.log(areasChecked.indexOf(1))
                    if (areasChecked.indexOf(item) !== -1) continue;
                    countOfAreas[item] = practiceAreas.filter(x => x === item).length;
                    areasChecked.push(item);
                }
                //for (let item in countOfAreas) console.log(`${item}: ${countOfAreas[item]}`);

                for (let item in countOfAreas) {
                    console.log(` ${item}: ${countOfAreas[item]}`);
                }
*/

/*                countOfAreas.map((item)=>{
                    console.log(`${countOfAreas}`)
                })*/
//console.log(Object.keys(countOfAreas));


// setCountOfArea(countOfAreas);



/*                const testest = practice.filter((item) => {

                    return item.id === "6c0e52c8-32ea-488b-9828-39b14dc8e789"

                })
                console.log("This should only return a specific ID", testest)
                */


/*                practice.map((item)=>{
                    console.log("This should return every ID",item.id)



                                                            <TableBody>
                                            {filterPracticeAreas(practice, search).map((item, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>
                                                        {
                                                            item.practiceAreas ?
                                                                item.practiceAreas.map((area, i) => (
                                                                    <Chip label={area} key={i}/>
                                                                )) :
                                                                'N/A'
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <div align="left" style={{fontSize: 22}}>Count of practice areas: {practice ?
                                                            /*this grabs Object.keys of practiceAreasCount and checks or "||" object */
                //                                             Object.keys(practiceAreasCount || {}).map((item, i)=>{
                //                                               return(
                //                                                   <div key={`keyID-${i}`}>
                //                                                       { this gets the items from practiceAreasCount keys "immigration" and
                //                                                             to get the value you need to call practiceAreasCount[item]
                //                                                       {practiceAreasCount[item]}
                //                                                   </div>
                //                                               )
                //                                           }) : 0}</div>
                //                                   </TableCell>
                //                               </TableRow>
                //                           ))}
                //                       </TableBody>
                // })*/