export const statesOptions = [
  {
    label: 'MN',
    value: 'Minnesota',
  },
  {
    label: 'CA',
    value: 'California',
  },
  {
    label: 'NY',
    value: 'New York',
  },
  {
    label: 'IL',
    value: 'Illinois',
  },
  {
    label: 'DE',
    value: 'Delaware',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];