import React, { useEffect, useState, PureComponent } from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';
import * as queries from '../graphql/customQueries';
//import { updateAttorney } from '../graphql/customMutations';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Button from '@material-ui/core/Button';
//import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { TableSortLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import NumberOfUsersByState from './numbersOfUsersByState';
import FoundNotFoundWeek from './FoundNotFoundWeek'
import FoundNotFoundMonth from './FoundNotFoundMonth'
import FoundNotFoundYear from './FoundNotFoundYear'
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LabelList, ResponsiveContainer,
} from 'recharts';
import {
    Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Label,
} from 'recharts';
import Button from '@material-ui/core/Button';
import Stripe from 'stripe';
const stripe = new Stripe('sk_test_19LcWglVLOj5FrtZGKl0Jgdc00Ihw76AEb', {
    apiVersion: '2020-08-27',
});

const useStyles = makeStyles((theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    seeMore: {
        marginTop: theme.spacing(3),
    },
    root: {
        display: 'flex',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const arrayToObjectCount = array => {
    //declare an empty object ( {} = empty object)
    const obj = {};
    //loop through the given array
    for (let item of array) {
        //is the element in the object, if so add one to its value
        if (obj[item]) {
            obj[item]++;
        } else {
            //if not set its value to 1
            obj[item] = 1;
        }
        //return the object
    }
    return obj;
}

export default function AttorneyDashboard() {
    const classes = useStyles();
    const [cases, setCases] = useState(null);
    const [client, setClient] = useState(null);
    const [AttorneyDiscoveryBarData, setAttorneyDiscoveryBarData] = useState(null);
    const [stateBarData, setStateBarData] = useState(null);
    const [cityBarData, setCityBarData] = useState(null);
    const [active, setActive] = useState("FirstCard")
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    useEffect(() => {
        (
            async () => {

                const responseListCases = await API.graphql(graphqlOperation(queries.listCasesTest));
                const cases = responseListCases.data.listCases.items;
                setCases(cases);
                let arrCase = cases;

                const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
                const client = responseListClients.data.listClients.items;
                setClient(client);
                let arrClient = client;


                //let clientValidIDArray = [];
                let clientStatesArray = [];
                let clientCityArray = [];
                let clientInvalidArray = [];

                for (let item of arrClient) {
                    //If the client has an ID and accepts the TC and PP 
                    if (item.id && item.acceptsTCAndPP === true) {
                        //clientValidIDArray.push(item.id);
                        clientStatesArray.push(item.state)
                        clientCityArray.push(item.city)
                    }
                    //checking if anyone is able to enter the app without acceping TC and PP
                    if (item.acceptsTCAndPP === null) {
                        clientInvalidArray.push(item.id)
                    }
                }

                //getting valid user counts
                //const clientCount = clientValidIDArray;

                //getting user city count
                const clientCity = arrayToObjectCount(clientCityArray);


                //getting user state count
                const clientState = arrayToObjectCount(clientStatesArray);


                let caseAttorneyNotFoundArray = [];
                let caseTimeOfAttorneyNotFoundArray = [];

                //if no attorney is found then add the client id
                for (let item of arrCase) {
                    if (!item.attorney) {
                        caseAttorneyNotFoundArray.push(item.client.id);
                        caseTimeOfAttorneyNotFoundArray.push(item.updatedAt);
                    }
                }


                let caseAttorneyFoundArray = [];
                let caseConnectionMethod = [];
                //if attorney is found then add the client id
                for (let item of arrCase) {
                    if (item.attorney) {
                        caseAttorneyFoundArray.push(item.attorney.id);
                        caseConnectionMethod.push(item.consultationConnectionMethod);
                    }
                }

                //Creating Attorney found/not found object arrays
                const AttorneyDiscoveryBarData = [
                    {
                        name: 'Not found', count: caseAttorneyNotFoundArray.length,
                    },
                    {
                        name: 'Found', count: caseAttorneyFoundArray.length,
                    },
                ];
                setAttorneyDiscoveryBarData(AttorneyDiscoveryBarData);

                //Creating Number of clients by state object arrays
                let stateBarData = [];
                if (clientState) {
                    Object.keys(clientState).map((item) => {
                        return (
                            stateBarData.push({ State: item, Count: clientState[item] })
                        )
                    })
                }
                setStateBarData(stateBarData);

                let cityBarData = [];
                if (clientCity !== null) {
                    Object.keys(clientCity).map((item) => {
                        return (
                            cityBarData.push({ City: item, Count: clientCity[item] })
                        )
                    })
                }
                setCityBarData(cityBarData);

            }
        )();
    }, []);
    return (
        <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div className={classes.appBarSpacer} />
            <React.Fragment>
                <Title>User Trends</Title>
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>


                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper className={fixedHeightPaper}>
                                    <NumberOfUsersByState />
                                </Paper>
                            </Grid>
                        </Grid>


                        <Grid item xs={4} md={4} lg={4}>
                    <Button variant="contained" onClick={() => setActive("FirstCard")}>This Week</Button>
                    <Button variant="contained" onClick={() => setActive("SecondCard")}>Past Month</Button>
                    <Button variant="contained" onClick={() => setActive("ThirdCard")}>Past Year</Button>
                        <Paper className={fixedHeightPaper}>
                            {active === "FirstCard" && <FoundNotFoundWeek />}
                            {active === "SecondCard" && <FoundNotFoundMonth />}
                            {active === "ThirdCard" && <FoundNotFoundYear />}
                        </Paper>
                            {/* <Paper className={fixedHeightPaper}>
                            <Title>Found/not found attorney All Time</Title>
                            <ResponsiveContainer >  
                                <BarChart
                                    data={AttorneyDiscoveryBarData}
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />

                                    <Bar dataKey="count" fill="#8884d8" >
                                        <LabelList dataKey="count" position="middle" />
                                    </Bar>
                                </BarChart>
                                </ResponsiveContainer>
                            </Paper> */}
                        </Grid>


                        <Grid item xs={8} md={8} lg={8}>
                            <Paper className={fixedHeightPaper}>
                            <Title>Users by State (null = no input)</Title>
                            <ResponsiveContainer >  
                                <BarChart
                                    width={500}
                                    height={300}
                                    data={cityBarData}
                                    margin={{
                                        top: 5, right: 30, left: 20, bottom: 5,
                                    }}
                                >


                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="City" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />

                                    <Bar dataKey="Count" fill="#8884d8" >
                                        <LabelList dataKey="Count" position="middle" />
                                    </Bar>
                                </BarChart>
                                </ResponsiveContainer>
                            </Paper>
                        </Grid>


                    </Grid>
                </Container>


            </React.Fragment>
        </main>
    );
}
