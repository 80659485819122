// @ts-nocheck
import { API, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import * as queries from '../../graphql/customQueries';
import Title from "../Title";
import AttorneyDataTable from "./AttorneyDataTable";
import AttorneyEditModal from "./AttorneyEditModal";
import useStyles from "./styles";
import { updateAttorney } from "../../graphql/customMutations";

function AttorneyDetails() {
  const classes = useStyles();

  const [attorneys, setAttorneys] = useState([])
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [selectedEditData, setSelectedEditData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getAttorneyList();
  }, [])

  async function getAttorneyList() {
    setIsLoading(true)
    try {
      const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));

      const data = responseListAttorneys.data.listAttorneys.items;
      if (data) {
        setAttorneys(data);
      }
    } catch (error) {
      console.error(error);
      alert('Fetch attorneys list failed')
    } finally {
      setIsLoading(false)
    }
  }

  function toggleEditModal() {
    setIsEditModalVisible(!isEditModalVisible)
  }

  async function handleSubmit(values) {
    if (values) {
      setIsLoading(true)
      try {
        await API.graphql(
          graphqlOperation(updateAttorney, {
            input: {
              id: values.id,
              firstName: values.firstName,
              lastName: values.lastName,
              emailWork: values.emailWork,
              statesOfLicense: values.statesOfLicense,
              licenseNumber: values.licenseNumber,
              cellPhone: values.cellPhone,
              notificationMethods: values.notificationMethods,
              communicationMethodsPreference: values.communicationMethodsPreference,
            },
          })
        );
        
        getAttorneyList();
      } catch (error) {
        alert('Edit attorney failed')
      } finally {
        setIsEditModalVisible(false)
        setSelectedEditData(null)
      }
    }
  }

  function handleEdit(data) {
    setIsEditModalVisible(true)
    setSelectedEditData(data)
  }

  async function handleToggleActive(data) {
    if (data) {
      setIsLoading(true);
      try {
        await API.graphql(
          graphqlOperation(updateAttorney, {
            input: {
              id: data.id,
              isActive: data.isActive === null ? false : !data.isActive,
            },
          })
        );

        getAttorneyList();
      } catch (error) {
        alert('Edit attorney failed')
      }
    }
  }

  return (
    <React.Fragment>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {
          attorneys && attorneys.length ? (
            <React.Fragment>
              <Title>Attorney Details</Title>
              <AttorneyDataTable data={attorneys} handleEdit={handleEdit} handleToggleActive={handleToggleActive} isLoading={isLoading} />
            </React.Fragment>
          )
            : <p>Loading...</p>
        }

        <AttorneyEditModal data={selectedEditData} isVisible={isEditModalVisible} onClose={toggleEditModal} onSubmit={handleSubmit} />
      </main>
    </React.Fragment>
  );
}

export default AttorneyDetails;
