import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxLarge: {
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
  root: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  searchBar: {
    width: '30%',
  }
}));

export default useStyles;