import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer, CartesianGrid, Tooltip, Legend } from 'recharts';
import Title from './Title';
import moment from 'moment';
import * as queries from '../graphql/customQueries';
import { API, graphqlOperation } from 'aws-amplify';


// const weekCount = dateTime => {

//   let today = moment().format("MM/DD");
//   let yesterday = moment().subtract(1, 'days').endOf('day').format("MM/DD");
//   let twoDaysAgo = moment().subtract(2, 'days').endOf('day').format("MM/DD");
//   let threeDaysAgo = moment().subtract(3, 'days').endOf('day').format("MM/DD");
//   let fourDaysAgo = moment().subtract(4, 'days').endOf('day').format("MM/DD");
//   let fiveDaysAgo = moment().subtract(5, 'days').endOf('day').format("MM/DD");
//   let sixDaysAgo = moment().subtract(6, 'days').endOf('day').format("MM/DD");
//   let SevenDaysAgo = moment().subtract(7, 'days').endOf('day').format("MM/DD");
//   //let createdAtTime = moment(item.createdAt).format("MM/DD").toString();

//   const obj = [{}];

//   for (let item of dateTime) {
//     let createdAtTime = moment(dateTime).format("MM/DD").toString();

//     if (createdAtTime === yesterday) {
//         obj.push({YesterdayCount: createdAtTime})
//     }
//   }


//   return obj;


// }

export default function Chart() {
  const theme = useTheme();
  //const classes = useStyles();
  const [practice, setPractice] = useState(null);
  const [client, setClient] = useState(null);
  const [clientCountBarData, setClientBarData] = useState(null);
  const [monthBarData, setMonthBarData] = useState(null);


  useEffect(() => {
    (
      async () => {
        //here it grabs listPracticeAreas
        const responseListAttorneys = await API.graphql(graphqlOperation(queries.listPracticeAreas));
        //set practice to listAttorneys
        const practice = responseListAttorneys.data.listAttorneys.items;
        setPractice(practice);
        let arrPractice = practice;

        const responseListClients = await API.graphql(graphqlOperation(queries.listClientTest));
        const client = responseListClients.data.listClients.items;
        setClient(client);
        let arrClient = client;

        // const test = new Date('2021-02-01T16:36:00.704Z');
        // const test2 = moment("2021-02-01T16:36:00.704Z");
        // const testYesterday = test2;

        // console.log(testYesterday.subtract(1, 'days').endOf('day').format("MM/DD").toString());
        // console.log(practice)
        let today = moment().format("MM/DD");
        let yesterday = moment().subtract(1, 'days').endOf('day').format("MM/DD");
        let todayArray = [];


        let thisWeek = moment().format("WW/YY");
        let oneWeekAgo = moment().subtract(1, 'weeks').endOf('day').format("WW/YY");
        let twoWeeksAgo = moment().subtract(2, 'weeks').endOf('day').format("WW/YY");
        let threeWeeksAgo = moment().subtract(3, 'weeks').endOf('day').format("WW/YY");
        let fourWeeksAgo = moment().subtract(4, 'weeks').endOf('day').format("WW/YY");
        let currentWeekArray = [];
        let oneWeekAgoArray = [];
        let twoWeeksAgoArray = [];
        let threeWeeksAgoArray = [];
        let fourWeeksAgoArray = [];
        let monthCountArray = [];

        let clientValidIDArray = [];
        let clientValidIDYesterdayArray = [];
        let clientInvalidArray = [];
        //console.log("test", today);




       let currentWeekArrayUser = [];
       let oneWeekAgoArrayUser = [];
       let twoWeeksAgoArrayUser = [];
       let threeWeeksAgoArrayUser = [];
       let fourWeeksAgoArrayUser = [];
       let monthCountArrayUser = [];

       let currentWeekArrayAttorney = [];
       let oneWeekAgoArrayAttorney = [];
       let twoWeeksAgoArrayAttorney = [];
       let threeWeeksAgoArrayAttorney = [];
       let fourWeeksAgoArrayAttorney = [];
       let monthCountArrayAttorney = [];
//month attorney 
       for (let item of arrClient) {
        let currentWeek = moment(item.createdAt).format("WW/YY").toString();
        //If the client has an ID and accepts the TC and PP 
        if (item.id && item.acceptsTCAndPP === true) {
          //weekCountArray  = weekCount(item.createdAt)
          //weekCountArray.push(weekCount(item.createdAt));
          if (currentWeek === thisWeek) {
            currentWeekArrayUser.push(item.id);
            monthCountArrayUser.push(item.id)
          }
          if (currentWeek === oneWeekAgo) {
            oneWeekAgoArrayUser.push(item.id);
            monthCountArrayUser.push(item.id)
          }
          if (currentWeek === twoWeeksAgo) {
            twoWeeksAgoArrayUser.push(item.id);
            monthCountArrayUser.push(item.id)
          }
          if (currentWeek === threeWeeksAgo) {
            threeWeeksAgoArrayUser.push(item.id);
            monthCountArrayUser.push(item.id)
          }
          if (currentWeek === fourWeeksAgo) {
            fourWeeksAgoArrayUser.push(item.id);
            monthCountArrayUser.push(item.id)
          }
        }else{
          //console.log("nothing")
        }
        //checking if anyone is able to enter the app without acceping TC and PP
      }

//Attorney 230942309580923458209345840395243
      for (let item of arrPractice) {
        let currentWeek = moment(item.createdAt).format("WW/YY").toString();
        //If the client has an ID and accepts the TC and PP 
        if (item.id) {
          //weekCountArray  = weekCount(item.createdAt)
          //weekCountArray.push(weekCount(item.createdAt));
          if (currentWeek === thisWeek) {
            currentWeekArrayAttorney.push(item.id);
            monthCountArrayAttorney.push(item.id)
          }
          if (currentWeek === oneWeekAgo) {
            oneWeekAgoArrayAttorney.push(item.id);
            monthCountArrayAttorney.push(item.id)
          }
          if (currentWeek === twoWeeksAgo) {
            twoWeeksAgoArrayAttorney.push(item.id);
            monthCountArrayAttorney.push(item.id)
          }
          if (currentWeek === threeWeeksAgo) {
            threeWeeksAgoArrayAttorney.push(item.id);
            monthCountArrayAttorney.push(item.id)
          }
          if (currentWeek === fourWeeksAgo) {
            fourWeeksAgoArrayAttorney.push(item.id);
            monthCountArrayAttorney.push(item.id)
          }
        }else{
          //console.log("nothing")
        }
        //checking if anyone is able to enter the app without acceping TC and PP
      }

        //console.log(weekCountArray);

        const MonthCountBarData = [];
        MonthCountBarData.push({Week: thisWeek, ClientCountWeek: currentWeekArrayUser.length, AttorneyCountWeek: currentWeekArrayAttorney.length})
        MonthCountBarData.push({Week: oneWeekAgo, ClientCountWeek: oneWeekAgoArrayUser.length, AttorneyCountWeek: oneWeekAgoArrayAttorney.length})
        MonthCountBarData.push({Week: twoWeeksAgo, ClientCountWeek: twoWeeksAgoArrayUser.length, AttorneyCountWeek: twoWeeksAgoArrayAttorney.length})
        MonthCountBarData.push({Week: threeWeeksAgo, ClientCountWeek: threeWeeksAgoArrayUser.length, AttorneyCountWeek: threeWeeksAgoArrayAttorney.length})
        MonthCountBarData.push({Week: fourWeeksAgo, ClientCountWeek: fourWeeksAgoArrayUser.length, AttorneyCountWeek: fourWeeksAgoArrayAttorney.length})
        setMonthBarData(MonthCountBarData.reverse());
        console.log(MonthCountBarData)


        // console.log("This many users signed up yesterday: ", oneDayAgoArray.length);
        // console.log(oneDayAgoArray);
        // console.log(weekCountArray);


      }
    )();
  }, []);

  return (
    <React.Fragment>
      <Title>Number of Users and Attorneys That Have Joined This Month</Title>
      <ResponsiveContainer >
        <LineChart
          data={monthBarData}
          margin={{
            top: 16,
            right: 16,
            bottom: 0,
            left: 24,
          }}
        >
          <XAxis dataKey="Week" stroke={theme.palette.text.secondary} />
          <YAxis stroke={theme.palette.text.secondary} allowDecimals={false} >
            <Label
              angle={270}
              position="left"
              style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
            >
              Count of users
            </Label>
          </YAxis>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
            <Legend/>
          <Line name="User Count" type="monotone" dataKey="ClientCountWeek" stroke="#8884d8" />
          <Line name="Attorney Count" type="monotone" dataKey="AttorneyCountWeek" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

